import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router";
import Swal from "sweetalert2";
import FormatTitle from "../../../utils/FormatTitle";
import SwalLoading from "../../../utils/SwalLoading";
import FieldsComponent from "./FieldsComponent";
import SessionContext from "../../../context/SessionContext";
import SwalError from "../../../utils/SwalError";
import { getFormById } from "../../../../services/settings/forms/FormService";

const ShowFormsComponent = (props) => {
  const { id: formId } = useParams();
  const [form, setForm] = useState({});
  const { closeSession } = useContext(SessionContext);

  useEffect(() => {
    SwalLoading({ title: "Consultando Formulario" });
    getFormById(formId)
      .then((response) => {
        if (response.status === 200) {
          setForm(response.data);
          Swal.close();
        }
      })
      .catch((error) => {
        const response = error.response;
        if (response.status === 401) {
          closeSession();
        } else if ([400].includes(response.status)) {
          SwalError({
            title: "Error",
            message: response.data.detail,
          });
        } else {
          SwalError({
            title: "Error no Controlador",
            message: response.data.detail,
          });
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <form>
      <div className="col-12 col-md-12 col-sm-12">
        <div className="app-forms-preview-title rounded-1">
          <span>FORMULARIO:</span>
          <span className="bold">{form.form_name}</span>
        </div>
      </div>
      {form?.form_sections?.map((section, index) => (
        <div className={section?.section_class} key={index}>
          <div className="app-forms-section-actions">
            <div className={`app-forms-section-title`}>
              {FormatTitle(section?.section_name)}
            </div>
          </div>
          <div
            className="row app-forms-section-fields"
            style={{ display: section?.section_show ? "block" : "none" }}
          >
            <div className="row d-flex">
              {section?.section_fields?.map((field, index) => {
                return (
                  <FieldsComponent
                    key={index}
                    {...field}
                    state={{ fields: null }}
                  />
                );
              })}
            </div>
          </div>
        </div>
      ))}
    </form>
  );
};

export default ShowFormsComponent;
