import React, { useState } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import { useNavigate } from "react-router";
import SwalLoading from "../../../utils/SwalLoading";
import SwalSuccess from "../../../utils/SwalSuccess";
import SwalError from "../../../utils/SwalError";
import { postRequestPassword } from "../../../../services/auth/AuthService";
import "./RequestPassword.css";

const RequestPassword = () => {
  const navigate = useNavigate();
  const [isSending, setIsSending] = useState(false);

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: yup.object({
      email: yup.string("Ingrese su correo electrónico")
      .email("Ingrese un correo electrónico válido.")
      .required("Debe ingresar su correo electrónico"),
    }),
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: (values) => {
      SwalLoading({ title: "Solicitando contraseña" });
      setIsSending(!isSending);
      postRequestPassword(values.email)
        .then((response) => {
          if (response.status === 200) {
            SwalSuccess({
              title: "Recuperando Contraseña",
              message: response.data.message,
            });
            goToLogin();
            setIsSending(false);
          }
        })
        .catch((error) => {
          const response = error.response;
          if([400].includes(response.status)){
            SwalError({title: "Error", message: response.data.detail})
          }else{
            SwalError({title: "Error no Controlado", message: JSON.stringify(response.data.detail)})
          }
          setIsSending(false);
        });
    },
  });

  const goToLogin = () => {
    navigate("/login");
  };

  return (
    <div className="container vh-100">
      <form className="form" onSubmit={formik.handleSubmit}>
        <p className="fs-5 text-center mb-4">Para recuperar su contraseña ingrese su correo en el siguiente campo de texto.</p>
        <div className="form-group mb-4">
          <label className="app-form-label" htmlFor="email">
            Correo electrónico:
          </label>
          <input
            id="email"
            name="email"
            type="email"
            className="form-control"
            placeholder="Ingrese su correo electrónico"
            value={formik.values.email}
            onChange={formik.handleChange}
            onBlur={formik.handleChange}
            autoComplete="off"
          />
          {formik.touched.email && (
            <div className="feedback-invalid text-danger">
              {formik.errors.email}
            </div>
          )}
        </div>
        <div className="buttons">
          <div>
            <button
              type="button"
              className="m-auto btn bg-danger text-white"
              onClick={goToLogin}
              disabled={isSending}
            >
              Cancelar
            </button>
          </div>
          <div>
            <button type="submit" className="m-auto btn" disabled={isSending}>
              {isSending ? "Recuperando..": "Recuperar Contraseña"}
            </button>
          </div>
        </div>
      </form>
      <div className="footer">
        <span>© 2022 Consulta Externa by HG Consulting</span>
      </div>
    </div>
  );
};

export default RequestPassword;
