import { useFormik } from "formik";
import * as yup from "yup";
import React, { useState } from "react";
import EyeIcon from "../../assets/icons/eye.svg";
import EyeOffIcon from "../../assets/icons/eye-off.svg";
import { useNavigate } from "react-router";
import SwalLoading from "../../utils/SwalLoading";
import SwalError from "../../utils/SwalError";
import Swal from "sweetalert2";
import ResendCodeTimer from "./ResendCodeTimer";
import SwalSuccess from "../../utils/SwalSuccess";
import { postLogin, postResedOtpCode, postVerifyOtpCode } from "../../../services/auth/AuthService";
import { NavLink } from "react-router-dom";

const LoginComponent = () => {
  const [passwordType, setPasswordType] = useState("password");
  const [show, setShow] = useState(false);
  const [success, setSuccess] = useState(false);
  const [isSended, setIsSended] = useState(false);
  const [seconds, setSeconds] = useState(60);
  const navigate = useNavigate();

  const login = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: yup.object({
      email: yup
        .string("Ingrese su correo electrónico.")
        .email("El correo electrónico ingresado no es válido.")
        .required("Debe ingresar su correo electrónico."),
      password: yup
        .string("Ingrese su contraseña.")
        .required("Debe ingresar su contraseña."),
    }),
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: async (values) => {

      SwalLoading({ title: "Verificando Credenciales" });

      await postLogin(values)
        .then((response) => {
          if (response.status === 200) {
            SwalSuccess({ title: "Credenciales Correctas", message: response.data?.message });
            f2a.setFieldValue("email", values.email);
            setSeconds(60);
            setIsSended(true);
            setSuccess(!success);
          }
        })
        .catch((error) => {
          const response = error.response;
          if ([400].includes(response.status)) {
            SwalError({ title: "Error", message: response.data.detail })
          } else {
            SwalError({ title: "Error no Controlado", message: JSON.stringify(response.data.detail) })
          }
        });
    },
  });

  const f2a = useFormik({
    initialValues: { email: "", otp_code: "" },
    validationSchema: yup.object({
      email: yup
        .string("Ingrese su correo electrónico.")
        .email("El correo electrónico ingresado no es válido.")
        .required("Debe ingresar su correo electrónico."),
      otp_code: yup
        .string("Ingrese el código de verificación.")
        .matches(
          /^[0-9]{6}$/g,
          "El código de verificación debe tener un tamaño de 6."
        )
        .required("El código de verificación es requerido."),
    }),
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: async (values) => {
      SwalLoading({ title: "Verificando Código" });
      await postVerifyOtpCode(values)
        .then((response) => {
          if (response.status === 200) {
            const data = response.data;
            sessionStorage.setItem("token", data.access_token);
            navigate("/");
            Swal.close();
          }
        })
        .catch((error) => {
          const response = error.response;
          if ([400].includes(response.status)) {
            SwalError({ title: "Error", message: response.data.detail })
          } else {
            SwalError({ title: "Error no Controlado", message: JSON.stringify(response.data.detail) })
          }
        });
    },
  });

  const handleToggle = () => {
    setShow(!show);
    setPasswordType(passwordType === "password" ? "text" : "password");
  };

  const handleSendCode = async () => {

    if (!isSended) {
      SwalLoading({ title: "Reenviando Código" });
      await postResedOtpCode({ email: f2a.values.email })
        .then((response) => {
          if (response.status === 200) {
            setSeconds(60);
            setIsSended(!isSended);
            Swal.close();
          }
        })
        .catch(() => {
          SwalError({
            title: "Credenciales Incorrectas",
            message: "El usuario o contraseña no es valido.",
          });
        });
    }
  };

  return (
    <>
      <h1 className="app-login-form-title">¡Bienvenido de nuevo!</h1>
      <h2 className="app-login-form-subtitle">
        Ingresa para comenzar con tus actividades de
        <span> Consulta Medica.</span>
      </h2>
      {!success ? (
        <form onSubmit={login.handleSubmit}>
          <div className="form-group mb-3">
            <input
              type="email"
              id="email"
              name="email"
              className={`form-control${login.touched.email && login.errors.email ? " is-invalid" : ""
                }`}
              placeholder="Escribe tu Usuario"
              value={login.values.email}
              onChange={login.handleChange}
              onBlur={login.handleBlur}
              autoComplete="off"
              required
            />
            {login.touched.email && login.errors.email && (
              <div
                className="d-flex invalid-feedback position-absolute"
                style={{ top: 55 }}
              >
                {login.errors.email}
              </div>
            )}
          </div>
          <div
            className={`form-group ${login.touched.password && login.errors.password ? "mb-5" : ""
              }`}
          >
            <input
              type={passwordType}
              id="password"
              name="password"
              className={`form-control ${login.touched.password && login.errors.password
                ? "is-invalid"
                : ""
                }`}
              placeholder="Ingresa tu Contraseña"
              value={login.values.password}
              onChange={login.handleChange}
              onBlur={login.handleBlur}
              autoComplete="off"
              required
            />
            <button type="button" onClick={handleToggle}>
              <img
                src={show ? EyeIcon : EyeOffIcon}
                alt="eye"
                width="32px"
                height="28px"
              />
            </button>
            {login.touched.password && login.errors.password && (
              <div
                className="d-flex invalid-feedback position-absolute"
                style={{ top: 55, minHeight: 10 }}
              >
                {login.errors.password}
              </div>
            )}
          </div>
          {process.env.NODE_ENV === "development" && (
            <div className="app-form-checkbox">
              <input type="checkbox" id="remember" name="remember" />
              <label htmlFor="remember">Recuerdame</label>
            </div>
          )}
          {process.env.NODE_ENV === "development" && (
            <div className="app-form-recapcha"></div>
          )}
          <div className="app-form-button">
            <button type="submit">Ingresar</button>
          </div>
          <div className="app-form-footer">
            <span className="d-flex flex-start">
              <NavLink to="/recuperar-contrasenia" className="nav-link text-secondary">¿Olvidaste tu contraseña?</NavLink>
            </span>
          </div>
        </form>
      ) : (
        <form onSubmit={f2a.handleSubmit}>
          <div className="form-group mb-1">
            <input
              type="text"
              id="otp_code"
              name="otp_code"
              className={`form-control ${f2a.touched.otp_code && f2a.errors.otp_code ? "is-invalid" : ""
                }`}
              placeholder="Código de Verificación"
              value={f2a.values.otp_code}
              onChange={f2a.handleChange}
              onBlur={f2a.handleBlur}
              autoComplete="off"
              required
            />
            {f2a.touched.otp_code && f2a.errors.otp_code && (
              <div
                className="d-flex invalid-feedback position-absolute"
                style={{ top: 55, wordWrap: "normal" }}
              >
                {f2a.errors.otp_code}
              </div>
            )}
          </div>
          <div className="app-form-resend mb-1">
            <div>
              <button
                type="button"
                disabled={isSended}
                onClick={handleSendCode}
              >
                <span>
                  <i className="bi bi-arrow-repeat"></i>
                </span>
                <ResendCodeTimer
                  duration={seconds}
                  isSended={isSended}
                  setIsSended={setIsSended}
                />
              </button>
            </div>
          </div>
          <div className="app-form-button">
            <button type="submit">Verificar</button>
          </div>
        </form>
      )}

    </>
  );
};

export default LoginComponent;
