import React, { useEffect } from "react";
import { useState } from "react";
import { getCatalogFieldsById } from "../../../services/settings/catalogs/CatalogsService";
import SwalError from "../../utils/SwalError";

const SelectCatalogComponent = (props) => {
  const { id, name, value, onChange, onBlur, className, required, catalogId } = props;
  const [fields, setFields] = useState([]);

  useEffect(() => {
    if (catalogId) {
      getCatalogFieldsById(catalogId)
        .then((response) => {
          if (response.status === 200) {
            setFields(response.data);
          }
        })
        .catch((error) => {
          const response = error.response;
          if (response.status === 401) {
            SwalError({
              title: "Error",
              message: response.data.detail,
            });
          }
        });
    }
  }, [catalogId]);

  return (
    <select
      id={id}
      name={name}
      className={className}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      required={required}
    >
      <option value="0">seleccione una opción</option>
      {fields?.map((item) => (
        <option key={item.id} value={item.id}>
          {item.name}
        </option>
      ))}
    </select>
  );
};

export default SelectCatalogComponent;
