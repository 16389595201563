import React, { useContext } from 'react'
import { useFormik } from "formik";
import { Modal } from "react-bootstrap";
import { regex_password } from "../../utils/Regex";
import * as yup from "yup";
import { postChangeCredentials } from '../../../services/auth/AuthService';
import SwalSuccess from '../../utils/SwalSuccess';
import SessionContext from '../../context/SessionContext';
import SwalError from '../../utils/SwalError';

const ChangePasswordModalComponent = ({ show, toggleModal }) => {
    const { closeSession } = useContext(SessionContext);

    const formik = useFormik({
        initialValues: {
            current_password: "",
            password: "",
            password_confirmation: ""
        },
        validationSchema: yup.object({
            current_password: yup.string("Escriba su contraseña actual").required("Debe escribir su contraseña actual"),
            password: yup
                .string("Escriba su nueva contraseña")
                .matches(
                    regex_password,
                    "La contraseña debe tener al menos un tamaño de 12, debe tener por lo menos una mayúscula, un número, minúsculas."
                )
                .required("La contraseña es requeridad"),
            password_confirmation: yup
                .string("Escriba la confirmación de su nueva contraseña")
                .oneOf([yup.ref("password"), null], "Las contraseñas no coinciden")
                .required("La confirmación de la nueva contraseña contraseña es requeridad"),
        }),
        validateOnChange: true,
        validateOnBlur: true,
        onSubmit: (values) => {
            postChangeCredentials(values).then((response) => {
                if (response.status === 200) {
                    SwalSuccess({ title: "Cambio de Contraseña", message: response.data.message })
                    toggleModal();
                }
            }).catch((error) => {
                const response = error.response;
                if (response.status === 401) {
                    closeSession();
                } else if ([400, 404].includes(response.status)) {
                    SwalError({
                        title: "Error",
                        message: response.data.detail
                    });
                } else {
                    SwalError({
                        title: "Error no Controlado",
                        message: JSON.stringify(response.data.detail)
                    });
                }
            })
        }
    });
    return (
        <Modal show={show} centered className="app-change-password-modal">
            <Modal.Header>
                <Modal.Title>Cambiar Contraseña</Modal.Title>
                <button className="btn-close" onClick={toggleModal}></button>
            </Modal.Header>
            <Modal.Body>
                <form onSubmit={formik.handleSubmit} noValidate>
                    <div className="col mb-2">
                        <label htmlFor="current_password" className="form-label fw-semibold text-secondary">
                            Contraseña:
                        </label>
                        <input
                            type="password"
                            id="current_password"
                            name="current_password"
                            className={`form-control ${formik.touched.current_password && formik.errors.current_password
                                ? "is-invalid"
                                : ""
                                }`}
                            placeholder="Ingrese la contraseña"
                            value={formik.values.current_password}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            autoComplete="off"
                            required
                        />
                        {formik.touched.current_password && formik.errors.current_password && (
                            <div className="d-flex invalid-feedback">
                                {formik.errors.current_password}
                            </div>
                        )}
                    </div>
                    <div className="col mb-2">
                        <label htmlFor="password" className="form-label fw-semibold  text-secondary">
                            Nueva Contraseña:
                        </label>
                        <input
                            type="password"
                            id="password"
                            name="password"
                            className={`form-control ${formik.touched.password && formik.errors.password
                                ? "is-invalid"
                                : ""
                                }`}
                            placeholder="Ingrese la contraseña"
                            value={formik.values.password}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            autoComplete="off"
                            required
                        />
                        {formik.touched.password && formik.errors.password && (
                            <div className="d-flex invalid-feedback">
                                {formik.errors.password}
                            </div>
                        )}
                    </div>
                    <div className="col mb-4">
                        <label htmlFor="password_confirmation" className="form-label fw-semibold  text-secondary">
                            Confirmar Nueva Contraseña:
                        </label>
                        <input
                            type="password"
                            id="password_confirmation"
                            name="password_confirmation"
                            className={`form-control ${formik.touched.password_confirmation &&
                                formik.errors.password_confirmation
                                ? "is-invalid"
                                : ""
                                }`}
                            placeholder="Ingrese su contraseña nuevamente"
                            value={formik.values.password_confirmation}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            autoComplete="off"
                            required
                        />
                        {formik.touched.password_confirmation &&
                            formik.errors.password_confirmation && (
                                <div className="d-flex invalid-feedback">
                                    {formik.errors.password_confirmation}
                                </div>
                            )}
                    </div>
                    <div className="d-flex flex-row gap-2">
                        <div className="col">
                            <div className="d-flex flex-row justify-content-center align-content-middle">
                                <button className="btn btn-outline-danger" onClick={toggleModal} type="reset">
                                    Cancelar
                                </button>
                            </div>
                        </div>
                        <div className="col">
                            <div className="d-flex flex-row justify-content-center align-content-middle">
                                <button className="btn btn-success" type="submit">
                                    Confirmar
                                </button>
                            </div>
                        </div>
                    </div>
                </form>
            </Modal.Body>
        </Modal>
    )
}

export default ChangePasswordModalComponent;
