import React, { useContext, useMemo, useState } from "react";
import { useEffect } from "react";
import Swal from "sweetalert2";
import { NavLink, useParams } from "react-router-dom";
import ProfilePhoto from "../../assets/images/home/profile.svg";
import SwalLoading from "../../utils/SwalLoading";
import SwalError from "../../utils/SwalError";
import { CalculateYears } from "../../utils/CalculateYears";
import TranslateGender from "../../utils/TranslateGender";
import SessionContext from "../../context/SessionContext";
import permissions from "../../json/route_permission.json";
import { getPatientById } from "../../../services/patients/PatientsService";

const ClinicHistoryPatientInformationComponent = (props) => {
  const [patient, setPatient] = useState(null);
  const { id: patientId } = useParams();
  const { isPermitted } = useContext(SessionContext);

  useEffect(() => {

    SwalLoading({ title: "Consultando paciente" });
    getPatientById(patientId)
      .then((response) => {
        if (response.status === 200) {
          setPatient(response.data);
          Swal.close();
        }
      })
      .catch((error) => {
        const response = error.response;
        if (response?.status === 401) {
          SwalError({
            title: "Error",
            message: response.data.detail,
          });
        }
      });

    return () => { };

  }, [patientId]);

  const fullName = useMemo(() => `${patient?.paternal_surname} ${patient?.maternal_surname} ${patient?.first_name} ${patient?.second_name}`, [patient]);

  const age = useMemo(() => CalculateYears(patient?.birth_date), [patient]);

  const antiquity = useMemo(() => CalculateYears(patient?.hire_date), [patient]);

  return (
    patient && (
      <form className="d-flex">
        <div className="col-10 col-md-10 col-sm-10">
          <div className="row d-flex flex-wrap justify-start">
            <div className="col-md-4">
              <label htmlFor="number_id" className="form-label">
                Número de ID:
              </label>
              <input
                type="text"
                className="form-control-plaintext"
                id="number_id"
                name="number_id"
                value={patient?.number_id}
                readOnly
              />
            </div>
            <div className="col-md-4">
              <label htmlFor="nombre" className="form-label">
                Nombre:
              </label>
              <input
                type="text"
                className="form-control-plaintext"
                id="full_name"
                name="full_name"
                value={fullName}
                readOnly
              />
            </div>
            <div className="col-md-2">
              <label htmlFor="sexo" className="form-label">
                Sexo:
              </label>
              <input
                type="text"
                className="form-control-plaintext"
                id="gender"
                name="gender"
                value={TranslateGender(patient?.gender)}
                readOnly
              />
            </div>
            <div className="col-md-2">
              <label htmlFor="age" className="form-label">
                Edad:
              </label>
              <input
                type="text"
                id="age"
                name="age"
                className="form-control-plaintext"
                value={age}
                readOnly
              />
            </div>
            <div className="col-md-4">
              <label htmlFor="birth_date" className="form-label">
                Fecha de Nacimiento:
              </label>
              <input
                type="date"
                className="form-control-plaintext"
                id="birth_date"
                name="birth_date"
                value={patient?.birth_date}
                readOnly
              />
            </div>

            <div className="col-md-4">
              <label htmlFor="hire_date" className="form-label">
                Fecha Ingreso:
              </label>
              <input
                type="date"
                className="form-control-plaintext"
                id="hire_date"
                name="hire_date"
                value={patient?.hire_date}
                readOnly
              />
            </div>
            <div className="col-md-4">
              <label htmlFor="antiquity" className="form-label">
                Antigüedad:
              </label>
              <input
                type="text"
                className="form-control-plaintext"
                id="antiquity"
                name="antiquity"
                value={antiquity}
                readOnly
              />
            </div>
            {patient?.patient_extra_fields.map((field, index) =>
              <div className="col-md-4" key={index}>
                <label htmlFor={field.extra_field.field_id} className="form-label">
                  {field.extra_field.field_label}
                </label>
                <input
                  type="text"
                  className="form-control-plaintext"
                  id={field.extra_field.field_id}
                  name={field.extra_field.field_name}
                  value={field.value === "" ? 'N/A' : field.value}
                  readOnly
                />
              </div>
            )}
          </div>
        </div>
        <div className="col">
          <div className="d-block m-auto">
            {isPermitted(permissions.medical_care.patients.edit) && <NavLink
              to={`/historia-clinica/${patientId}/editar`}
              className="nav-link text-decoration-underline text-green-link"
            >
              Editar Paciente
            </NavLink>}
          </div>
          <div className="app-clinichistory-form-avatar">
            <div className="avatar">
              <img
                src={!patient.photo ? ProfilePhoto : patient.photo}
                alt="profile"
                width="192px"
                height="192px"
                className="avatar"
              />
            </div>
          </div>
        </div>
      </form>
    )
  );
};

export default ClinicHistoryPatientInformationComponent;
