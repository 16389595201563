import Swal from "sweetalert2";
import PropTypes from "prop-types";

const SwalInformation = (props) => {
  return Swal.fire({
    title: props?.title,
    html: props?.message,
    showConfirmButton: false,
    timer: 3000,
  });
};

export default SwalInformation;

SwalInformation.propTypes = {
  title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
};
