import React, { useEffect, useState } from "react";
import SwalError from "../../utils/SwalError";
import { getSpecialities } from "../../../services/settings/doctors/DoctorsService";

const SelectSpecialitiesComponent = (props) => {
  const { id, name, value, branchId, onChange, onBlur, className, required } = props;
  const [specialities, setSpecialities] = useState([]);

  useEffect(() => {
    if (branchId !== 0) {
      getSpecialities(branchId)
        .then((response) => {
          if (response.status === 200) {
            setSpecialities(response.data);
          }
        })
        .catch((error) => {
          const response = error.response;
          if (response.status === 401) {
            SwalError({
              title: "Error",
              message: response.data.detail,
            });
          }
        });
    }
  }, [branchId]);

  return (
    <select
      id={id}
      name={name}
      className={className}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      required={required}
    >
      <option value="0">Seleccione una especialidad</option>
      {specialities
        ?.filter((item) => item.is_active === true)
        ?.map((item) => (
          <option key={item.id} value={item.id}>
            {item.name}
          </option>
        ))}
    </select>
  );
};

export default SelectSpecialitiesComponent;
