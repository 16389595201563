import React, { useEffect, useState } from "react";
import SwalError from "../../../../utils/SwalError";
import { getCatalogs } from "../../../../../services/settings/catalogs/CatalogsService";

const SelectCatalogsComponent = (props) => {
  const { className, id, name, value, onChange } = props;
  const [catalogs, setCatalogs] = useState([]);

  useEffect(() => {
    getCatalogs()
      .then((response) => {
        if (response.status === 200) {
          setCatalogs(response.data);
        }
      })
      .catch((error) => {
        const response = error.response;
        if (response.status === 401) {
          SwalError({
            title: "Error",
            message: response.data.detail,
          });
        }
      });
  }, []);

  return (
    <select
      className={className}
      id={id}
      name={name}
      value={value !== null ? value : ""}
      onChange={onChange}
    >
      <option value="">Seleccione una opción</option>
      {catalogs.map((catalog) => (
        <option key={catalog.id} value={catalog.id}>
          {catalog.name}
        </option>
      ))}
    </select>
  );
};

export default SelectCatalogsComponent;
