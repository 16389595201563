import React, { useState, useEffect, useMemo, useContext } from "react";
import Swal from "sweetalert2";
import SwalLoading from "../../utils/SwalLoading";
import PatientCareInformationComponent from "./PatientCareInformationComponent";
import PatientCareStepsComponent from "./PatientCareStepsComponent";
import SwalError from "../../utils/SwalError";
import LogoDefault from "../../assets/images/forms/logo_default.svg";
import * as yup from "yup";
import DateFormat from "../../utils/DateFormat";
import FormatTitle from "../../utils/FormatTitle";
import FieldsComponent from "../forms/FieldsComponent";
import { useNavigate, useParams } from "react-router";
import { getProtocolFormsById } from "../../../services/settings/protocols/ProtocolsService";
import { useFormik } from "formik";
import SessionContext from "../../context/SessionContext";
import { postAppointment } from "../../../services/patients/AppointmentsService";
import SwalSuccess from "../../utils/SwalSuccess";
import "./Forms.css";

const PatientCareComponent = (props) => {
  const navigate = useNavigate();
  const { protocolId, patientId } = useParams();
  const [type] = useState("status_green");
  const [forms, setForms] = useState([]);
  const [currentForm, setCurrentForm] = useState({});
  const { session, closeSession } = useContext(SessionContext);

  useEffect(() => {
    SwalLoading({ title: "Consultando Formularios" });
    getProtocolFormsById(protocolId)
      .then((response) => {
        if (response.status === 200) {
          const data = response.data;
          const forms = setupForms(data);
          const forms_history = setupFormsHistory(data);
          setForms(forms);
          setCurrentForm(forms[0]);
          formik.setFieldValue("forms_history", forms_history);
          Swal.close();
        }
      })
      .catch((error) => {
        const response = error.response;
        if (response.status === 401) {
          closeSession()
        } else if ([400, 404].includes(response.status)) {
          SwalError({
            title: "Error",
            message: response.data.detail,
          });
        } else {
          SwalError({
            title: "Error No Controlador",
            message: response.data.detail,
          });
        }
      });

    return () => { };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const formik = useFormik({
    initialValues: {
      patient_id: patientId,
      protocol_id: protocolId,
      is_active: true,
      forms_history: []
    },
    validationSchema: yup.object({
      patient_id: yup.number().required(),
      protocol_id: yup.number().required(),
      forms_history: yup.array(
        yup.object({
          form_id: yup.number().required(),
          is_active: yup.bool().default(() => true),
          fields_history: yup.array(
            yup.object({
              field_id: yup.number().required(),
              value: yup.string().required(),
              is_active: yup.bool().default(() => true)
            }))
        })
      ),
      is_active: yup.bool().required()
    }),
    onSubmit: (values) => {
      SwalLoading({ title: "Guardando Historial Clinico" })
      postAppointment(values).then((response) => {
        if (response.status === 200) {
          SwalSuccess({
            title: "Historial Clinico",
            message: "El historial clinico ha sido guardado correctamente"
          });
          navigate(`/historia-clinica/${patientId}/ver`)
        }
      }).catch((error) => {
        const response = error.response;
        if (response.status === 401) {
          closeSession()
        } else if ([400, 404].includes(response.status)) {
          SwalError({
            title: "Error",
            message: response.data.detail,
          });
        } else {
          SwalError({
            title: "Error No Controlador",
            message: response.data.detail,
          });
        }
      });
    }
  })

  const setupForms = (forms) => {
    return forms.map((form, index) => ({
      id: index,
      form_id: form.form_id,
      is_required: form.is_required,
      is_completed: false,
      is_current: index === 0,
      is_lasted: index === forms.length - 1,
      form: form.form
    }));
  };

  const setupFormsHistory = (forms) => {
    const forms_history = [];

    forms.forEach(({ form }) => {
      const fields_history = [];
      form.form_sections.forEach((section) => {
        section.section_fields.forEach((field) => {
          fields_history.push({ field_id: field.id, value: "", is_active: true })
        })
      })
      forms_history.push({ form_id: form.id, is_active: true, fields_history });
    });

    return forms_history;
  }
  const handleNextForm = () => {
    const index = currentForm.id;
    if (forms.length - 1 > index) {
      forms[index].is_current = false;
      forms[index].is_completed = true;
      forms[index + 1].is_current = true;
      setCurrentForm(forms[index + 1]);
    }
  };

  const handleSelectForm = (index) => {
    forms[currentForm.id].is_current = false;
    forms[index].is_current = true;
    setCurrentForm(forms[index]);
  };

  const getFieldIndex = (formIndex, fieldId) => {
    const { fields_history } = formik.values.forms_history[formIndex];
    return fields_history.findIndex((field) => field.field_id === fieldId);
  }

  const isLastForm = useMemo(() => currentForm.is_lasted, [currentForm])

  return forms.length > 0 && <React.Fragment>
    <div className="app-patientcare-form">
      <div className="app-patientcare-form-title">
        <span className="bold">Consulta Médica</span>
      </div>
      <div className="row d-wrap">
        <PatientCareInformationComponent />
      </div>
      <div className={`app-patientcare-steps-container ${type}`}>
        <div className="app-patientcare-steps-title">
          <span className="bold">Consulta Externa </span>/
          <span> Tipo de Actividad</span>
        </div>
        <div className="app-patientcare-steps-subtitle">
          Empieza en el paso 1 / {forms[0]?.form?.form_name}.
        </div>
        <PatientCareStepsComponent
          steps={forms}
          step={currentForm}
          handleSelectStep={handleSelectForm}
        />
      </div>
    </div>
    <div className="app-forms">
      <form onSubmit={formik.handleSubmit}>
        <div className="app-forms-header">
          <div className="left">
            <span className="bold">{currentForm?.form?.form_name}</span>
            <span>{DateFormat()}</span>
          </div>
          <div className="right">
            <img src={LogoDefault} alt="Logo Empresa" />
            <span className="bold">{session?.username}</span>
            <span>Médico General</span>
          </div>
        </div>
        {currentForm?.form?.form_sections?.map((section, index) => {
          const formIndex = currentForm.id;

          return <div className={section?.section_class} key={index}>
            <div className="app-forms-section-actions">
              <div className={`app-forms-section-title`}>
                {FormatTitle(section?.section_name)}
              </div>
            </div>
            <div
              className="row app-forms-section-fields"
              style={{ display: section?.section_show ? "" : "none" }}
            >
              {section?.section_fields.map((field, index) => {
                const fieldIndex = getFieldIndex(formIndex, field.id);
                return <FieldsComponent
                  key={index}
                  {...field}
                  field_id={`forms_history[${formIndex}].fields_history[${fieldIndex}].value`}
                  field_name={`forms_history[${formIndex}].fields_history[${fieldIndex}].value`}
                  onChange={formik.handleChange}
                  value={formik.values.forms_history[formIndex].fields_history[fieldIndex].value}
                />
              })}
            </div>
          </div>
        }
        )}
        <div className="app-forms-toolbar">
          {!isLastForm ? (
            <React.Fragment>
              <button
                className="btn app-forms-toolbar-button outline"
                type="button"
              >
                Cancelar
              </button>
              <button
                className="btn app-forms-toolbar-button"
                type="button"
                onClick={handleNextForm}
                disabled={currentForm?.is_completed}
              >
                Terminar Consulta
              </button>
            </React.Fragment>
          ) : (
            <button
              className="btn app-forms-toolbar-button"
              type="submit"
            >
              Ir a Historial Clínico
            </button>
          )}
        </div>
      </form>
    </div>
  </React.Fragment>

};

export default PatientCareComponent;
