import React from "react";

const PaginationCounter = (props) => {
  const { data, rows, setRows } = props;

  const handleChangeRow = (event) => {
    setRows(parseInt(event.target.value));
  };

  return (
    <div className="app-table-counter">
      <div className="app-table-rows">
        <span>Mostrar</span>
        <span>
          <select
            className="form-select"
            value={rows}
            onChange={handleChangeRow}
          >
            <option value="1">1</option>
            <option value="3">3</option>
            <option value="5">5</option>
            <option value="10">10</option>
            <option value="20">20</option>
            <option value="30">30</option>
            <option value="50">50</option>
            <option value="100">100</option>
            <option value={data.length}>Todos</option>
          </select>
        </span>
        <span>Registros</span>
      </div>
    </div>
  );
};

export default PaginationCounter;
