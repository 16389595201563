import axios from "axios";

class AxiosService {
  constructor() {
    this.token = undefined;
    this.client = axios.create({
      baseURL: process.env.REACT_APP_API_URL,
      maxBodyLength: Infinity,
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
    });
    this.checkToken();
  }

  getToken() {
    return this.token;
  }

  checkToken() {
    const token = sessionStorage.getItem("token");
    if (token) {
      this.token = token;
      this.client.defaults.headers.common["Authorization"] = `Bearer ${token}`;
      return true;
    }
    return false;
  }

  setToken(token) {
    if (token) {
      this.token = token;
      this.client.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    }
  }
}

const axiosService = new AxiosService();

export default axiosService;
