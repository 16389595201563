import React, { useContext, useEffect, useState } from "react";
import * as yup from "yup";
import { useFormik } from "formik";
import { useNavigate } from "react-router";
import SwalLoading from "../../../utils/SwalLoading";
import SwalSuccess from "../../../utils/SwalSuccess";
import PaginationCounter from "../../../utils/PaginationCounter";
import PaginationComponent from "../../pagination/PaginationComponent";
import Pagination from "../../../utils/Pagination";
import { postCatalog } from "../../../../services/settings/catalogs/CatalogsService";
import SessionContext from "../../../context/SessionContext";

const CreateCatalogsComponent = (props) => {
  const [data, setData] = useState([]);
  const [rows, setRows] = useState(10);
  const [current, setCurrent] = useState(1);
  const [pages, setPages] = useState([]);
  const [isChange, setIsChange] = useState();
  const { closeSession } = useContext(SessionContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (isChange) {
      getPagination();
      setIsChange(!isChange);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rows, isChange]);

  const formik = useFormik({
    initialValues: {
      name: "",
      is_active: true,
      catalog_fields: [],
    },
    validationSchema: yup.object({
      name: yup
        .string("Ingresa el nombre del catálogo")
        .required("Debes ingresar el nombre del catálogo"),
      is_active: yup.boolean("Seleccione una opción").default(() => true),
      catalog_fields: yup.array(
        yup.object({
          name: yup
            .string("Ingresa el nombre del campo")
            .required("Debes ingresar el nombre del campo"),
          value: yup
            .string("Ingresa el valor del campo")
            .required("Debes ingresar el valor del campo"),
          field_type: yup
            .string("Seleccione el estatus del campo")
            .required("Debes seleccionar el estatus del campo"),
          position: yup
            .number("Ingresa la posición del campo")
            .required("Debes ingresar una posición del campo"),
          is_active: yup
            .boolean("Seleccione el estatus del campo")
            .default(() => true),
        })
      ),
    }),
    onSubmit: async (values) => {
      SwalLoading({ title: "Creando Catálogo" });
      // clonar elementos de catalogo para no modificar el original.
      const catalog_fields = [...values.catalog_fields];

      // eliminar todos los id temporales para evitar problemas de registro de elementos.
      const data = {
        ...values,
        catalog_fields: catalog_fields.map((item) => {
          delete item.id;
          return item;
        }),
      };

      await postCatalog(data)
        .then((response) => {
          if (response.status === 200) {

            SwalSuccess({
              title: "Catálogo",
              message: "El catálogo ha sido creado exitosamente",
            });

            const id = response.data.id;

            navigate(`/configuracion/catalogos/${id}/ver`);

          }
        })
        .catch((error) => {
          const response = error.response;
          if (response.status === 401) {
            closeSession()
          }
        });
    },
  });

  useEffect(() => {
    setIsChange(!isChange);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rows, formik.values]);

  const handleAddField = () => {
    const catalog_fields = [...formik.values.catalog_fields];
    const size = catalog_fields.length;
    const id = size === 0 ? 1 : parseInt(catalog_fields[size - 1].id) + 1;
    const position = size === 0 ? 1 : parseInt(catalog_fields[size - 1].position) + 1;

    if (catalog_fields.length < 50) {
      catalog_fields.push({
        id,
        name: "",
        value: "",
        is_active: true,
        field_type: "",
        position,
      })
      setFieldsAttributtes(catalog_fields);
    }
  };

  const handleDelete = (id) => {
    const catalog_items = [...formik.values.catalog_fields];
    setFieldsAttributtes(catalog_items.filter((item) => item?.id !== id));
    setIsChange(!isChange);
  };

  const setFieldsAttributtes = (values) => {
    formik.setFieldValue("catalog_fields", values);
    setIsChange(!isChange);
  };

  const getPagination = () => {
    const catalog_items = [...formik.values.catalog_fields];
    const pages = Pagination(catalog_items, rows);
    setData(catalog_items);
    setCurrent(pages.length >= current ? current : 1);
    setPages(pages);
  };

  const getIndex = (id) => {
    const catalog_items = [...formik.values.catalog_fields];
    return catalog_items.findIndex((item) => item.id === id);
  };

  const SelectCatalogSorted = () => {
    return (
      <select className="form-select" id={`catalog-${formik.values.name}`}>
        <option value="0">seleccione una opción</option>
        {[...formik.values.catalog_fields]
          ?.sort((a, b) => {
            if (a.position > b.position) {
              return 1
            } else if (a.position < b.position) {
              return -1
            }
            return 0;
          })
          .filter((item) => item?.is_active !== "false")
          .map((item) => (
            <option key={item.id} value={item?.value}>
              {item?.name}
            </option>
          ))}
      </select>
    );
  }

  return (
    <form onSubmit={formik.handleSubmit}>
      <h1 className="app-form-title">Datos del Catálogo</h1>
      <div className="row d-flex">
        <div className="col-4 col-md-4 col-sm-4">
          <label className="app-form-label fs-5" htmlFor="name">
            Nombre Catalogo:
          </label>
          <input
            className="form-control"
            type="text"
            id="name"
            name="name"
            placeholder="Ingrese el nombre del catálogo"
            value={formik.values.name}
            onChange={formik.handleChange}
            required
          />
        </div>
        <div className="col-4 col-md-4 col-sm-4">
          <label className="app-form-label fs-5" htmlFor="is_active">
            Estatus:
          </label>
          <select
            className="form-select"
            name="is_active"
            id="is_active"
            value={formik.values.is_active}
            onChange={formik.handleChange}
          >
            <option value="">Seleccione una opción</option>
            <option value={true}>Activo</option>
            <option value={false}>Inactivo</option>
          </select>
        </div>
      </div>
      <div className="boxshadow white">
        <h1 className="boxshadow-title">ELEMENTOS ACTIVOS</h1>
        <h2 className="boxshadow-subtitle">
          {formik.values.catalog_fields?.length} de 50 campos activos
          y personalizados
        </h2>
        {pages.length > 0 && (
          <div className="border rounded p-3">
            {pages
              .find((item) => item.id === current)
              ?.items?.map((item) => {
                const index = getIndex(item.id);

                return (
                  <div key={item?.id} className="row">
                    <div className="col-3 col-md-3 col-sm-3">
                      <label
                        htmlFor="field_name"
                        className="app-form-label fs-5"
                      >
                        Nombre de Elemento {item.id}:
                      </label>
                      <input
                        className="form-control"
                        type="text"
                        id={`catalog_fields[${index}].name`}
                        name={`catalog_fields[${index}].name`}
                        value={item.name}
                        onChange={formik.handleChange}
                        placeholder="Ingrese el nombre del campo"
                        required
                      />
                    </div>
                    <div className="col-2 col-md-2 col-sm-2">
                      <label htmlFor="value" className="app-form-label fs-5">
                        Valor:
                      </label>
                      <input
                        className="form-control"
                        type={item.field_type || "text"}
                        id={`catalog_fields[${index}].value`}
                        name={`catalog_fields[${index}].value`}
                        value={item.value}
                        onChange={formik.handleChange}
                        placeholder="Ingrese el valor del campo"
                        required
                      />
                    </div>
                    <div className="col-2 col-md-2 col-sm-2">
                      <label
                        htmlFor="is_active"
                        className="app-form-label fs-5"
                      >
                        Mostrar Valor:
                      </label>
                      <select
                        className="form-select"
                        id={`catalog_fields[${index}].is_active`}
                        name={`catalog_fields[${index}].is_active`}
                        value={item.is_active}
                        onChange={formik.handleChange}
                        required
                      >
                        <option value="">Seleccione una opción</option>
                        <option value={true}>Si</option>
                        <option value={false}>No</option>
                      </select>
                    </div>
                    <div className="col-2 col-md-2 col-sm-2">
                      <label
                        htmlFor="field_type"
                        className="app-form-label fs-5"
                      >
                        Tipo de Dato:
                      </label>
                      <select
                        className="form-select"
                        id={`catalog_fields[${index}].field_type`}
                        name={`catalog_fields[${index}].field_type`}
                        value={item.field_type}
                        onChange={formik.handleChange}
                        required
                      >
                        <option value="">Seleccione una opción</option>
                        <option value="text">Texto</option>
                        <option value="number">Numerico</option>
                      </select>
                    </div>
                    <div className="col-2 col-md-2 col-sm-2">
                      <label htmlFor="position" className="app-form-label fs-5">
                        Posición:
                      </label>
                      <input
                        className="form-control"
                        type="number"
                        min={0}
                        max={49}
                        maxLength={2}
                        id={`catalog_fields[${index}].position`}
                        name={`catalog_fields[${index}].position`}
                        value={item.position}
                        onChange={formik.handleChange}
                        placeholder="0-49"
                        required
                      />
                    </div>
                    <div className="col">
                      <button
                        type="button"
                        className="btn btn-default"
                        onClick={() => handleDelete(item?.id)}
                      >
                        <i className="bi bi-trash text-danger"></i>
                      </button>
                    </div>
                  </div>
                );
              })}
          </div>
        )}
        {pages.length > 0 && (
          <div className="app-footer-actions m-4">
            <PaginationCounter data={data} rows={rows} setRows={setRows} />
            <PaginationComponent
              pages={pages}
              current={current}
              setCurrent={setCurrent}
            />
          </div>
        )}
        <div className="row">
          <div className="col-2 col-md-2 col-sm-2 mt-5">
            <button
              type="button"
              className="app-button-outline small"
              onClick={handleAddField}
            >
              Agregar Nuevo Elemento
            </button>
          </div>
        </div>
      </div>
      <div className="boxshadow white">
        <div className="app-container-table default">
          <h1 className="boxshadow-title">VISUALIZACIÓN DE CATÁLOGO</h1>
          <h1 className="boxshadow-subtitle bold m-1">
            Catálogo: {formik.values.name}
          </h1>
          <div className="form-group col-4 col-md-4 col-sm-4">
            <SelectCatalogSorted />
          </div>
        </div>
        <div className="app-footer-actions">
          <div className="app-table-records"></div>
          <div className="d-flex flex-row">
            <button className="app-button small bg-danger" type="button" onClick={() => navigate(-1)} >
              Cancelar
            </button>
            <button className="app-button small bg-green-ryb" type="submit" >
              Guardar
            </button>
          </div>
        </div>
      </div>
    </form>
  );


};

export default CreateCatalogsComponent;
