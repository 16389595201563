import React, { useContext, useState } from "react";
import RowDownIcon from "../../assets/icons/row-down.svg";
import KeyIcon from "../../assets/icons/key.svg";
import HelpIcon from "../../assets/icons/help.svg";
import PowerIcon from "../../assets/icons/power.svg";
import Swal from "sweetalert2";
import SwalSuccess from "../../utils/SwalSuccess";
import { useNavigate } from "react-router";
import ChangePasswordModalComponent from "./ChangePasswordModalComponent";
import SessionContext from "../../context/SessionContext";


const MenuHeaderComponent = () => {
  const navigate = useNavigate();
  const {session} = useContext(SessionContext);
  const [show, setShow] = useState(false);

  const handleCloseSesion = async () => {

    Swal.fire({
      title: "ATENCIÓN",
      html: "¿Desea Cerrar Sesión?",
      showCancelButton: true,
      confirmButtonText: "Aceptar",
      cancelButtonText: "Cancelar",
      showCloseButton: true,
      allowEscapeKey: false,
      closeButtonHtml: `<div class="swal2-header">
                            <div class="swal2-header-title">Cerrar Sesión</div>
                            <div class="swal2-header-close">
                              <button type="button" aria-label="Close this dialog">x</button>
                            </div>
                        </div>`,
    }).then(async (result) => {
      if (result.isConfirmed) {
        sessionStorage.clear();
        SwalSuccess({
          title: "Cerrando Sesión",
          message: "La sesión ha sido cerrada exitosamente.",
        });
        navigate("/login");
      }
    });
  };


  const toggleModal = () => setShow(!show);

  return (<>
    <ul>
      <span className="fs-6 truncate-text">Hola {session.username}</span>
      <li onClick={toggleModal}>
        <span className="icon">
          <img src={KeyIcon} alt="key" width="25px" height="25px" />
        </span>
        <span className="label">Cambiar Contraseña</span>
        <span className="divider" />
      </li>
      <li>
        <span className="icon">
          <img src={HelpIcon} alt="key" width="25px" height="25px" />
        </span>
        <span className="label">Ayuda</span>
        <span className="divider" />
      </li>
      <li onClick={handleCloseSesion}>
        <span className="icon">
          <img src={PowerIcon} alt="key" width="25px" height="25px" />
        </span>
        <span className="label">Cerrar Sesión</span>
      </li>
      <img src={RowDownIcon} alt="search" width="14px" height="8px" />
    </ul>
    <ChangePasswordModalComponent show={show} toggleModal={toggleModal} />
  </>
  );
};

export default MenuHeaderComponent;
