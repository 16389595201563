import React, { useState } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import { useNavigate, useParams } from "react-router";
import { regex_password } from "../../../utils/Regex";
import SwalLoading from "../../../utils/SwalLoading";
import SwalSuccess from "../../../utils/SwalSuccess";
import SwalError from "../../../utils/SwalError";
import { postChangePassword } from "../../../../services/auth/AuthService";
import "./ChangePassword.css";

const ChangePassword = () => {
  const { email, hashed_token } = useParams();
  const [isSending, setIsSending] = useState(false);
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      password: "",
      password_confirmation: "",
    },
    validationSchema: yup.object({
      password: yup
        .string("La contraseña es requeridad")
        .matches(
          regex_password,
          "La contraseña debe tener al menos un tamaño de 12, debe tener por lo menos una mayúscula, un número, minúsculas."
        )
        .required("La contraseña es requeridad"),
      password_confirmation: yup
        .string("La contraseña es requeridad")
        .oneOf([yup.ref("password"), null], "Las contraseñas no coinciden")
        .required("La confirmación de contraseña es requeridad"),
    }),
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: (values) => {
      SwalLoading({ title: "Cambiando contraseña" });
      setIsSending(!isSending);
      postChangePassword(email, hashed_token, values)
        .then((response) => {
          if (response.status === 200) {
            SwalSuccess({
              title: "Contraseña Actualizada",
              message: "La contraseña ha sido actualizada correctamente.",
            });
            setIsSending(false);
            goToLogin();
          }
        })
        .catch((error) => {
          const response = error.response;
          if([400].includes(response.status)){
            SwalError({title: "Error", message: response.data.detail})
          }else{
            SwalError({title: "Error no Controlado", message: JSON.stringify(response.data.detail)})
          }
          setIsSending(false);
        });
    },
  });

  const goToLogin = () => {
    navigate("/login");
  };

  return (
    <div className="container vh-100">
      <form className="form" onSubmit={formik.handleSubmit}>
        <p className="fs-5 text-center mb-4">Ingrese su nueva contraseña en los siguientes campos de texto.</p>
        <div className="form-group mb-3">
          <label className="app-form-label" htmlFor="password">
            Nueva Contraseña:
          </label>
          <input
            id="password"
            name="password"
            type="password"
            className="form-control"
            placeholder="Ingrese su nueva contraseña"
            value={formik.values.password}
            onChange={formik.handleChange}
            onBlur={formik.handleChange}
            autoComplete="off"
          />
          {formik.touched.password && (
            <div className="feedback-invalid text-danger">
              {formik.errors.password}
            </div>
          )}
        </div>
        <div className="form-group mb-3">
          <label className="app-form-label" htmlFor="password">
            Confirmar Nueva Contraseña:
          </label>
          <input
            id="password_confirmation"
            name="password_confirmation"
            type="password"
            className="form-control"
            placeholder="Ingrese su nueva contraseña"
            value={formik.values.password_confirmation}
            onChange={formik.handleChange}
            onBlur={formik.handleChange}
            autoComplete="off"
          />
          {formik.touched.password_confirmation && (
            <div className="feedback-invalid text-danger">
              {formik.errors.password_confirmation}
            </div>
          )}
        </div>
        <div className="buttons">
          <div>
            <button
              type="button"
              className="m-auto btn bg-danger text-white"
              onClick={goToLogin}
            >
              Cancelar
            </button>
          </div>
          <div>
            <button type="submit" className="m-auto btn">
              Cambiar Contraseña
            </button>
          </div>
        </div>
      </form>
      <div className="footer">
        <span>© 2022 Consulta Externa by HG Consulting</span>
      </div>
    </div>
  );
};

export default ChangePassword;
