import React, { useMemo, useState } from "react";

const UsersFormComponent = (props) => {
  const { state, setState } = props;
  const [search, setSearch] = useState("");


  const handleSearch = ({ target }) => {
    setSearch(target.value);
  };

  const handleSelected = (profileId) => {
    const profiles = [...state];
    setState(profiles.map((user_profile) => {
      if (user_profile.profile_id === profileId) {
        user_profile.is_selected = !user_profile.is_selected;
      }
      return user_profile;
    }))
  };

  const handleAssigned = () => {
    const profiles = [...state];
    setState(profiles.map((user_profile) => {
      if (user_profile.is_selected) {
        user_profile.is_assigned = true;
        user_profile.is_selected = false;
      }
      return user_profile;
    }))
  };

  const handleUnassigned = () => {
    const profiles = [...state];
    setState(profiles.map((user_profile) => {
      if (user_profile.is_selected) {
        user_profile.is_assigned = false;
        user_profile.is_selected = false;
      }
      return user_profile;
    }))
  };

  const handleDelete = () => {
    const profiles = [...state];
    setState(profiles.map((user_profile) => {
      user_profile.is_assigned = false;
      user_profile.is_selected = false;
      return user_profile;
    }))
  };

  const assigned = useMemo(() => state.filter((item) => item.is_assigned === true), [state]);

  const unassigned = useMemo(() => state.filter((item) => {
    return search.length > 0 ? item.is_assigned === false && item.name.includes(search) : item.is_assigned === false;
  }), [state, search]);

  return (
    <div className="row d-flex app-protocols-form">
      <div className="col">
        <div className="col-12 app-table-title fs-4 m-1">
          <label>Roles</label>
        </div>
        <div className="col-12 app-protocols-form-container white m-0">
          <div className="col">
            <input
              type="search"
              id="search"
              name="search"
              className="form-control"
              placeholder="Buscar perfil"
              onChange={handleSearch}
            />
          </div>
          {unassigned.length > 0 && (
            <div className="col app-protocols-list">
              {unassigned.map((profile) => (
                <div
                  key={profile.profile_id}
                  className={`app-protocols-list-item${profile?.is_selected ? " selected" : ""
                    }`}
                  onClick={() => handleSelected(profile.profile_id)}
                >
                  {profile?.name}
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
      <div className="col-1 col-md-1 col-sm-1">
        <div className="app-protocols-actions">
          <span>
            <button type="button" className="circle" onClick={handleAssigned}>
              <i className="fa-solid fa-chevron-right"></i>
            </button>
          </span>
          <span>
            <button type="button" className="circle" onClick={handleUnassigned}>
              <i className="fa-solid fa-chevron-left"></i>
            </button>
          </span>
          <span>
            <button type="button" className="square" onClick={handleDelete}>
              <i className="bi bi-trash"></i>
            </button>
          </span>
        </div>
      </div>
      <div className="col">
        <div className="col-12 app-table-title m-1 fs-4">
          <label>Roles Asignados</label>
        </div>
        <div className="col-12 app-protocols-form-container white m-0">
          <div className="row d-flex">
            <div className="col"></div>
          </div>
          {assigned.length > 0 && (
            <div className="col app-protocols-list">
              {assigned.map((profile) => (
                <div
                  key={profile.profile_id}
                  className={`app-protocols-list-item${profile?.is_selected ? " selected" : ""
                    }`}
                >
                  <div
                    className="col"
                    onClick={() => handleSelected(profile.profile_id)}
                  >
                    {profile?.name}
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default UsersFormComponent;
