import React from "react";

const SelectFieldTypeComponent = (props) => {
  const { id, name, className, onChange, value, required, readOnly } = props;

  return (
    <select
      id={id}
      name={name}
      className={className}
      value={value}
      onChange={onChange}
      required={required}
      readOnly={readOnly}
    >
      <option value="">Selecciona una opción</option>
      <option value="audiogram">Audiograma</option>
      <option value="catalog">Catalog</option>
      <option value="checkbox">Checkbox</option>
      <option value="email">Correo</option>
      <option value="date">Fecha</option>
      <option value="number">Número</option>
      <option value="radio">Radio</option>
      <option value="radiorow">Radio (Horizontal) </option>
      <option value="radiocol">Radio (Vertical)</option>
      <option value="tel">Teléfono</option>
      <option value="textarea">Textarea</option>
      <option value="text">Texto</option>
      <option value="time">Tiempo</option>
      <option value="switch">Switch</option>
    </select>
  );
};

export default SelectFieldTypeComponent;
