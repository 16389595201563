import React, { useContext, useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import DateFormatPersonalized from "../../../utils/DateFormatPersonalized";
import Pagination from "../../../utils/Pagination";
import PaginationComponent from "../../pagination/PaginationComponent";
import { getCatalogById } from "../../../../services/settings/catalogs/CatalogsService";
import SessionContext from "../../../context/SessionContext";
import SwalError from "../../../utils/SwalError";

const ShowCatalogsComponent = (props) => {
  const { id: catalogId } = useParams();
  const [data, setData] = useState([]);
  const [catalog, setCatalog] = useState({});
  const [current, setCurrent] = useState(1);
  const [pages, setPages] = useState([]);
  const [rows] = useState(10);
  const navigate = useNavigate();
  const {closeSession} = useContext(SessionContext);

  useEffect(() => {

    getCatalogById(catalogId).then((response)=> {

      if (response.status === 200) {
        const data = response.data;
        setCurrent(1);
        setCatalog(data);
        setData(data?.catalog_fields);
        setPages(Pagination(data?.catalog_fields, rows));
      }

    }).catch((error)=> {
      const response = error.response;
      if(response.status === 401){
        closeSession();
      }else if([400].includes(response.status)){
        SwalError({title: "Error", message: response.data.detail})
      }else {
        SwalError({title: "Error no Controlado", message: JSON.stringify(response.data.detail)})
      }
    })

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleBack = () => {
    navigate(-1);
  };

  const handleSearch = (e) => {
    const catalog_fields = [...catalog?.catalog_fields];
    let data = null;
    const value = e.target.value;

    if (value !== "") {
      data = catalog_fields.filter(
        (item) =>
          item?.name.toLowerCase().includes(value.toLowerCase()) |
          item?.value.toLowerCase().includes(value.toLowerCase()) |
          item?.field_type.toLowerCase().includes(value.toLowerCase()) |
          (item?.is_active ? "Si" : "No")
            .toLowerCase()
            .includes(value.toLowerCase())
      );
    }

    setCurrent(1);
    setData(data ? data : catalog_fields);
    setPages(Pagination(data ? data : catalog_fields, rows));
  };

  const ToDateFormat = (date) => DateFormatPersonalized(date,"YYYY-MM-DD HH:mm:ss");

  return (
    <div>
      <div className="app-container-actions">
        <div className="app-container-actions-label">
          <div className="app-form-search">
            <label className="app-form-label" htmlFor="search">
              Buscar Catálogo
            </label>
            <input
              type="search"
              className="form-control"
              id="search"
              name="search"
              onChange={handleSearch}
              placeholder="Ingrese el nombre de protocolo"
            />
          </div>
        </div>
        <div className="app-container-actions-button"></div>
      </div>
      <div className="boxshadow white">
        <div className="boxshadow-subtitle fs-5">
          <span className="bold">Catálogo: </span>
          <span>{catalog?.name}</span>
        </div>
        <Table striped hover>
          <thead className="bg-green-ryb">
            <tr>
              <th>ID</th>
              <th>Nombre</th>
              <th>Valor</th>
              <th>Mostrar Valor</th>
              <th>Tipo de Valor</th>
              <th>Posición</th>
              <th>Fecha Creación</th>
              <th>Fecha Actualización</th>
            </tr>
          </thead>
          <tbody>
            {pages
              .find((item) => item.id === current)
              ?.items.map((item, index) => (
                <tr key={index}>
                  <td>{item.id}</td>
                  <td>{item.name}</td>
                  <td>{item.value}</td>
                  <td>{item.is_active ? "Si" : " No"}</td>
                  <td>{item.field_type}</td>
                  <td>{item.position}</td>
                  <td>
                    {ToDateFormat(item.created_at)}
                  </td>
                  <td>
                    {ToDateFormat(item.updated_at)}
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
      </div>
      <div className="app-footer-actions">
        <div className="app-table-records">
          {`Catálogos Registrados: ${
            data.length > 9 ? data.length : `0${data.length}`
          }`}
        </div>
        <PaginationComponent
          pages={pages}
          current={current}
          setCurrent={setCurrent}
        />
        <div className="app-table-button">
          <button
            className="app-button small bg-green-ryb"
            onClick={handleBack}
          >
            Regresar
          </button>
        </div>
      </div>
    </div>
  );
};

export default ShowCatalogsComponent;
