/* usuarios */
import axiosService from "../../connection/AxiosService";

export async function getUsers() {
  if (axiosService.checkToken()) {
    return await axiosService.client.get(`/users`);
  }
}

export async function postUser(data = null) {
  if (axiosService.checkToken()) {
    return await axiosService.client.post(`/users`, data);
  }
}

export async function getUserById(id = null) {
  if (axiosService.checkToken()) {
    if (id) {
      return await axiosService.client.get(`/users/${id}`);
    }
  }
}

export async function putUserById(userId = null, data = null) {
  if (axiosService.checkToken()) {
    return await axiosService.client.put(`/users/${userId}`, data);
  }
}

export async function deleteUserById(userId = null) {
  if (axiosService.checkToken()) {
    return await axiosService.client.delete(`/users/${userId}`);
  }
}
