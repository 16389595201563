import React, { useContext, useEffect, useState } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import ProtocolsFormComponent from "./ProtocolsFormComponent";
import SwalError from "../../../utils/SwalError";
import { useNavigate } from "react-router";
import SwalSuccess from "../../../utils/SwalSuccess";
import SwalLoading from "../../../utils/SwalLoading";
import Swal from "sweetalert2";
import { getForms } from "../../../../services/settings/forms/FormService";
import { postProtocol } from "../../../../services/settings/protocols/ProtocolsService";
import SessionContext from "../../../context/SessionContext";

const CreateProtocolsComponent = (props) => {
  const navigate = useNavigate();
  const initialValues = { name: "", is_active: true, protocol_forms: [] };
  const [data, setData] = useState([]);
  const [isAgain, setIsAgain] = useState(false);
  const { closeSession } = useContext(SessionContext);

  useEffect(() => {
    SwalLoading({ title: "Consultando información" });
    getForms()
      .then((response) => {
        if (response?.status === 200) {
          const data = response.data;
          setData(data);
          const forms = setupInitialValues(data);
          formik.setFieldValue("protocol_forms", forms);

          Swal.close();
        }
      })
      .catch((error) => {
        const response = error.response;
        if (response.status === 401) {
          closeSession();
        }
      });
    // eslint-disable-next-line
  }, []);



  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: yup.object({
      name: yup
        .string("Describa el tipo de actividad")
        .required("Debe escribir el tipo de actividad"),
      protocol_forms: yup.array(yup.object({
        form_id: yup.number().required(),
        is_required: yup.boolean().required(),
        is_active: yup.boolean().required()
      })).min(1)
    }),
    onSubmit: (values) => {

      const protocol_forms = [...values.protocol_forms]
        .filter((form) => form.is_assigned === true)
        .map(({ name, is_assigned, is_selected, ...rest }) => rest);

      if (protocol_forms.length > 0) {

        SwalLoading({ title: "Creando protocolo" });

        const data = { ...values, protocol_forms };

        postProtocol(data)
          .then((response) => {
            if (response.status === 200) {
              SwalSuccess({
                title: "Protocolo de Atención",
                message: "El protocolo ha sido creado exitosamente",
              });
              if (!isAgain) {
                navigate(-1);
              }
              handleReset();
            }
          })
          .catch((error) => {
            const response = error.response;
            if (response.status === 401) {
              closeSession();
            } else {
              SwalError({
                title: "Error no Controlado",
                message: JSON.stringify(response.data.detail),
              });
            }
          });
      } else {
        SwalError({
          title: "Error",
          message: "Debe asignar al menos un formulario",
        });
      }
    },
  });

  const setupInitialValues = (array) => {
    return array.map((value) => {
      return {
        form_id: value.id,
        name: value?.form_name,
        is_active: true,
        is_required: false,
        is_assigned: false,
        is_selected: false,
      };
    });
  };

  const handleAgain = () => {
    setIsAgain(!isAgain);
  };

  const handleReset = () => {
    const forms = setupInitialValues(data)
    formik.handleReset();
    formik.setValues({ ...initialValues, protocol_forms: forms });
  };

  const handleChange = (value) => formik.setFieldValue("protocol_forms", value)


  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="mb-4">
        <div className="row d-flex">
          <div className="col-4 col-md-4 col-sm-4">
            <label htmlFor="name" className="app-form-label">
              Tipo de Actividad
            </label>
            <input
              id="name"
              name="name"
              placeholder="Escriba el tipo de actividad"
              className={`form-control ${formik.touched.name && formik.errors.name ? "is-invalid" : ""
                }`}
              value={formik.values.name}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              required={true}
            />
            {formik.touched.name && formik.errors.name && (
              <div className="d-flex invalid-feedback">
                {formik.errors.name}
              </div>
            )}
          </div>
          <div className="col-4 col-md-4 col-sm-4">
            <label htmlFor="is_active" className="app-form-label">
              Estatus
            </label>
            <select
              id="is_active"
              name="is_active"
              placeholder="Escriba el tipo de actividad"
              className={`form-select ${formik.touched.is_active && formik.errors.is_active ? "is-invalid" : ""
                }`}
              value={formik.values.is_active}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              required={true}
            >
              <option disabled>Seleccione una opción</option>
              <option value={true}>Activo</option>
              <option value={false}>Inactivo</option>
            </select>
            {formik.touched.is_active && formik.errors.is_active && (
              <div className="d-flex invalid-feedback">
                {formik.errors.is_active}
              </div>
            )}
          </div>
        </div>
      </div>
      <div>
        <ProtocolsFormComponent
          state={formik.values.protocol_forms}
          setState={handleChange}
        />
      </div>
      <div className="app-protocols-footer">
        <div>
          <button type="sumbit" className="app-button bg-green-ryb">
            Guardar Registro
          </button>
        </div>
        <div>
          <button
            type="sumbit"
            className="app-button bg-maximun-blue-green"
            onClick={handleAgain}
          >
            Guardar y registrar otro prótocolo
          </button>
        </div>
        <div>
          <button
            type="reset"
            className="app-button default"
            onClick={() => navigate(-1)}
          >
            Cancelar
          </button>
        </div>
      </div>
    </form>
  );
};

export default CreateProtocolsComponent;
