import React, { useContext, useEffect } from "react";
import DateFormat from "../../../utils/DateFormat";
import { useNavigate } from "react-router";
import EditDoctorsComponent from "../../../components/settings/doctors/EditDoctorsComponent";
import SessionContext from "../../../context/SessionContext";
import usePermission from "../../../hooks/usePermission";
import permissions from "../../../json/route_permission.json";

const EditDoctors = (props) => {
  const navigate = useNavigate();
  const { session } = useContext(SessionContext);
  const permitted = usePermission(permissions.settings.doctors.edit);

  useEffect(() => {

    if (permitted && !permitted) {
      navigate("not-found");
    }

    return () => { };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [permitted]);

  return permitted && (
    <React.Fragment>
      <div className="app-container-header">
        <div className="left">
          <span onClick={() => navigate(-1)}>
            <span>
              <i className="fa-solid fa-arrow-left fs-auto"></i>
            </span>
            <span className="bold">Médicos / Editar Medico</span>
          </span>
          <span>{DateFormat()}</span>
        </div>
        <div className="right">
          <span className="bold">{session?.username}</span>
          <span>{session.email}</span>
        </div>
      </div>
      <div className="app-settings">
        <EditDoctorsComponent />
      </div>
    </React.Fragment>
  );
};

export default EditDoctors;
