import React from 'react'
import { Outlet } from 'react-router'
import "./Dates.css";

const Dates = () => {
    return (
        <div className='app-dates'>
            <Outlet />
        </div>
    );
}

export default Dates;
