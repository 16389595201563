import axiosService from "../connection/AxiosService";

export async function getExtraFields() {
    if (axiosService.checkToken()) {
        return await axiosService.client.get("/extra_fields");
    }
}

export async function postPatient(data) {
    if (axiosService.checkToken()) {
        if (data) {
            return await axiosService.client.post("/patients", data);
        }
    }
}

export async function postPatientsSearch(data) {
    if (axiosService.checkToken()) {
        if (data) {
            return await axiosService.client.post("/patients/search", data);
        }
    }
}


export async function getPatients() {
    if (axiosService.checkToken()) {
        return await axiosService.client.get("/patients");
    }
}

export async function getPatientById(patientId) {
    if (axiosService.checkToken()) {
        if (patientId) {
            return await axiosService.client.get(`/patients/${patientId}`);
        }
    }
}

export async function getPatientAppointments(patientId) {
    if (axiosService.checkToken()) {
        return await axiosService.client.get(`/patients/${patientId}/appointments`);
    }
}

export async function putPatientById(patientId = null, data = null) {
    if (axiosService.checkToken()) {
        if (patientId && data) {
            return await axiosService.client.put(`/patients/${patientId}`, data);
        }
    }
}

export async function deletePatientById(patientId = null) {
    if (axiosService.checkToken()) {
        if (patientId) {
            return await axiosService.client.delete(`/patients/${patientId}`);
        }
    }
}
