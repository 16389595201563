import React, { useEffect } from "react";

const FieldInputRadioColRow = (props) => {
  useEffect(() => {
    return () => { };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const split = (value) => {
    const elements = value.split("\n");
    return (
      <>
        {elements.map((element, index) => (
          <span key={index} className="d-flex">
            {element}
          </span>
        ))}
      </>
    );
  };

  return (
    <div
      className={`form-${props?.field_type} ${props?.field_class}`}
      style={props.field_style}
    >
      <div className="col-6">
        <label className="form-label">{split(props.field_label)}</label>
      </div>
      <div className="col-6">
        <div className="options">
          {props.options.map((option) => (
            <div key={option.id} className="option">
              <label htmlFor={props?.field_id + option.id}>{option.name}</label>
              <input
                id={props.field_id || ""}
                name={props.field_name || ""}
                type="radio"
                className="form-check-input"
                placeholder={props.field_placeholder || ""}
                cols={props.field_cols || ""}
                rows={props.field_rows || ""}
                value={option.value}
                checked={!props?.value ? false : Boolean(props?.value === option?.value)}
                onChange={!props.isPreview ? props.onChange : () => { }
                }
                readOnly={!props.isPreview ? false : true}
                disabled={!props.isPreview ? false : true}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default FieldInputRadioColRow;
