import React, { useEffect } from "react";
import ToBoolean from "../../../utils/ToBoolean";

const FieldInputRadio = (props) => {
  useEffect(() => {
    return () => { };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      className={`${props?.field_class} form-group-${props?.field_type}`}

    >
      <div>
        <label className="form-label" htmlFor={`${props?.field_id}`}>
          {props?.field_label}
        </label>
      </div>
      <div className="form-group">
        {props?.options.map((option, index) => (
          <div key={index}>
            <label
              className="form-check-label"
              htmlFor={props?.field_id + option.id}
            >
              {option.name}
            </label>
            <input
              type="radio"
              name={props.field_name}
              id={props.field_id + option.id}
              className="form-check-input"
              value={option.value}
              checked={props?.value === option?.value}
              onChange={!props.isPreview ? props.onChange : () => { }}
              required={!props.isPreview ? ToBoolean(props.is_required) : false}
              readOnly={!props.isPreview ? false : true}
            ></input>
          </div>
        ))}
      </div>
    </div>
  );
};

export default FieldInputRadio;
