import React from "react";

const FieldInputSwitch = (props) => {

  return (
    <div
      className={`form-group-${props.field_type} ${props.field_class} mr-1 ml-1`}
      style={props.field_style}
    >
      <input
        type="checkbox"
        id={props.field_id}
        name={props.field_name}
        checked={props?.value || false}
        onChange={!props.isPreview ? props.onChange : () => { }}
        readOnly={props?.is_readonly}
        autoComplete="off"
      />
      <label htmlFor={`${props.field_id}`}>{props.field_label}</label>
    </div>
  );
};

export default FieldInputSwitch;
