import axiosService from "../../connection/AxiosService";

export async function getCompanies() {
    if (axiosService.checkToken()) {
        return await axiosService.client.get("/companies");
    }
}

export async function getCompanyBranchesById(companyId) {
    if (axiosService.checkToken()) {
        return await axiosService.client.get(`/companies/${companyId}/branches`);
    }
}

export async function getSpecialities(branchId) {
    if (axiosService.checkToken()) {
        return await axiosService.client.get(`/branches/${branchId}/specialities`);
    }
}


export async function postDoctor(data) {
    if (axiosService.checkToken()) {
        return await axiosService.client.post("/doctors", data);
    }
}

export async function getDoctors() {
    if (axiosService.checkToken()) {
        return await axiosService.client.get("/doctors");
    }
}

export async function getDoctorById(doctorId) {
    if (axiosService.checkToken()) {
        return await axiosService.client.get(`/doctors/${doctorId}`);
    }
}

export async function putDoctorById(doctorId, data) {
    if (axiosService.checkToken()) {
        return await axiosService.client.put(`/doctors/${doctorId}`, data);
    }
}
