import React, { useContext, useEffect, useState } from "react";
import { useFormik } from "formik";
import { regex_options, regex_placeholder } from "../../../utils/Regex";
import { useNavigate, useParams } from "react-router";
import * as yup from "yup";
import SelectSectionClassComponent from "./selects/SelectSectionClassComponent";
import SelectFieldTypeComponent from "./selects/SelectFIeldTypeComponent";
import SelectFieldClassComponent from "./selects/SelectFIeldClassComponent";
import SelectCatalogsComponent from "./selects/SelectCatalogsComponent";
import FieldsComponent from "./FieldsComponent";
import SwalLoading from "../../../utils/SwalLoading";
import FormJsonUpdate from "../../../utils/FormJsonUpdate";
import SwalSuccess from "../../../utils/SwalSuccess";
import FormatTitle from "../../../utils/FormatTitle";
import ToBoolean from "../../../utils/ToBoolean";
import PaginationComponent from "../../pagination/PaginationComponent";
import PaginationCounter from "../../../utils/PaginationCounter";
import Pagination from "../../../utils/Pagination";
import SwalError from "../../../utils/SwalError";
import { getFormById, putFormById } from "../../../../services/settings/forms/FormService";
import SessionContext from "../../../context/SessionContext";

const EditFormsComponent = (props) => {
  const { id: formId } = useParams();
  const [tabSelected, setTabSelected] = useState(null);
  const [data, setData] = useState([]);
  const [rows, setRows] = useState(3);
  const [current, setCurrent] = useState(1);
  const [currentSection, setCurrentSection] = useState([]);
  const [pages, setPages] = useState([]);
  const [isChange, setIsChange] = useState(true);
  const navigate = useNavigate();
  const { closeSession } = useContext(SessionContext);

  useEffect(() => {
    if (isChange) {
      getFormById(formId)
        .then(async (response) => {
          if (response.status === 200) {
            await formik.setValues(response.data);
            setIsChange(!isChange);
          }
        })
        .catch((error) => {
          const response = error.response;
          if (response.status === 401) {
            closeSession();
          } else if ([400].includes(response.status)) {
            SwalError({
              title: "Error",
              message: response.data.detail,
            });
          } else {
            SwalError({
              title: "Error No Controlado",
              message: JSON.stringify(response.data.detail),
            });
          }
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isChange) {
      getTabSelected();
      setIsChange(!isChange);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabSelected, isChange]);

  const initialSectionValues = {
    section_name: "",
    section_show: true,
    section_class: "app-forms-container",
    form_id: formId,
    section_fields: [],
    is_new: true,
    is_active: true,
  };

  const initialFieldValues = {
    section_id: null,
    field_label: "",
    field_name: "",
    field_id: "",
    field_type: "",
    field_max: 0,
    field_min: 0,
    field_rows: 0,
    field_cols: 0,
    field_placeholder: "",
    field_class: "col-3 col-md-3 col-sm-3",
    catalog_id: null,
    is_required: false,
    is_readonly: false,
    is_active: true,
    is_new: true,
    field_options: [],
  };

  const initialOptionValues = {
    field_id: null, is_active: true, is_new: true
  }

  const formik = useFormik({
    initialValues: {
      id: 0,
      form_name: "",
      is_active: true,
      form_sections: [],
    },
    validationSchema: yup.object({
      form_name: yup
        .string("Ingresa el nombre del formulario")
        .required("El nombre del formulario es requerido"),
      is_active: yup
        .boolean()
        .default(() => true)
        .required("Debe seleccionar una opción"),
      form_sections: yup.array(
        yup.object().shape({
          id: yup.number().nullable(),
          section_name: yup.string("Ingrese el nombre de la sección"),
          section_show: yup.boolean().default(() => true),
          section_class: yup
            .string("Seleccione una clase")
            .default(() => "app-forms-container")
            .required("Debe seleccionar al menos una clase"),
          is_active: yup.boolean().default(() => true),
          section_fields: yup.array(
            yup.object({
              id: yup.number().nullable(),
              field_label: yup.string("Ingresa el nombre del campo").nullable(),
              field_name: yup
                .string("Ingresa el nombre de la propiedad name")
                .required("Debes asignarle un nombre a la propiedad name"),
              field_id: yup
                .string("Ingresa el nombre de la propiedad id")
                .required("El nombre de la propiedad id es requerido"),
              field_type: yup
                .string("Seleccione una opción")
                .required("Debe seleccionar una opción"),
              field_max: yup
                .number("Ingresa el tamaño maximo")
                .default(() => "")
                .nullable(),
              field_min: yup
                .number("Ingresa el tamaño minimo")
                .default(() => "")
                .nullable(),
              field_rows: yup.mixed("Ingresa el número de filas").nullable(),
              field_cols: yup.mixed("Ingresa el número de filas").nullable(),
              field_placeholder: yup
                .string("Ingresa el placeholder")
                .nullable(),
              field_class: yup
                .string("Seleccione una opción")
                .required("Debe seleccionar una opción"),
              catalog_id: yup.number().nullable(),
              is_required: yup
                .boolean()
                .default(() => false)
                .nullable(),
              is_readonly: yup
                .boolean()
                .default(() => false)
                .nullable(),
              is_active: yup.boolean().default(() => true),
              field_options: yup
                .array(
                  yup.object({
                    id: yup.number().nullable(),
                    name: yup.string(""),
                    value: yup.string(""),
                    is_active: yup.boolean().default(() => true)
                  })
                )
                .default(() => [])
            })
          ),
        })
      ),
    }),
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: async (values) => {
      SwalLoading({ title: "Actualizando Formulario" });
      const data = FormJsonUpdate({ ...values });
      await putFormById(formId, data)
        .then((response) => {
          if (response.status === 200) {
            navigate(-1);
            SwalSuccess({
              title: "Formulario Actualizado",
              message: "El formulario ha sido actualizado exitosamente.",
            });
          }
        })
        .catch((error) => {
          const response = error.response;
          if (response.status === 401) {
            closeSession();
          } else if ([400].includes(response.status)) {
            SwalError({
              title: "Error",
              message: response.data.detail,
            });
          } else {
            SwalError({
              title: "Error no Controlador",
              message: response.data.detail,
            });
          }
        });
    },
  });

  useEffect(() => {
    setIsChange(!isChange);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rows, formik.values.form_sections]);

  const setSectionsAttributes = (sections) => {
    formik.setFieldValue("form_sections", sections);
    setIsChange(!isChange);
  };

  const handleAddSection = () => {
    const sections = formik.values.form_sections;
    const size = sections.length;
    const id = size === 0 ? 1 : sections[size - 1]?.id + 1;
    sections.push({ id, ...initialSectionValues });
    setSectionsAttributes(sections);
  };

  const isSomeFieldEmpty = (section_id) => {
    const section = [...formik.values.form_sections].find((section) => section.id === section_id);
    const elements = [
      "field_id",
      "field_class",
      "field_label",
      "field_name",
      "field_type"
    ];

    return section.section_fields.some((field) => {
      return elements.some((element) => {
        if (field[element] === "") {
          return true;
        } else {
          return false;
        }
      })
    });
  };


  const handleAddField = (section_id) => {
    if (!isSomeFieldEmpty(section_id)) {
      const sections = [...formik.values.form_sections];
      setSectionsAttributes(sections.map((section) => {
        if (section.id === section_id) {
          const fields = section.section_fields;
          const size = fields.length;
          const field_id = size === 0 ? 1 : fields[size - 1]?.id + 1;
          const unique = `field${section_id}${field_id}`;
          section.section_fields.push({
            ...initialFieldValues,
            id: field_id,
            field_id: unique,
            field_name: unique,
          });
        }
        return section;
      }));
    } else {
      SwalError({
        title: "Error",
        message:
          "Debes llenar el campo vacio para poder agregar uno nuevo",
      });
    }
  };


  const handleAddFieldOption = (section_id, field_id) => {
    const sections = formik.values.form_sections;
    setSectionsAttributes(sections.map((section) => {
      if (section.id === section_id) {
        const fields = section.section_fields;
        fields.map((field) => {
          if (field.id === field_id) {
            const field_options = field.field_options;
            const size = field_options.length;
            const option_id = size === 0 ? 1 : field_options[size - 1]?.id + 1;
            field.field_options.push({ ...initialOptionValues, id: option_id, name: "", value: "" });
          }
          return field;
        });
      }
      return section;
    }));
  };

  const handleDeleteSection = (section_id) => {
    const sections = [...formik.values.form_sections];
    setSectionsAttributes(sections.filter((section) => section.id !== section_id));
    setTabSelected(null);
  };

  const handleDeleteField = (section_id, field_id) => {
    const sections = [...formik.values.form_sections];
    setSectionsAttributes(sections.map((section) => {
      if (section.id === section_id) {
        section.section_fields = section.section_fields.filter(
          (field) => field.id !== field_id
        );
      }
      return section;
    }));
  };

  const handleDeleteOptions = (e, section_id, field_id) => {
    if (!e.target.value.match(regex_options)) {
      const sections = [...formik.values.form_sections];
      setSectionsAttributes(sections.map((section) => {
        if (section.id === section_id) {
          section?.section_fields.map((field) => {
            if (field.id === field_id) {
              field.field_options = [];
            }
            return field;
          });
        }
        return section;
      }));
    }
  };

  const handleDeleteOption = (section_id, field_id, option_id) => {
    const sections = [...formik.values.form_sections];
    setSectionsAttributes(sections.map((section) => {
      if (section.id === section_id) {
        section?.section_fields.map((field) => {
          if (field.id === field_id) {
            field.field_options = field?.field_options?.filter(
              (option) => option.id !== option_id
            );
          }
          return field;
        });
      }
      return section;
    }));
  };

  const handleTabSelected = (id) => {
    setTabSelected(id);
    setIsChange(!isChange);
  };

  const getTabSelected = () => {
    const sections = [...formik.values.form_sections];
    const size = sections.length;
    if (size > 0) {
      const id = !tabSelected ? sections[0].id : tabSelected;
      const section = sections.filter((item) => item?.id === id);
      const fields = section[0].section_fields;
      const pages = Pagination(fields, rows);
      setCurrent(pages.length >= current ? current : 1);
      setData(fields);
      setPages(pages);
      setTabSelected(id);
      setCurrentSection(section);
    }
  };

  const getSectionIndex = (id) => {
    const sections = formik.values.form_sections;
    return sections.findIndex((section) => section.id === id);
  };

  const getFieldIndex = (section_id, field_id) => {
    let index = null;
    const sections = formik.values.form_sections;
    sections.forEach((section) => {
      if (section.id === section_id) {
        index = section.section_fields.findIndex(
          (field) => field.id === field_id
        );
      }
    });
    return index;
  };

  return (
    <React.Fragment>
      <form onSubmit={formik.handleSubmit}>
        <div className="row d-flex">
          <div className="col-6 col-md-6 col-sm-6">
            <label className="app-form-label" htmlFor="form_name">
              <strong className="text-danger">*</strong>Nombre de Formulario:
            </label>
            <input
              id="form_name"
              name="form_name"
              className={`form-control ${formik.touched.form_name && formik.errors.form_name
                ? "is-invalid"
                : ""
                }`}
              placeholder="Escribe el nombre del formulario"
              value={formik.values.form_name}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              required
            />
            {formik.touched.form_name && formik.errors.form_name && (
              <div className="d-flex invalid-feedback position-absolute">
                {formik.errors.form_name}
              </div>
            )}
          </div>
          <div className="col-6 col-md-6 col-sm-6">
            <label className="app-form-label" htmlFor="is_active">
              <strong className="text-danger">*</strong> Estatus:
            </label>
            <select
              id="is_active"
              name="is_active"
              className={`form-select ${formik.touched.is_active && formik.errors.is_active
                ? "is-invalid"
                : ""
                }`}
              value={formik.values.is_active}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              required
            >
              <option value="">seleccione una opción</option>
              <option value={true}>Activo</option>
              <option value={false}>Inactivo</option>
            </select>
            {formik.touched.is_active && formik.errors.is_active && (
              <div className="d-flex invalid-feedback">
                {formik.errors.is_active}
              </div>
            )}
          </div>
          {formik.values.form_sections.length > 0 && (
            <div className="col-12 col-md-12 col-sm-12 mt-4">
              <div className="col-12 col-md-12 col-sm-12">
                <div className="app-forms-tabs">
                  {formik.values.form_sections.map((section) => (
                    <button
                      key={section.id}
                      type="button"
                      className={`app-forms-tabs-item${tabSelected === section.id ? " active" : ""
                        }`}
                      onClick={() => handleTabSelected(section.id)}
                    >{`Sección ${section.id} `}</button>
                  ))}
                </div>
              </div>
              <div className="app-forms-sections-container">
                {currentSection.map((section) => {
                  const index = getSectionIndex(section.id);
                  return (
                    <div key={section.id} className="row d-flex">
                      <div className="col">
                        <div className="form-group">
                          <label
                            className="app-form-label"
                            htmlFor="section_name"
                          >
                            Nombre de Sección (Opcional):
                          </label>
                          <input
                            id={`form_sections[${index}].section_name`}
                            name={`form_sections[${index}].section_name`}
                            className="form-control"
                            placeholder="Escribe el nombre de la sección"
                            value={section?.section_name}
                            onChange={formik.handleChange}
                          />
                        </div>
                      </div>
                      <div className="col">
                        <div className="form-group">
                          <label
                            className="app-form-label"
                            htmlFor="section_class"
                          >
                            <strong className="text-danger">*</strong> Diseño:
                          </label>
                          <SelectSectionClassComponent
                            className="form-select"
                            id={`form_sections[${index}].section_class`}
                            name={`form_sections[${index}].section_class`}
                            value={section?.section_class}
                            onChange={formik.handleChange}
                          />
                        </div>
                      </div>
                      <div className="col">
                        <div className="form-group">
                          <label
                            className="app-form-label"
                            htmlFor="section_show"
                          >
                            <strong className="text-danger">*</strong> Acciones:
                          </label>
                          <select
                            className="form-select"
                            id={`form_sections[${index}].section_show`}
                            name={`form_sections[${index}].section_show`}
                            value={ToBoolean(section?.section_show)}
                            onChange={formik.handleChange}
                          >
                            <option value="">Seleccione una opción</option>
                            <option value={true}>Mostrar</option>
                            <option value={false}>Ocultar</option>
                          </select>
                        </div>
                      </div>
                      <div className="col">
                        <div className="form-group">
                          <label className="app-form-label" htmlFor="is_active">
                            <strong className="text-danger">*</strong> Estatus:
                          </label>
                          <select
                            className={`form-select`}
                            id={`form_sections[${index}].is_active`}
                            name={`form_sections[${index}].is_active`}
                            value={section?.is_active}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          >
                            <option value="">Seleccione una opción</option>
                            <option value={true}>Activo</option>
                            <option value={false}>Inactivo</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-12 col-md-12 col-sm-12">
                        {section.section_fields.length > 0 && (
                          <div className="app-forms-fields-container">
                            {pages
                              .find((item) => item.id === current)
                              ?.items.map((field) => {
                                const index2 = getFieldIndex(
                                  section.id,
                                  field.id
                                );
                                return (
                                  <div
                                    key={field.id}
                                    className="app-forms-fields-item"
                                  >
                                    <div className="row d-flex">
                                      <div className="col-4 col-md-4 col-sm-4">
                                        <div className="form-group">
                                          <label
                                            className="app-form-label"
                                            htmlFor="field_type"
                                          >
                                            <strong className="text-danger">
                                              *
                                            </strong>
                                            Tipo de Campo:
                                          </label>
                                          <SelectFieldTypeComponent
                                            className={`form-select`}
                                            id={`form_sections[${index}].section_fields[${index2}].field_type`}
                                            name={`form_sections[${index}].section_fields[${index2}].field_type`}
                                            value={field?.field_type}
                                            onChange={formik.handleChange}
                                            onClick={(e) =>
                                              handleDeleteOptions(
                                                e,
                                                section.id,
                                                field.id
                                              )
                                            }
                                            required
                                          />
                                        </div>
                                      </div>
                                      <div className="col-4 col-md-4 col-sm-4">
                                        <div className="form-group">
                                          <label
                                            className="app-form-label"
                                            htmlFor="section_name"
                                          >
                                            Nombre de Etiqueta (Opcional):
                                          </label>
                                          <input
                                            id={`form_sections[${index}].section_fields[${index2}].field_label`}
                                            name={`form_sections[${index}].section_fields[${index2}].field_label`}
                                            className="form-control"
                                            placeholder="Escribe el nombre de la etiqueta"
                                            value={field?.field_label}
                                            onChange={formik.handleChange}
                                          />
                                        </div>
                                      </div>
                                      {field?.field_type.match(
                                        regex_placeholder
                                      ) && (
                                          <div className="col-4 col-md-4 col-sm-4">
                                            <div className="form-group">
                                              <label
                                                className="app-form-label"
                                                htmlFor="field_placeholder"
                                              >
                                                <strong className="text-danger">
                                                  *
                                                </strong>
                                                Indicación de Campo:
                                              </label>
                                              <input
                                                className="form-control"
                                                type="text"
                                                id={`form_sections[${index}].section_fields[${index2}].field_placeholder`}
                                                name={`form_sections[${index}].section_fields[${index2}].field_placeholder`}
                                                value={field?.field_placeholder}
                                                onChange={formik.handleChange}
                                                required
                                              />
                                            </div>
                                          </div>
                                        )}
                                      {field.field_type === "catalog" ? (
                                        <div className="col-4 col-md-4 col-sm-4">
                                          <div className="form-group">
                                            <label
                                              className="app-form-label"
                                              htmlFor="field_label"
                                            >
                                              <strong className="text-danger">
                                                *
                                              </strong>
                                              Tipo de Catálogo:
                                            </label>
                                            <SelectCatalogsComponent
                                              className="form-select"
                                              id={`form_sections[${index}].section_fields[${index2}].catalog_id`}
                                              name={`form_sections[${index}].section_fields[${index2}].catalog_id`}
                                              value={parseInt(field?.catalog_id)}
                                              onChange={formik.handleChange}
                                              required
                                            />
                                          </div>
                                        </div>
                                      ) : field?.field_type === "number" ? (
                                        <>
                                          <div className="col-4 col-md-4 col-sm-4">
                                            <div className="form-group">
                                              <label
                                                className="app-form-label"
                                                htmlFor="field_min"
                                              >
                                                <strong className="text-danger">
                                                  *
                                                </strong>
                                                Tamaño minimo:
                                              </label>
                                              <input
                                                className="form-control"
                                                type="number"
                                                id={`form_sections[${index}].section_fields[${index2}].field_min`}
                                                name={`form_sections[${index}].section_fields[${index2}].field_min`}
                                                value={field?.field_min}
                                                onChange={formik.handleChange}
                                                required
                                              />
                                            </div>
                                          </div>
                                          <div className="col-4 col-md-4 col-sm-4">
                                            <div className="form-group">
                                              <label
                                                className="app-form-label"
                                                htmlFor="field_max"
                                              >
                                                <strong className="text-danger">
                                                  *
                                                </strong>
                                                Tamaño máximo:
                                              </label>
                                              <input
                                                className="form-control"
                                                type="number"
                                                id={`form_sections[${index}].section_fields[${index2}].field_max`}
                                                name={`form_sections[${index}].section_fields[${index2}].field_max`}
                                                value={field?.field_max}
                                                onChange={formik.handleChange}
                                                required
                                              />
                                            </div>
                                          </div>
                                        </>
                                      ) : field?.field_type === "textarea" ? (
                                        <>
                                          <div className="col-4 col-md-4 col-sm-4">
                                            <div className="form-group">
                                              <label
                                                className="app-form-label"
                                                htmlFor="field_cols"
                                              >
                                                <strong className="text-danger">
                                                  *
                                                </strong>
                                                Número de Columnas:
                                              </label>
                                              <input
                                                type="number"
                                                className="form-control"
                                                id={`form_sections[${index}].section_fields[${index2}].field_cols`}
                                                name={`form_sections[${index}].section_fields[${index2}].field_cols`}
                                                placeholder="Ingrese el número de columnas"
                                                min={0}
                                                value={field?.field_cols}
                                                onChange={formik.handleChange}
                                                required
                                              />
                                            </div>
                                          </div>
                                          <div className="col-4 col-md-4 col-sm-4">
                                            <div className="form-group">
                                              <label
                                                className="app-form-label"
                                                htmlFor="field_rows"
                                              >
                                                <strong className="text-danger">
                                                  *
                                                </strong>
                                                Número de Renglones:
                                              </label>
                                              <input
                                                type="number"
                                                className="form-control"
                                                id={`form_sections[${index}].section_fields[${index2}].field_rows`}
                                                name={`form_sections[${index}].section_fields[${index2}].field_rows`}
                                                placeholder="Ingrese el número de renglones"
                                                min={0}
                                                value={field?.field_rows}
                                                onChange={formik.handleChange}
                                                required
                                              />
                                            </div>
                                          </div>
                                        </>
                                      ) : (
                                        <></>
                                      )}
                                      <div className="col-4 col-md-4 col-sm-4">
                                        <div className="form-group">
                                          <label
                                            className="app-form-label"
                                            htmlFor="field_class"
                                          >
                                            <strong className="text-danger">
                                              *
                                            </strong>
                                            Tamaño de Campo:
                                          </label>
                                          <SelectFieldClassComponent
                                            className="form-select"
                                            id={`form_sections[${index}].section_fields[${index2}].field_class`}
                                            name={`form_sections[${index}].section_fields[${index2}].field_class`}
                                            value={field?.field_class}
                                            onChange={formik.handleChange}
                                            required
                                          />
                                        </div>
                                      </div>
                                      <div className="col-4 col-md-4 col-sm-4">
                                        <div className="form-group">
                                          <label
                                            className="app-form-label"
                                            htmlFor="is_required"
                                          >
                                            <strong className="text-danger">
                                              *
                                            </strong>
                                            Requerido:
                                          </label>
                                          <select
                                            className="form-select"
                                            id={`form_sections[${index}].section_fields[${index2}].is_required`}
                                            name={`form_sections[${index}].section_fields[${index2}].is_required`}
                                            value={field?.is_required}
                                            onChange={formik.handleChange}
                                            required
                                          >
                                            <option value="">
                                              Seleccione una opción
                                            </option>
                                            <option value={true}>Si</option>
                                            <option value={false}>No</option>
                                          </select>
                                        </div>
                                      </div>
                                      <div className="col-4 col-md-4 col-sm-4">
                                        <div className="form-group">
                                          <label
                                            className="app-form-label"
                                            htmlFor="is_readonly"
                                          >
                                            <strong className="text-danger">
                                              *
                                            </strong>
                                            Editable:
                                          </label>
                                          <select
                                            className="form-select"
                                            id={`form_sections[${index}].section_fields[${index2}].is_readonly`}
                                            name={`form_sections[${index}].section_fields[${index2}].is_readonly`}
                                            value={field?.is_readonly}
                                            onChange={formik.handleChange}
                                            required
                                          >
                                            <option value="">
                                              Seleccione una opción
                                            </option>
                                            <option value={false}>Si</option>
                                            <option value={true}>No</option>
                                          </select>
                                        </div>
                                      </div>
                                      <div className="col-4 col-md-4 col-sm-4">
                                        <div className="form-group">
                                          <label
                                            className="app-form-label"
                                            htmlFor="is_active"
                                          >
                                            <strong className="text-danger">
                                              *
                                            </strong>
                                            Estatus:
                                          </label>
                                          <select
                                            className="form-select"
                                            id={`form_sections[${index}].section_fields[${index2}].is_active`}
                                            name={`form_sections[${index}].section_fields[${index2}].is_active`}
                                            value={field?.is_active}
                                            onChange={formik.handleChange}
                                            required
                                          >
                                            <option value="">
                                              Seleccione una opción
                                            </option>
                                            <option value={true}>Activo</option>
                                            <option value={false}>
                                              Inactivo
                                            </option>
                                          </select>
                                        </div>
                                      </div>
                                      {field?.field_type.match(regex_options) && (
                                        <div className="col-4 col-md-4 col-sm-4">
                                          {field?.field_options.map(
                                            (option, index3) => {
                                              return (
                                                <div
                                                  key={option.id}
                                                  className="app-forms-fields-options"
                                                >
                                                  <div className="col-5 col-md-5 col-sm-5">
                                                    <label
                                                      className="app-form-label"
                                                      htmlFor="name"
                                                    >
                                                      {option.id}) Nombre:
                                                      <strong className="text-danger">
                                                        *
                                                      </strong>
                                                    </label>
                                                    <input
                                                      type="text"
                                                      className="form-control"
                                                      id={`form_sections[${index}].section_fields[${index2}].field_options[${index3}].name`}
                                                      name={`form_sections[${index}].section_fields[${index2}].field_options[${index3}].name`}
                                                      value={option.name}
                                                      onChange={
                                                        formik.handleChange
                                                      }
                                                      required
                                                    />
                                                  </div>
                                                  <div className="col-5 col-md-5 col-sm-5">
                                                    <label
                                                      className="app-form-label"
                                                      htmlFor="value"
                                                    >
                                                      Valor:
                                                      <strong className="text-danger">
                                                        *
                                                      </strong>
                                                    </label>
                                                    <input
                                                      type="text"
                                                      className="form-control"
                                                      id={`form_sections[${index}].section_fields[${index2}].field_options[${index3}].value`}
                                                      name={`form_sections[${index}].section_fields[${index2}].field_options[${index3}].value`}
                                                      value={option.value}
                                                      onChange={
                                                        formik.handleChange
                                                      }
                                                      required
                                                    />
                                                  </div>
                                                  <div className="col-2 col-md-2 col-sm-2">
                                                    <button
                                                      type="button"
                                                      className="app-form-button-delete"
                                                      onClick={() =>
                                                        handleDeleteOption(
                                                          section.id,
                                                          field.id,
                                                          option.id
                                                        )
                                                      }
                                                    >
                                                      <span className="bi bi-trash"></span>
                                                    </button>
                                                  </div>
                                                </div>
                                              );
                                            }
                                          )}
                                          <div className="col-12 col-md-12 col-sm-12">
                                            <div className="app-forms-toolbar">
                                              <button
                                                type="button"
                                                className="app-forms-toolbar-button bg-japanese-laurel"
                                                onClick={() =>
                                                  handleAddFieldOption(
                                                    section.id,
                                                    field.id
                                                  )
                                                }
                                              >
                                                <span className="bi bi-plus">
                                                  Agregar opción
                                                </span>
                                              </button>
                                            </div>
                                          </div>
                                        </div>
                                      )}
                                      <div className="app-form-button">
                                        <button
                                          type="button"
                                          className="app-form-button-delete"
                                          onClick={() =>
                                            handleDeleteField(
                                              section.id,
                                              field.id
                                            )
                                          }
                                        >
                                          <span className="bi bi-trash">
                                            Eliminar Campo
                                          </span>
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                );
                              })}
                          </div>
                        )}
                      </div>
                      {pages.length > 0 && (
                        <div className="app-footer-actions mt-3">
                          <PaginationCounter
                            data={data}
                            rows={rows}
                            setRows={setRows}
                          />
                          <PaginationComponent
                            pages={pages}
                            current={current}
                            setCurrent={setCurrent}
                          />
                        </div>
                      )}
                      <div className="app-forms-toolbar">
                        <button
                          type="button"
                          className="app-forms-toolbar-button bg-japanese-laurel"
                          onClick={() => handleDeleteSection(section.id)}
                        >
                          <span className="bi bi-plus">Eliminar Seccion</span>
                        </button>
                        <button
                          type="button"
                          className="app-forms-toolbar-button bg-japanese-laurel"
                          onClick={() => handleAddField(section.id)}
                        >
                          <span className="bi bi-plus">Agregar Campo</span>
                        </button>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
          <div className="app-forms-toolbar">
            <button
              type="button"
              className="app-forms-toolbar-button"
              onClick={handleAddSection}
            >
              <span className="bi bi-plus">Agregar Sección</span>
            </button>
            <button type="submit" className="app-forms-toolbar-button">
              Guardar Formulario
            </button>
          </div>
        </div>
      </form>
      <div className="row d-flex">
        <div className="col-12 col-md-12 col-sm-12 ">
          <div className="app-forms-preview-title">
            <span>VISUALIZAR FORMULARIO:</span>
            <span className="bold">{formik.values.form_name}</span>
          </div>
        </div>
        <div className="col-12 col-md-12 col-sm-12">
          <div className="app-forms-preview-container">
            {formik.values.form_sections.map((section) => (
              <div key={section.id} className={section?.section_class}>
                <div className="app-forms-section-actions">
                  <div className={`app-forms-section-title`}>
                    {FormatTitle(section?.section_name)}
                  </div>
                  {section?.section_show === "true" && (
                    <div className="app-forms-section-tools">
                      <div className="tool">
                        <button type="button">
                          <span className="icon">
                            <i className="bi bi-printer"></i>
                          </span>
                          <span className="label">Imprimir</span>
                        </button>
                      </div>
                    </div>
                  )}
                </div>
                <div
                  className="row app-forms-section-fields"
                  style={{ display: section?.section_show ? "" : "none" }}
                >
                  {section?.section_fields.map((field) => (
                    <FieldsComponent key={field.id} {...field} />
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default EditFormsComponent;
