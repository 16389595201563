import React, { useContext, useEffect, useState } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import SwalError from "../../../utils/SwalError";
import { useNavigate } from "react-router";
import SwalSuccess from "../../../utils/SwalSuccess";
import SwalLoading from "../../../utils/SwalLoading";
import Swal from "sweetalert2";
import UsersFormComponent from "./UsersFormComponent";
import { regex_password, regex_username } from "../../../utils/Regex";
import { getProfiles } from "../../../../services/settings/profiles/ProfilesService";
import { postUser } from "../../../../services/settings/users/UsersService";
import SessionContext from "../../../context/SessionContext";

const CreateUsersComponent = (props) => {
  const navigate = useNavigate();
  const initialValues = {
    username: "",
    email: "",
    password: "",
    password_confirmation: "",
    profiles: [],
    is_active: true,
  };
  const { closeSession } = useContext(SessionContext);
  const [data, setData] = useState([]);
  const [isAgain, setIsAgain] = useState(false);

  useEffect(() => {

    SwalLoading({ title: "Consultando información" });
    getProfiles()
      .then((response) => {
        if (response?.status === 200) {
          const data = response.data;
          setData(data);

          const profiles = setupInitialValues(data);
          formik.setFieldValue("profiles", profiles);

          Swal.close();
        }
      })
      .catch((error) => {
        const response = error.response;
        if (response.status === 401) {
          closeSession();
        } else {
          SwalError({ title: "Error no Controlado", message: JSON.stringify(response.data.detail) })
        }
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: yup.object({
      username: yup
        .string("Ingrese su nombre")
        .matches(
          regex_username,
          "El nombre de usuario debe tener un tamaño de almenos 8 caracteres compuesto por minusculas y números, no debe comenzar con números"
        )
        .required("Debe ingresar su nombre."),
      email: yup
        .string("Ingrese su correo electrónico")
        .email("El correo electrónico no es valido")
        .required("El correo es requerido."),
      password: yup
        .string("La contraseña es requeridad")
        .matches(
          regex_password,
          "La contraseña debe tener al menos un tamaño de 12, debe tener por lo menos una mayúscula, un número, minúsculas."
        )
        .required("La contraseña es requeridad"),
      password_confirmation: yup
        .string("La contraseña es requeridad")
        .oneOf([yup.ref("password"), null], "Las contraseñas no coinciden")
        .required("La confirmación de contraseña es requeridad"),
      is_active: yup.boolean().default(() => true),
      profiles: yup.array(
        yup.object({
          profile_id: yup.number().required(),
          is_selected: yup.boolean().default(false),
          is_assigned: yup.boolean().default(false),
          is_active: yup.boolean().default(true)
        })).min(1).required()
    }),
    onSubmit: (values) => {

      const profiles = [...values.profiles]
        .filter((user_profile) => user_profile.is_assigned === true)
        .map(({ profile_id, is_active }) => ({ profile_id, is_active }));

      if (profiles.length === 0) {
        SwalError({ title: "Error", message: "El usuario debe tener al menos un role asignado." });
        return;
      }

      const data = { ...values, profiles };

      SwalLoading({ title: "Creando Usuario" });

      postUser(data)
        .then((response) => {
          if (response.status === 201) {
            SwalSuccess({
              title: "Creando Usuario",
              message: "El usuario ha sido creado exitosamente",
            });

            if (isAgain) {
              setIsAgain(!isAgain);
              handleReset();
            } else {
              navigate(-1);
            }
          }
        })
        .catch((error) => {
          const response = error.response;
          if (response.status === 401) {
            closeSession();
          } else if ([400, 404].includes(response.status)) {
            SwalError({ title: "Error", message: response.data.detail })
          } else {
            SwalError({ title: "Error no Controlado", message: JSON.stringify(response.data.detail) })
          }
        });
    },
  });

  const setupInitialValues = (array) => {
    return array.map((value) => {
      return {
        name: value.name,
        profile_id: value.id,
        is_active: true,
        is_assigned: false,
        is_selected: false,
      };
    });
  };

  const handleAgain = () => {
    setIsAgain(!isAgain);
  };

  const handleReset = () => {
    const profiles = setupInitialValues(data)
    formik.handleReset();
    formik.setValues({ ...initialValues, profiles: profiles });
  };

  const handleChange = (value) => formik.setFieldValue("profiles", value);

  return (
    <form onSubmit={formik.handleSubmit} noValidate>
      <div className="mb-4">
        <div className="row d-flex">
          <div className="col-6 col-md-6 col-sm-6">
            <label htmlFor="username" className="app-form-label">
              Nombre de Usuario:
            </label>
            <input
              type="text"
              id="username"
              name="username"
              className={`form-control ${formik.touched.username && formik.errors.username
                ? "is-invalid"
                : ""
                }`}
              placeholder="Ingrese su nombre completo"
              value={formik.values.username}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              required
            />
            {formik.touched.username && formik.errors.username && (
              <div className="d-flex invalid-feedback">
                {formik.errors.username}
              </div>
            )}
          </div>
          <div className="col-6 col-md-6 col-sm-6">
            <label htmlFor="email" className="app-form-label">
              Correo electrónico:
            </label>
            <input
              type="email"
              id="email"
              name="email"
              className={`form-control ${formik.touched.email && formik.errors.email ? "is-invalid" : ""
                }`}
              value={formik.values.email}
              placeholder="Ingrese su correo electronico"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              required
            />
            {formik.touched.email && formik.errors.email && (
              <div className="d-flex invalid-feedback">
                {formik.errors.email}
              </div>
            )}
          </div>
          <div className="col-4 col-md-4 col-sm-4">
            <label htmlFor="password" className="app-form-label">
              Contraseña:
            </label>
            <input
              type="password"
              id="password"
              name="password"
              className={`form-control ${formik.touched.password && formik.errors.password
                ? "is-invalid"
                : ""
                }`}
              placeholder="Ingrese la contraseña"
              value={formik.values.password}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              autoComplete="off"
              required
            />
            {formik.touched.password && formik.errors.password && (
              <div className="d-flex invalid-feedback">
                {formik.errors.password}
              </div>
            )}
          </div>
          <div className="col-4 col-md-4 col-sm-4">
            <label htmlFor="password_confirmation" className="app-form-label">
              Confirmar Contraseña:
            </label>
            <input
              type="password"
              id="password_confirmation"
              name="password_confirmation"
              className={`form-control ${formik.touched.password_confirmation &&
                formik.errors.password_confirmation
                ? "is-invalid"
                : ""
                }`}
              placeholder="Ingrese su contraseña nuevamente"
              value={formik.values.password_confirmation}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              autoComplete="off"
              required
            />
            {formik.touched.password_confirmation &&
              formik.errors.password_confirmation && (
                <div className="d-flex invalid-feedback">
                  {formik.errors.password_confirmation}
                </div>
              )}
          </div>
          <div className="col-4 col-md-4 col-sm-4">
            <label htmlFor="is_active" className="app-form-label">
              Estatus:
            </label>
            <select
              id="is_active"
              name="is_active"
              className={`form-select ${formik.touched.is_active && formik.errors.is_active
                ? "is-invalid"
                : ""
                }`}
              value={formik.values.is_active}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              required
            >
              <option value="">seleccione una opción</option>
              <option value={true}>Activo</option>
              <option value={false}>Inactivo</option>
            </select>
            {formik.touched.is_active && formik.errors.is_active && (
              <div className="d-flex invalid-feedback">
                {formik.errors.is_active}
              </div>
            )}
          </div>
        </div>
      </div>
      <div>
        <UsersFormComponent
          state={formik.values.profiles}
          setState={handleChange}
        />
      </div>
      <div className="app-protocols-footer">
        <div>
          <button type="sumbit" className="app-button bg-green-ryb">
            Guardar Registro
          </button>
        </div>
        <div>
          <button
            type="sumbit"
            className="app-button bg-maximun-blue-green"
            onClick={handleAgain}
          >
            Guardar y registrar otro usuario
          </button>
        </div>
        <div>
          <button
            type="reset"
            className="app-button default"
            onClick={() => navigate(-1)}
          >
            Cancelar
          </button>
        </div>
      </div>
    </form>
  );
};

export default CreateUsersComponent;
