import React, { useEffect } from "react";
import { Outlet, useNavigate } from "react-router";
import usePermission from "../../../hooks/usePermission";
import permissions from "../../../json/route_permission.json"
import "./Catalogs.css";

const Catalogs = (props) => {
  const navigate = useNavigate();
  const permitted = usePermission(permissions.settings.catalogs.index);

  useEffect(() => {

    const isPermitted = permitted !== null && permitted === false;

    if (isPermitted) {
      // Don't render the component if not permitted
      navigate("not-found");
    }
    // eslint-disable-next-line
  }, [permitted]);

  return permitted && (
    <Outlet />
  );
};

export default Catalogs;
