import React, { useContext, useEffect } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import SwalError from "../../../utils/SwalError";
import { useNavigate, useParams } from "react-router";
import SwalLoading from "../../../utils/SwalLoading";
import Swal from "sweetalert2";
import UsersFormComponent from "./UsersFormComponent";
import { regex_username } from "../../../utils/Regex";

import { getProfiles } from "../../../../services/settings/profiles/ProfilesService";
import { getUserById, putUserById } from "../../../../services/settings/users/UsersService";
import SwalSuccess from "../../../utils/SwalSuccess";
import SessionContext from "../../../context/SessionContext";

const EditUsersComponent = (props) => {
  const navigate = useNavigate();
  const { id: userId } = useParams();
  const { closeSession } = useContext(SessionContext);

  const initialValues = {
    username: "",
    email: "",
    password: "",
    password_confirmation: "",
    profiles: [],
    is_active: true,
  };


  useEffect(() => {
    SwalLoading({ title: "Consultando información" });
    getProfiles()
      .then((response) => {
        if (response?.status === 200) {
          const data = response.data;
          const profiles = setupInitialValues(data);
          getUserById(userId).then((response) => {
            if (response.status === 200) {
              const user = response.data;
              user.profiles = profiles.map((profile) => {

                const user_profile = user.profiles.find((user_profile) => user_profile.profile_id === profile.profile_id)

                if (user_profile) {
                  return { ...profile, ...user_profile, is_assigned: true };
                }

                profile.user_id = userId;
                return profile;
              });

              formik.setValues(user);
              Swal.close();
            }
          })
        }
      })
      .catch((error) => {
        const response = error.response;
        if (response.status === 401) {
          closeSession();
        }
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: yup.object({
      username: yup
        .string("Ingrese su nombre")
        .matches(
          regex_username,
          "El nombre de usuario debe tener un tamaño de almenos 8 caracteres compuesto por minusculas y números, no debe comenzar con números"
        )
        .required("Debe ingresar su nombre."),
      email: yup
        .string("Ingrese su correo electrónico")
        .email("El correo electrónico no es valido")
        .required("El correo es requerido."),
      is_active: yup.boolean().default(() => true),
      profiles: yup.array(
        yup.object({
          profile_id: yup.number().required(),
          user_id: yup.number().required(),
          is_selected: yup.boolean().default(false),
          is_assigned: yup.boolean().default(false),
          is_active: yup.boolean().default(true)
        })).min(1).required()
    }),
    onSubmit: (values) => {

      const profiles = [...values.profiles]
        .filter((user_profile) => user_profile.is_assigned === true)
        .map((item) => ({ user_id: item.user_id, profile_id: item.profile_id, is_active: item.is_active }));

      if (profiles.length === 0) {
        SwalError({ title: "Error", message: "El usuario debe tener al menos un role asignado." });
        return;
      }

      const data = { ...values, profiles };

      SwalLoading({ title: "Actualizando Usuario" });

      putUserById(userId, data).then((response) => {
        if (response.status === 200) {
          SwalSuccess({
            title: "Actualizando Usuario",
            message: "El usuario ha sido actualizado exitosamente",
          });
          navigate(-1);
        }
      }).catch((error) => {
        const response = error.response;
        if (response.status === 401) {
          closeSession();
        } else if ([400].includes(response.status)) {
          SwalError({ title: "Error", message: response.data.detail })
        } else {
          SwalError({ title: "Error no Controlado", message: JSON.stringify(response.data.detail) })
        }
      });
    },
  });

  const setupInitialValues = (array) => {
    return array.map((value) => {
      return {
        name: value.name,
        profile_id: value.id,
        user_id: null,
        is_active: true,
        is_assigned: false,
        is_selected: false,
      };
    });
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="mb-4">
        <div className="row d-flex">
          <div className="col-4 col-md-4 col-sm-4">
            <label htmlFor="username" className="app-form-label">
              Nombre de Usuario:
            </label>
            <input
              type="text"
              id="username"
              name="username"
              className={`form-control ${formik.touched.username && formik.errors.username
                ? "is-invalid"
                : ""
                }`}
              placeholder="Ingrese su nombre completo"
              value={formik.values.username}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              required
            />
            {formik.touched.username && formik.errors.username && (
              <div className="d-flex invalid-feedback">
                {formik.errors.username}
              </div>
            )}
          </div>
          <div className="col-4 col-md-4 col-sm-4">
            <label htmlFor="email" className="app-form-label">
              Correo electrónico:
            </label>
            <input
              type="email"
              id="email"
              name="email"
              className={`form-control ${formik.touched.email && formik.errors.email ? "is-invalid" : ""
                }`}
              placeholder="Ingrese su correo electronico"
              value={formik.values.email}
              readOnly
              disabled
            />
            {formik.touched.email && formik.errors.email && (
              <div className="d-flex invalid-feedback">
                {formik.errors.email}
              </div>
            )}
          </div>
          <div className="col-4 col-md-4 col-sm-4">
            <label htmlFor="is_active" className="app-form-label">
              Estatus:
            </label>
            <select
              id="is_active"
              name="is_active"
              className={`form-select ${formik.touched.is_active && formik.errors.is_active
                ? "is-invalid"
                : ""
                }`}
              value={formik.values.is_active}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              required
            >
              <option value="">seleccione una opción</option>
              <option value={true}>Activo</option>
              <option value={false}>Inactivo</option>
            </select>
            {formik.touched.is_active && formik.errors.is_active && (
              <div className="d-flex invalid-feedback">
                {formik.errors.is_active}
              </div>
            )}
          </div>
        </div>
      </div>
      <div>
        <UsersFormComponent
          state={formik.values.profiles}
          setState={(value) => formik.setFieldValue("profiles", value)}
        />
      </div>
      <div className="app-protocols-footer">
        <div>
          <button type="sumbit" className="app-button bg-green-ryb">
            Actualizar Registro
          </button>
        </div>
        <div>
          <button
            type="reset"
            className="app-button default"
            onClick={() => navigate(-1)}
          >
            Cancelar
          </button>
        </div>
      </div>
    </form>
  );
};

export default EditUsersComponent;
