import React, { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router";
import ProfilePhoto from "../../assets/images/home/profile.svg";
import SwalError from "../../utils/SwalError";
import { CalculateYears } from "../../utils/CalculateYears";
import { getPatientById } from "../../../services/patients/PatientsService";
import TranslateGender from "../../utils/TranslateGender";

const PatientCareInformationComponent = (props) => {
  const navigate = useNavigate();
  const { patientId } = useParams();
  const [patient, setPatient] = useState(null);

  useEffect(() => {

    getPatientById(patientId)
      .then((response) => {
        if (response.status === 200) {
          setPatient(response.data);
        }
      })
      .catch((error) => {
        const response = error.response;
        if (response.status === 401) {
          SwalError({
            title: "Error",
            message: response.data.detail,
          });
        }
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleHistorial = (patientId) => {
    navigate(`/historia-clinica/${patientId}/ver`);
  };

  const fullName = `${patient?.paternal_surname} ${patient?.maternal_surname} ${patient?.first_name} ${patient?.second_name}`;

  const age = useMemo(() => CalculateYears(patient?.birth_date), [patient?.birth_date]);

  const antiquity = useMemo(() => CalculateYears(patient?.hire_date), [patient?.hire_date]);

  return (
    patient && (
      <form className="d-flex">
        <div className="col-11 col-md-11 col-sm-11">
          <div className="row d-flex flex-wrap">
            <div className="col-md-4">
              <label htmlFor="full_name" className="form-label">
                Nombre Paciente:
              </label>
              <input
                type="text"
                className="form-control-plaintext"
                id="full_name"
                name="full_name"
                value={fullName}
                readOnly
              />
            </div>
            <div className="col-md-2">
              <label htmlFor="gender" className="form-label">
                Sexo:
              </label>
              <input
                type="text"
                className="form-control-plaintext"
                id="gender"
                name="gender"
                value={TranslateGender(patient?.gender)}
                readOnly
              />
            </div>
            <div className="col-md-2">
              <label htmlFor="age" className="form-label">
                Edad:
              </label>
              <input
                type="number"
                id="age"
                name="age"
                className="form-control-plaintext"
                value={age}
                readOnly
              />
            </div>
            <div className="col-md-4">
              <label htmlFor="birth_date" className="form-label">
                Fecha de Nacimiento:
              </label>
              <input
                type="date"
                className="form-control-plaintext"
                id="birth_date"
                name="birth_date"
                value={patient?.birth_date}
                readOnly
              />
            </div>
            <div className="col-md-4">
              <label htmlFor="hire_date" className="form-label">
                Fecha Ingreso:
              </label>
              <input
                type="date"
                className="form-control-plaintext"
                id="hire_date"
                name="hire_date"
                value={patient?.hire_date}
                readOnly
              />
            </div>
            <div className="col-md-4">
              <label htmlFor="antiquity" className="form-label">
                Antigüedad:
              </label>
              <input
                type="text"
                className="form-control-plaintext"
                id="antiquity"
                name="antiquity"
                value={antiquity}
                readOnly
              />
            </div>
            {patient?.patient_extra_fields?.map((field) => (
              <div key={field.id} className="col-md-4">
                <label htmlFor={field.extra_field.field_id} className="form-label">
                  {field.extra_field.field_label}
                </label>
                <input
                  type={field.extra_field.field_type}
                  className="form-control-plaintext text-ellipsis"
                  id={field.extra_field.field_id}
                  name={field.extra_field.field_name}
                  value={field.value === "" ? 'N/A' : field.value}
                  readOnly
                />
              </div>))
            }
          </div>
        </div>
        <div className="col-2 col-md-1 col-sm-2">
          <div className="app-patientcare-form-avatar">
            <div>
              <img
                src={patient?.photo ? patient?.photo : ProfilePhoto}
                alt="profile"
                width="192px"
                height="192px"
                className="avatar"
              />
            </div>
            <div>
              <button
                type="button"
                className="app-button-outline mt-4"
                onClick={() => handleHistorial(patientId)}
              >
                Ver Historia Clínica
              </button>
            </div>
          </div>
        </div>
      </form>
    )
  );
};

export default PatientCareInformationComponent;
