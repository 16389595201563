import DateFormatPersonalized from './DateFormatPersonalized';

const GenerateTimeIntervals = (start = "00:00:00", end = "24:00:00") => {
    const intervals = [];
    const starts = start.split(":");
    const ends = end.split(":");
    const startTime = new Date();
    const endTime = new Date();

    startTime.setHours(starts[0], starts[1], starts[2]);
    endTime.setHours(ends[0], ends[1], ends[2]);

    while (startTime < endTime) {
        intervals.push(DateFormatPersonalized(startTime, "HH:mm:ss"));
        startTime.setMinutes(startTime.getMinutes() + 30);
    }

    return intervals;
}

export default GenerateTimeIntervals;
