import React from "react";
import ToBoolean from "../../../../utils/ToBoolean";

const FieldTextarea = (props) => {

  return (
    <div
      className={`${props.field_class} form-group`}
      style={props.field_style?.container}
    >
      <label
        className="form-label"
        htmlFor={props?.field_id}
        style={props.field_style?.label}
      >
        {props?.field_label ? props?.field_label : ""}
      </label>
      <textarea
        id={props?.field_id}
        name={props?.field_name}
        className="form-control"
        placeholder={props?.field_placeholder}
        rows={props?.field_rows}
        cols={props?.field_cols}
        value={props?.default_value}
        readOnly={ToBoolean(props?.is_readonly)}
        autoComplete="off"
      ></textarea>
    </div>
  );
};

export default FieldTextarea;
