const Pagination = (data, rows) => {
  let count = 0;
  let page = [];
  let array = [];
  
  data.forEach((item, index) => {
    page.push(item);
    if ((index + 1) % rows === 0) {
      array.push({ id: count + 1, items: page });
      page = [];
      count++;
    }
  });

  if (page.length > 0) {
    array.push({ id: count + 1, items: page });
  }
  
  return array;
};

export default Pagination;
