import React, { useContext } from "react";
import Catalogs from "../../assets/images/settings/catalogs.svg";
import Protocols from "../../assets/images/settings/protocols.svg";
import Forms from "../../assets/images/settings/forms.svg";
import Reports from "../../assets/images/home/reportes.svg";
import Users from "../../assets/images/settings/users.svg";
import Profiles from "../../assets/images/settings/profiles.svg";
import SessionContext from "../../context/SessionContext";
import { NavLink } from "react-router-dom";

const SettingsComponent = () => {
  const { isPermitted } = useContext(SessionContext);

  return (
    <div>
      <h1 className="app-settings-title">
        Estas son las opciones de configuración que tienes, selecciona una
        opción
      </h1>

      <div className="app-options-container">
        {isPermitted(["select_catalog"]) &&
          <NavLink
            className="app-option nav-link bg-maximun-blue-green"
            to="catalogos"
            button="true"
          >
            <img src={Catalogs} alt="catálogos" width="143px" height="138px" />
            <span className="bold">Catálogos</span>
          </NavLink>
        }
        {isPermitted(["select_protocol"]) &&
          <NavLink
            className="app-option nav-link bg-green-ryb"
            to="protocolos"
            button="true"
          >
            <img
              src={Protocols}
              alt="protocolos de atención"
              width="143px"
              height="138px"
            />
            <span className="bold">
              Protocolos
              <br />
              de Atención
            </span>
          </NavLink>
        }
        {isPermitted(["select_form"]) &&
          <NavLink
            className="app-option nav-link bg-japanese-laurel"
            to="formularios"
            button="true"
          >
            <img src={Forms} alt="formularios" width="143px" height="138px" />
            <span className="bold">Formularios</span>
          </NavLink>
        }

        {process.env.NODE_ENV === "development" ? (
          <NavLink
            className="app-option nav-link bg-viridian-green"
            to="reportes"
            button="true"
          >
            <img src={Reports} alt="reportes" width="143px" height="138px" />
            <span className="bold">Reportes</span>
          </NavLink>
        ) : (
          <></>
        )}
        {isPermitted(["select_user", "select_users"]) &&
          <NavLink
            className="app-option nav-link bg-gray-asparagus"
            to="usuarios"
            button="true"
          >
            <img src={Users} alt="usuarios" width="143px" height="138px" />
            <span className="bold">Usuarios</span>
          </NavLink>
        }

        {isPermitted(["select_profile"]) &&
          <NavLink
            className="app-option nav-link bg-cadet-blue"
            to="perfiles"
            button="true"
          >
            <img src={Profiles} alt="Perfiles" width="143px" height="138px" />
            <span className="bold">Perfiles</span>
          </NavLink>
        }

        {isPermitted(["select_doctor"]) &&
          <NavLink
            className="app-option nav-link bg-spanish-gray"
            to="medicos"
            button="true"
          >
            <img src={Profiles} alt="Médicos" width="143px" height="138px" />
            <span className="bold">Médicos</span>
          </NavLink>
        }
      </div>
    </div>
  );
};

export default SettingsComponent;
