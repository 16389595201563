import React from "react";
import ToBoolean from "../../../../utils/ToBoolean";
import SelectCatalogComponent from "../../../selects/SelectCatalogComponent";

const FieldSelectCatalog = (props) => {


  return (
    <div
      className={`${props?.field_class} form-group`}

    >
      <label className="form-label" htmlFor={props?.field_id}>
        {props?.field_label ? props?.field_label : ""}:
      </label>
      <SelectCatalogComponent
        id={props?.field_id}
        name={props?.field_name}
        className="form-select"
        readOnly={ToBoolean(props?.is_readonly)}
        catalogId={props?.catalog_id}
        autoComplete="off"
      />
    </div>
  );
};

export default FieldSelectCatalog;
