import axiosService from "../../connection/AxiosService";

export async function getForms() {
  if (axiosService.checkToken()) {
    return await axiosService.client.get(`/forms`);
  }
}

export async function postForm(data = null) {
  if (axiosService.checkToken()) {
    return await axiosService.client.post(`/forms`, data);
  }
}

export async function getFormById(formId) {
  if (axiosService.checkToken()) {
    return await axiosService.client.get(`/forms/${formId}`);
  }
}

export async function putFormById(formId = null, data = null) {
  if (axiosService.checkToken()) {
    return await axiosService.client.put(`/forms/${formId}`, data);
  }
}
