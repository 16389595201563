import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { NavLink, useNavigate } from "react-router-dom";
import Pagination from "../../../utils/Pagination";
import PaginationCounter from "../../../utils/PaginationCounter";
import SortArrayById from "../../../utils/SortArrayById";
import SwalLoading from "../../../utils/SwalLoading";
import SwalSuccess from "../../../utils/SwalSuccess";
import PaginationComponent from "../../pagination/PaginationComponent";
import SwalError from "../../../utils/SwalError";
import { deleteProtocolById, getProtocols } from "../../../../services/settings/protocols/ProtocolsService";
import IsActiveIcon from "../../../utils/IsActiveIcon";

const ListProtocolsComponent = (props) => {
  const [data, setData] = useState([]);
  const [current, setCurrent] = useState(1);
  const [pages, setPages] = useState([]);
  const [rows, setRows] = useState(10);
  const [isReload, setIsReload] = useState(true);

  const navigate = useNavigate();

  useEffect(() => {
    if (isReload) {
      getProtocols()
        .then((response) => {
          if (response.status === 200) {
            setCurrent(1);
            setData(SortArrayById(response.data));
            setPages(Pagination(SortArrayById(response.data), rows));
            setIsReload(!isReload);
          }
        })
        .catch((error) => {
          const response = error.response;
          if (response.status === 401) {
            SwalError({
              title: "Error",
              message: response.data.detail,
            });
          }
        });
    }
    return () => { };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isReload]);

  useEffect(() => {
    setCurrent(1);
    setPages(Pagination(SortArrayById(data), rows));
    return () => { };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rows]);

  const handleCreate = () => {
    navigate(`crear`);
  };

  const handleGoTo = (value) => {
    navigate(`${value}`);
  };

  const handleBack = () => {
    navigate(-1);
  };

  const handleSearch = (event) => {
    const value = event.target.value.toLowerCase();
    const array = [...data];

    if (value !== "") {
      setCurrent(1);
      setPages(Pagination(filter(array, value), rows));
    } else {
      setCurrent(1);
      setPages(Pagination(data, rows));
    }
  };

  const filter = (array, value) => {
    return array.filter(
      (item) =>
        (item?.id === parseInt(value)) |
        item?.name?.toLowerCase().includes(value) |
        item?.especialidad?.toLowerCase().includes(value) |
        item?.medico?.toLowerCase().includes(value) |
        item?.sucursales?.toLowerCase().includes(value)
    );
  };

  const handleChangeStatus = (item) => {
    SwalLoading({
      title: `${item.is_active ? "Inactivando" : "Activando"} Protocolo`,
    });

    deleteProtocolById(item.id)
      .then((response) => {
        if (response.status === 200) {
          SwalSuccess({
            title: "Estatus de Protocolo",
            message: `El protocolo ha sido ${item.is_active ? "inactivado" : "activado"
              }`,
          });
          setIsReload(!isReload);
        }
      })
      .catch((error) => {
        const response = error.response;
        if (response.status === 401) {
          SwalError({
            title: "Error",
            message: response.data.detail,
          });
        }
      });
  };

  const form_names = (protocol) => {
    return protocol.protocol_forms?.map((form) => form.form.form_name).join(", ");
  };

  return (
    <div>
      <div className="app-container-actions">
        <div className="app-container-actions-label">
          <div className="app-form-search">
            <label className="app-form-label" htmlFor="search">
              Buscar
            </label>
            <input
              type="search"
              className="form-control"
              name="search"
              id="search"
              placeholder="Ingrese el nombre de protocolo"
              onChange={handleSearch}
            />
          </div>
        </div>
        <div className="app-container-actions-button">
          <button className="app-button" onClick={handleCreate}>
            Nuevo Protocolo de Atención
          </button>
          <button
            className="app-button bg-japanese-laurel"
            onClick={() => handleGoTo("/configuracion/formularios")}
          >
            Ir a Formularios
          </button>
        </div>
      </div>
      <div className="boxshadow white">
        <Table striped hover>
          <thead className="bg-green-ryb">
            <tr>
              <th>ID</th>
              <th>Tipo de Actividad</th>
              <th>Especialidad Medico</th>
              <th>Formularios</th>
              <th>Médico</th>
              <th>Sucursales</th>
              <th>Estatus</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {pages.length > 0 &&
              SortArrayById(
                pages.find((item) => item.id === current)?.items
              )?.map((protocolo, index) => (
                <tr key={index}>
                  <td>{protocolo.id}</td>
                  <td>{protocolo.name}</td>
                  <td>{protocolo.especialidad}</td>
                  <td>
                    {form_names(protocolo)}
                  </td>
                  <td>{protocolo.medico}</td>
                  <td>{protocolo.sucursales}</td>
                  <td>
                    {IsActiveIcon(protocolo.is_active)}
                  </td>
                  <td>
                    <button className="btn btn-outline-secondary detalle">
                      Detalles
                      <ul className="menu-button-detalle">

                        <li>
                          <NavLink
                            to={`${protocolo.id}/editar`}
                            className="nav-link"
                          >
                            Editar Protocolo
                          </NavLink>
                        </li>
                        <li onClick={() => handleChangeStatus(protocolo)}>
                          {`${protocolo.is_active ? "Inactivar" : "Activar"
                            } Protocolo`}
                        </li>
                      </ul>
                    </button>
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
      </div>
      <div className="app-footer-actions">
        <PaginationCounter data={data} rows={rows} setRows={setRows} />
        <PaginationComponent
          pages={pages}
          current={current}
          setCurrent={setCurrent}
        />
        <div className="app-table-button">
          <button
            className="app-button small bg-green-ryb"
            onClick={handleBack}
          >
            Regresar
          </button>
        </div>
      </div>
    </div>
  );
};

export default ListProtocolsComponent;


