import React, { useEffect, useState } from "react";
import ToCapitalize from "../../../utils/ToCapitalize";
import SwalLoading from "../../../utils/SwalLoading";
import SwalSuccess from "../../../utils/SwalSuccess";
import SwalError from "../../../utils/SwalError";
import { getModules, getProfileById, putProfileById } from "../../../../services/settings/profiles/ProfilesService";
import { useFormik } from "formik";
import { useNavigate, useParams } from "react-router";
import * as yup from "yup";


const EditProfileComponent = (props) => {
  const navigate = useNavigate();
  const { id: profileId } = useParams();
  const [modules, setModules] = useState([]);
  const [selected, setSelected] = useState(null);
  const initialValues = { name: "", permissions: [], is_active: true };

  useEffect(() => {

    getModules().then((response) => {
      if (response.status === 200) {

        const data = response.data;
        setSelected(data[0]?.id);
        setModules(data);

        getProfileById(profileId).then((response) => {
          if (response.status === 200) {
            const profile = response.data;
            formik.setValues(profile);
          }
        }).catch((error) => {
          const response = error.response;
          if (response.status === 401) {
            SwalError({
              title: "Error",
              message: response.data.detail,
            });
          }
        });
      }
    }).catch((error) => {
      const response = error.response;
      if (response.status === 401) {
        SwalError({
          title: "Error",
          message: response.data.detail,
        });
      }
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);




  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: yup.object({
      name: yup
        .string("Ingrese su nombre de perfil")
        .required("Debe ingresar su nombre de perfil."),
      permissions: yup.array(
        yup.object({
          profile_id: yup.number().nullable(),
          permission_id: yup.number().required(),
          is_active: yup.boolean().default(false),
        })),
      is_active: yup.boolean().default(() => true),
    }),
    onSubmit: (values) => {
      SwalLoading({ title: "Actualizando Perfil" });
      putProfileById(profileId, values).then((response) => {
        if (response.status === 200) {
          SwalSuccess({
            title: "Actualizando Perfil",
            message: "Perfil actualizado correctamente."
          });
          navigate(-1);
        }
      }).catch((error) => {
        const response = error.response;
        if (response.status === 400) {
          SwalError({ title: "Error", message: response?.data?.detail });
        } else {
          console.log(response);
          SwalError({ title: "Error", message: "Error no Controlador" });
        }
      })
    },
  });


  const handleAssigned = ({ target }, permissionId) => {
    const checked = target.checked;
    const permissions = [...formik.values.permissions];

    formik.setFieldValue("permissions", permissions.map((permission) => {

      if (permission.permission_id === parseInt(permissionId)) {
        permission.is_active = checked;
      }

      return permission;
    }));
  };

  const handleAssignedAll = ({ target }, submodule) => {
    const checked = target.checked;
    const permissions = [...formik.values.permissions];

    formik.setFieldValue("permissions", permissions.map((permission) => {

      submodule.permissions.forEach((item) => {
        if (item.id === permission.permission_id) {
          permission.is_active = checked;
        }
      });

      return permission;
    }));

  };

  const isChecked = (permissionId) => {
    const permissions = [...formik.values.permissions];
    let checked = false;
    permissions.forEach((permission) => {
      if (permission.permission_id === permissionId) {
        checked = permission.is_active
      }
    })
    return checked;
  };

  const isCheckedAll = (submodule) => {
    const ids = submodule.permissions.map(({ id }) => id);
    const permissions = [...formik.values.permissions].filter((permission) => ids.includes(permission.permission_id));
    return !permissions.some((permission) => permission.is_active === false)
  };


  return (
    <form onSubmit={formik.handleSubmit} noValidate>
      <div className="mb-4">
        <div className="row d-flex">
          <div className="col-6 col-md-6 col-sm-6">
            <label htmlFor="name" className="app-form-label">
              Nombre de Perfil:
            </label>
            <input
              type="text"
              id="name"
              name="name"
              className={`form-control ${formik.touched.name && formik.errors.name ? "is-invalid" : ""
                }`}
              placeholder="Ingrese su nombre de perfil"
              value={formik.values.name}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              required
            />
            {formik.touched.name && formik.errors.name && (
              <div className="d-flex invalid-feedback">
                {formik.errors.name}
              </div>
            )}
          </div>
          <div className="col-6 col-md-6 col-sm-6">
            <label htmlFor="is_active" className="app-form-label">
              Estatus:
            </label>
            <select
              id="is_active"
              name="is_active"
              className={`form-select ${formik.touched.is_active && formik.errors.is_active
                ? "is-invalid"
                : ""
                }`}
              value={formik.values.is_active}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              required
            >
              <option value="">seleccione una opción</option>
              <option value={true}>Activo</option>
              <option value={false}>Inactivo</option>
            </select>
            {formik.touched.is_active && formik.errors.is_active && (
              <div className="d-flex invalid-feedback">
                {formik.errors.is_active}
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="app-permissions-container">
        <div className="app-permissions-options">
          {modules.map((module) => (
            <div key={module.id} className="app-permissions-option">
              <button
                type="button"
                className={`${selected === module.id && "selected"}`}
                onClick={() => setSelected(module.id)}
              >
                {ToCapitalize(module?.name)}
              </button>
            </div>
          ))}
        </div>
        <div className="app-permissions-table">
          {modules
            .find((module) => module.id === selected)
            ?.submodules.map((submodule) => (
              <div key={submodule.id} className="row d-flex">
                <div className="col d-flex">
                  <input
                    className="form-check-input m-1"
                    type="checkbox"
                    id={submodule.name}
                    name={submodule.name}
                    value={submodule.name}
                    checked={isCheckedAll(submodule)}
                    onChange={(e) => handleAssignedAll(e, submodule)}
                  />
                  <label
                    className="form-check-label text-nowrap"
                    htmlFor="inlineCheckbox1"
                  >
                    {submodule.name}
                  </label>
                </div>
                <div className="col d-flex flex-start align-items-center">
                  {submodule.permissions.map((permission, index) => (
                    <div key={index} className="form-check form-check-inline">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id={permission.permission}
                        name={permission.permission}
                        onChange={(e) => handleAssigned(e, permission.id)}
                        checked={isChecked(permission.id)}
                      />
                      <label
                        className="form-check-label text-nowrap"
                        htmlFor={permission.permission}
                      >
                        {ToCapitalize(permission.name)}
                      </label>
                    </div>
                  ))}
                </div>
              </div>
            ))}
        </div>
      </div>
      <div className="app-protocols-footer">
        <div>
          <button type="sumbit" className="app-button bg-green-ryb">
            Actualizar Registro
          </button>
        </div>
        <div>
          <button
            type="reset"
            className="app-button default"
            onClick={() => navigate(-1)}
          >
            Cancelar
          </button>
        </div>
      </div>
    </form>
  );
};

export default EditProfileComponent;
