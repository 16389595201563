import React, { useContext, useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { NavLink, useNavigate } from "react-router-dom";
import Pagination from "../../utils/Pagination";
import PaginationCounter from "../../utils/PaginationCounter";
import SwalError from "../../utils/SwalError";
import SwalLoading from "../../utils/SwalLoading";
import PaginationComponent from "../pagination/PaginationComponent";
import SelectProtocolsComponent from "../selects/SelectProtocolsComponent";
import Swal from "sweetalert2";
import { deletePatientById, getPatients } from "../../../services/patients/PatientsService";
import TranslateGender from "../../utils/TranslateGender";
import SessionContext from "../../context/SessionContext";
import IsActiveIcon from "../../utils/IsActiveIcon";
import permissions from "../../json/route_permission.json";

const ListClinicHistoryComponent = (props) => {
  const [data, setData] = useState([]);
  const [current, setCurrent] = useState(1);
  const [pages, setPages] = useState([]);
  const [rows, setRows] = useState(4);
  const [isReload, setIsReload] = useState(true);
  const navigate = useNavigate();
  const { isPermitted , closeSession } = useContext(SessionContext);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (isReload) {
        SwalLoading({ title: "Consultando pacientes" });
        getPatients()
          .then(async (response) => {
            if (response.status === 200) {
              setCurrent(1);
              setRows(4);
              setData(response.data);
              setIsReload(!isReload);
              await setPages(Pagination(response.data, rows));
              Swal.close();
            }
          })
          .catch((error) => {
            const response = error.response;
            if (response.status === 401) {
              SwalError({
                title: "Error",
                message: response.data.detail,
              });
            } else {
              SwalError({
                title: "Error",
                message: "Error al consultar los pacientes",
              });
            }
          });
      }
    });
    return () => {
      clearTimeout(timeout);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isReload]);

  useEffect(() => {
    setCurrent(1);
    setPages(Pagination(data, rows));
    return () => { };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rows]);

  const handleBack = () => {
    navigate(-1);
  };

  const handleSearch = (event) => {
    const value = event.target.value.toLowerCase();
    const array = [...data];

    if (value !== "") {
      setCurrent(1);
      setPages(Pagination(filter(array, value), rows));
    } else {
      setCurrent(1);
      setPages(Pagination(data, rows));
    }
  };

  const filter = (array, value) => {
    return array.filter(
      (item) =>
        toString(item.id).toLowerCase().includes(value) ||
        [item.last_name, item.second_last_name, item.first_name]
          .join(" ")
          .toLowerCase()
          .match(value)
    );
  };

  const handleInactive = async (patientId) => {
    SwalLoading({ title: "Actualizando Paciente" });
    deletePatientById(patientId)
      .then((response) => {
        if (response.status === 200) {
          setIsReload(!isReload);
          Swal.close();
        }
      })
      .catch((error) => {
        const response = error.response;
        if (response.status === 401) {
          closeSession();
        } else if ([404].includes(response.status)) {
          SwalError({
            title: "Error",
            message: response.data.detail,
          });
        } else {
          SwalError({
            title: "Error no Controlado",
            message: JSON.stringify(response.data.detail),
          });
        }
      });
  };

  const fullName = (patient) => `${patient.paternal_surname} ${patient.maternal_surname} ${patient.first_name} ${patient.second_name}`;

  return (
    <div>
      <div className="app-container-actions gap-1">
        <div className="col-4 col-md-4 col-sm-4">
          <label className="app-form-label" htmlFor="search">
            Buscar
          </label>
          <input
            type="search"
            className="form-control"
            name="search"
            id="search"
            placeholder="Buscar paciente"
            onChange={handleSearch}
          />
        </div>
        {process.env.NODE_ENV === "development" && (
          <React.Fragment>
            <div className="col-3 col-md-3 col-sm-3 ">
              <label className="app-form-label" htmlFor="protocol_id">
                Tipo de Actividad:
              </label>
              <SelectProtocolsComponent
                id="protocol_id"
                name="protocol_id"
                className="form-select"
                required
              />
            </div>
            <div className="col-3 col-md-3">
              <label className="app-form-label" htmlFor="date">
                Fechas:
              </label>
              <input
                type="date"
                className="form-control"
                name="date"
                id="date"
                placeholder="Buscar paciente"
                onChange={handleSearch}
              />
            </div>
          </React.Fragment>
        )}
      </div>
      <div className="boxshadow white">
        <Table striped hover className="round">
          <thead className="bg-green-ryb">
            <tr>
              <th>ID</th>
              <th>Nombre</th>
              <th>Número de ID</th>
              <th>Fecha de Nacimiento</th>
              <th>Sexo</th>
              <th>Estatus</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {pages
              .find((item) => item.id === current)
              ?.items.map((patient, index) => {
                return (
                  <tr key={index}>
                    <td>{patient.id}</td>
                    <td>{fullName(patient)}</td>
                    <td>{patient?.number_id}</td>
                    <td>{patient?.birth_date}</td>
                    <td>{TranslateGender(patient?.gender)}</td>
                    <td>{IsActiveIcon(patient.is_active)}</td>
                    <td>
                      <button className="btn btn-outline-secondary btn-sm detalle">
                        Detalles
                        <ul className="menu-button-detalle">
                          <li>
                            <NavLink
                              className="nav-link"
                              to={`${patient?.id}/ver`}
                            >
                              Ver Paciente
                            </NavLink>
                          </li>
                          <li>
                            { isPermitted(permissions.medical_care.patients.edit) &&
                            <NavLink
                              className="nav-link"
                              to={`${patient?.id}/editar`}
                            >
                              Editar Paciente
                            </NavLink>
              }
                          </li>
                          {/*<li>Agendar Cita</li>*/}
                          <li onClick={() => handleInactive(patient?.id)}>
                            {`${patient?.is_active ? "Desactivar" : "Activar"} Paciente`}
                          </li>
                        </ul>
                      </button>
                    </td>
                  </tr>
                );


              })}
          </tbody>
        </Table>
      </div>
      <div className="app-footer-actions">
        <PaginationCounter data={data} rows={rows} setRows={setRows} />
        <PaginationComponent
          pages={pages}
          current={current}
          setCurrent={setCurrent}
        />
        <div className="app-table-button">
          <button
            className="app-button small bg-green-ryb"
            onClick={handleBack}
          >
            Regresar
          </button>
        </div>
      </div>
    </div>
  );
};

export default ListClinicHistoryComponent;
