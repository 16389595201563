import React, { useMemo } from 'react'
import SelectProtocolsComponent from '../selects/SelectProtocolsComponent'
import TranslateGender from '../../utils/TranslateGender'
import DateFormatPersonalized from '../../utils/DateFormatPersonalized'
import { CalculateYears } from '../../utils/CalculateYears'
import AlertIcon from "../../assets/icons/alert-grey.svg";
import SelectSpecialitiesComponent from '../selects/SelectSpecialitiesComponent'
import SelectCompaniesComponent from '../selects/SelectCompaniesComponent'
import SelectBranchesComponent from '../selects/SelectBranchesComponent'

const DateStepOneComponent = ({ patient, values, handleChange, handleSubmit, handleReset }) => {

    const fullName = useMemo(() => `${patient?.paternal_surname} ${patient?.maternal_surname} ${patient?.first_name} ${patient?.second_name}`, [patient])

    return (
        <form className='app-dates-container' onSubmit={handleSubmit}>
            <h1 className='app-dates-title mb-5'>Paso 1 - Asignación de Citas</h1>
            <div className='mb-5'>
                <fieldset>
                    <legend>Paciente</legend>
                    <div className="d-flex flex-row">
                        <div className='col-md-4'>
                            <div className='row ps-3'>
                                <label htmlFor='full_name' className='col-sm-2 col-form-label fw-bold fs-6 me-2'>Nombre:</label>
                                <div className="col-sm-9">
                                    <input className='form-control-plaintext text-secondary fw-semibold fs-6' id="full_name" name="full_name" value={fullName} />
                                </div>
                            </div>
                        </div>
                        <div className='col-md-4'>
                            <div className='row ps-3'>
                                <label htmlFor='number_id' className='col-sm-4 col-form-label fw-bold fs-6 text-nowrap me-3'>No. Identificación:</label>
                                <div className="col-sm-7">
                                    <input className='form-control-plaintext text-secondary fw-semibold fs-6' id="number_id" name="number_id" value={patient?.number_id} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="d-flex flex-row">
                        <div className='col-md-4'>
                            <div className='row ps-3'>
                                <label htmlFor='gender' className='col-sm-2 col-form-label fw-bold fs-6'>Sexo:</label>
                                <div className="col-sm-10">
                                    <input className='form-control-plaintext text-secondary fw-semibold fs-6' id="gender" name="gender" value={TranslateGender(patient?.gender)} />
                                </div>
                            </div>
                        </div>
                        <div className='col-md-4'>
                            <div className='row ps-3'>
                                <label htmlFor='birth_date' className='col-sm-5 col-form-label fw-bold fs-6 text-nowrap me-2'>Fecha de Nacimiento:</label>
                                <div className="col-sm-6">
                                    <input className='form-control-plaintext text-secondary fw-semibold fs-6' id="birth_date" name="birth_date" value={DateFormatPersonalized(patient?.birth_date, "DD/MM/YYYY")} />
                                </div>
                            </div>
                        </div>
                        <div className='col-md-4'>
                            <div className='row ps-3'>
                                <label htmlFor='age' className='col-sm-2 col-form-label fw-bold fs-6 text-nowrap'>Edad:</label>
                                <div className="col-sm-10">
                                    <input className='form-control-plaintext text-secondary fw-semibold fs-6' id="age" name="age" value={`${CalculateYears(patient?.birth_date)} años`} />
                                </div>
                            </div>
                        </div>
                    </div>
                </fieldset>
            </div>
            <div className='mb-5'>
                <fieldset>
                    <legend>Datos del Profesional</legend>
                    <div className="d-flex flex-row justify-content-start">
                        <div className='col-md-4 p-3'>
                            <label
                                className='form-label text-secondary text-left fw-bold fs-6'
                                htmlFor='full_name'
                            >
                                Nombre:
                            </label>
                            <input
                                className='form-control fw-semibold fs-6'
                                type='text'
                                id="full_name"
                                name="full_name"
                                placeholder='Escriba el nombre del profesional'
                                value={values.full_name}
                                onChange={handleChange}
                            />
                        </div>
                        <div className='col-md-4 p-3'>
                            <label
                                className='form-label text-secondary text-left fw-bold fs-6'
                                htmlFor='professional_id'
                            >
                                Código Profesional:
                            </label>
                            <input
                                className='form-control fw-semibold fs-6'
                                type='search'
                                id="professional_id"
                                name="professional_id"
                                value={values.professional_id}
                                onChange={handleChange}
                            />
                        </div>
                        <div className='col-md-4 p-3'>
                            <label
                                className='form-label text-secondary text-left fw-bold fs-6'
                                htmlFor='company_id'
                            >
                                Empresa:
                            </label>
                            <SelectCompaniesComponent
                                className='form-select text-secondary fw-semibold fs-6'
                                id="company_id"
                                name="company_id"
                                value={values.company_id}
                                onChange={handleChange}
                            />
                        </div>
                    </div>
                    <div className="d-flex flex-row justify-content-start">

                        <div className='col-md-4 p-3'>
                            <label
                                className='form-label text-secondary text-left fw-bold fs-6'
                                htmlFor='branch_id'
                            >
                                Centro de Atención:
                            </label>
                            <SelectBranchesComponent
                                className='form-select text-secondary fw-semibold fs-6'
                                id="branch_id"
                                name="branch_id"
                                value={values.branch_id}
                                companyId={values.company_id}
                                onChange={handleChange}
                            />
                        </div>
                        <div className='col-md-4 p-3'>
                            <label
                                className='form-label text-secondary text-left fw-bold fs-6'
                                htmlFor='speciality_id'
                            >
                                Especialidad:
                            </label>
                            <SelectSpecialitiesComponent
                                className='form-select text-secondary fw-semibold fs-6'
                                id="speciality_id"
                                name="speciality_id"
                                value={values.speciality_id}
                                branchId={values.branch_id}
                                onChange={handleChange}
                            />
                        </div>
                        <div className='col-md-4 p-3'>
                            <label
                                className='form-label text-secondary text-left fw-bold fs-6'
                                htmlFor='protocol_id'
                            >
                                Tipo de Actividad:
                            </label>
                            <SelectProtocolsComponent
                                className='form-select text-secondary fw-semibold fs-6'
                                id="protocol_id"
                                name="protocol_id"
                                value={values.protocol_id}
                                onChange={handleChange}
                            />
                        </div>
                    </div>
                </fieldset>
            </div>
            <div className='mb-5'>
                <fieldset>
                    <legend>Fecha y Hora</legend>
                    <div className="d-flex flex-row">
                        <div className='col-md-4 p-3'>
                            <label
                                className='form-label text-secondary text-left fw-bold fs-6'
                                htmlFor='appointment_date'
                            >
                                Fecha de Cita:
                            </label>
                            <input
                                className='form-control fw-semibold fs-6'
                                type='date'
                                id="appointment_date"
                                name="appointment_date"
                                value={values.appointment_date}
                                onChange={handleChange}
                            />
                        </div>
                        <div className='col-md-4 p-3'>
                            <label
                                className='form-label text-secondary text-left fw-bold fs-6'
                                htmlFor='dates_quantiy'
                            >
                                No. Citas:
                            </label>
                            <input
                                className='form-control fw-semibold fs-6'
                                type='number'
                                id="date_quantiy"
                                name="date_quantiy"
                                placeholder='Escriba su número de citas'
                                value={values.date_quantity}
                                onChange={handleChange}
                            />
                        </div>
                        <div className='col-md-4 p-3'>
                            <div className="d-flex flex-column align-items-center justify-content-center align-content-center form-check form-switch">
                                <label class="form-check-label fw-semibold text-secondary" for="is_scheduled">
                                    <span className='d-flex justify-content-center align-items-center gap-2'>
                                        <span className=''>
                                            <img src={AlertIcon} alt="alert" width="32px" height="32px" />
                                        </span>
                                        <span className='fs-5'>Cita No Programada</span>
                                    </span>
                                </label>
                                <input
                                    class="form-check-input fs-4 ms-2"
                                    type="checkbox"
                                    id="is_scheduled"
                                    name="is_scheduled"
                                    value={values.is_scheduled}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>
                    </div>
                </fieldset>
            </div>
            <div className="d-flex flex-row justify-content-center gap-2 mb-3">
                <div className='col-auto'>
                    <button type="submit" className="btn btn-success fs-5">Buscar</button>
                </div>
                <div className='col-auto'>
                    <button type='reset' onClick={handleReset} className="btn btn-outline-success fs-5">Cancelar</button>
                </div>
            </div>
        </form>
    )
}

export default DateStepOneComponent
