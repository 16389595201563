import React, { useContext } from "react";
import MainIcon from "../../assets/icons/main.svg";
import MedicalCareIcon from "../../assets/icons/bag.svg";
import BookIcon from "../../assets/icons/book.svg";
import ReportIcon from "../../assets/icons/report.svg";
import SettingIcon from "../../assets/icons/setting.svg";
import PowerIcon from "../../assets/icons/power-dark.svg";
import { NavLink, useNavigate } from "react-router-dom";
import SwalSuccess from "../../utils/SwalSuccess";
import Swal from "sweetalert2";
import SessionContext from "../../context/SessionContext";
import permissions from "../../json/route_permission.json";
import "./Navbar.css";


const Navbar = ({ show, handleToggle }) => {
  const navigate = useNavigate();
  const { isPermitted } = useContext(SessionContext);

  const handleCloseSesion = async () => {
    await Swal.fire({
      title: "ATENCIÓN",
      html: "¿Desea Cerrar Sesión?",
      showCancelButton: true,
      confirmButtonText: "Aceptar",
      cancelButtonText: "Cancelar",
      showCloseButton: true,
      allowEscapeKey: false,
      closeButtonHtml: `<div class="swal2-header">
                            <div class="swal2-header-title">Cerrar Sesión</div>
                            <div class="swal2-header-close">
                              <button type="button" aria-label="Close this dialog">x</button>
                            </div>
                        </div>`,
    }).then(async (result) => {
      if (result.isConfirmed) {
        sessionStorage.clear();
        SwalSuccess({
          title: "Cerrando Sesión",
          message: "La sesión ha sido cerrada exitosamente.",
        });
        navigate("/login");
      }
    });
  };

  return (
    <div className={`app-navbar-menu ${show && "active"}`}>
      <div className="app-navbar-menu-shadow">
        <ul>
          <NavLink to="/" className="nav-link" onClick={handleToggle}>
            <li>
              <span className="icon">
                <img src={MainIcon} alt="main" width="auto" height="auto" />
              </span>
              <span className="label">Mi Tablero</span>
            </li>
          </NavLink>
          {isPermitted(permissions.medical_care.index) &&
            <NavLink
              to="/atencion-medica"
              className="nav-link"
              onClick={handleToggle}
            >
              <li>
                <span className="icon">
                  <img src={MedicalCareIcon} alt="" width="auto" height="auto" />
                </span>
                <span className="label">Atención Médica</span>
              </li>
            </NavLink>
          }
          {isPermitted(permissions.clinic_history.index) &&
            <NavLink
              to="/historia-clinica"
              className="nav-link"
              onClick={handleToggle}
            >
              <li>
                <span className="icon">
                  <img src={BookIcon} alt="" width="22px" height="23px" />
                </span>
                <span className="label">Historia Clínica</span>
              </li>
            </NavLink>
          }
          {process.env.NODE_ENV === "development" && (
            <NavLink to="/reportes" className="nav-link" onClick={handleToggle}>
              <li>
                <span className="icon">
                  <img src={ReportIcon} alt="" width="auto" height="auto" />
                </span>
                <span className="label">Reportes</span>
              </li>
            </NavLink>
          )}
          {isPermitted(permissions.settings.index) &&
            <NavLink
              to="/configuracion"
              className="nav-link"
              onClick={handleToggle}
            >
              <li>
                <span className="icon">
                  <img src={SettingIcon} alt="" width="auto" height="auto" />
                </span>
                <span className="label">Configuración</span>
              </li>
            </NavLink>
          }
          <li onClick={handleCloseSesion}>
            <span className="icon">
              <img src={PowerIcon} alt="" width="auto" height="auto" />
            </span>
            <span className="label">Cerrar Sesión</span>
          </li>
          <span>ConsultaExterna©2022</span>
        </ul>
      </div>
    </div>
  );
};

export default Navbar;
