import React, { useEffect, useState } from "react";
import SwalError from "../../utils/SwalError";
import { getUsers } from "../../../services/settings/users/UsersService";

const SelectUsersComponent = (props) => {
  const { id, name, value, onChange, onBlur, className, required } = props;
  const [users, setUsers] = useState([]);

  useEffect(() => {
    getUsers()
      .then((response) => {
        if (response.status === 200) {
          setUsers(response.data);
        }
      })
      .catch((error) => {
        const response = error.response;
        if (response.status === 401) {
          SwalError({
            title: "Error",
            message: response.data.detail,
          });
        }
      });

  }, []);

  return (
    <select
      id={id}
      name={name}
      className={className}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      required={required}
    >
      <option value="0">seleccione una opción</option>
      {users?.map((item) => (
        <option key={item.id} value={item.id}>
          {item.username} - {item.email}
        </option>
      ))}
    </select>
  );
};

export default SelectUsersComponent;
