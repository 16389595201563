import React, { useContext, useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { NavLink, useNavigate } from "react-router-dom";
import PaginationComponent from "../../pagination/PaginationComponent";
import Pagination from "../../../utils/Pagination";
import PaginationCounter from "../../../utils/PaginationCounter";
import SortArrayById from "../../../utils/SortArrayById";
import SwalError from "../../../utils/SwalError";
import { getDoctors } from "../../../../services/settings/doctors/DoctorsService";
import SessionContext from "../../../context/SessionContext";

const ListDoctorsComponent = (props) => {
  const [data, setData] = useState([]);
  const [current, setCurrent] = useState(1);
  const [pages, setPages] = useState([]);
  const [rows, setRows] = useState(10);
  const [isReload, setIsReload] = useState(true);
  const navigate = useNavigate();
  const { isPermitted } = useContext(SessionContext);

  useEffect(() => {
    if (isReload) {
      getDoctors()
        .then((response) => {
          if (response.status === 200) {
            setCurrent(1);
            setData(SortArrayById(response.data));
            setPages(Pagination(SortArrayById(response.data), rows));
            setIsReload(!isReload);
          }
        })
        .catch((error) => {
          const response = error.response;
          if (response.status === 401) {
            SwalError({
              title: "Error",
              message: response.data.detail,
            });
          }
        });
    }
    return () => { };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isReload]);

  useEffect(() => {
    setCurrent(1);
    setPages(Pagination(data, rows));
    return () => { };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rows]);

  const handleCreate = () => {
    navigate(`crear`);
  };

  const handleGoTo = (value) => {
    navigate(`${value}`);
  };

  const handleBack = () => {
    navigate(-1);
  };

  const handleStatus = (user) => {
    // const data = { is_active: !user.is_active };
    // SwalLoading({
    //   title: `${user.is_active ? "Inactivando" : "Activando"} Usuario`,
    // });
    // putUserById(user.id, data)
    //   .then((response) => {
    //     if (response.status === 200) {
    //       SwalSuccess({
    //         title: `${user.is_active ? "Inactivando" : "Activando"} Usuario`,
    //         message: `El usuario ha sido ${
    //           user.is_active ? "Inactivado" : "Activado"
    //         } correctamente`,
    //       });
    //       setIsReload(!isReload);
    //     }
    //   })
    //   .catch((error) => {
    //     const response = error.response;
    //     if (response.status === 401) {
    //       SwalError({
    //         title: "Error",
    //         message: response.data.detail,
    //       });
    //     }
    //   });
  };

  const handleSearch = (event) => {
    const value = event.target.value.toLowerCase();
    const array = [...data];

    if (value !== "") {
      setCurrent(1);
      setPages(Pagination(filter(array, value), rows));
    } else {
      setCurrent(1);
      setPages(Pagination(data, rows));
    }
  };

  const filter = (array, value) => {
    return array.filter(
      (item) =>
        (item?.id === parseInt(value)) |
        item?.full_name?.toLowerCase().includes(value) |
        item?.id_number?.toLowerCase().includes(value) |
        item?.email?.toLowerCase().includes(value)
    );
  };


  return (
    <div>
      <div className="app-container-actions">
        <div className="app-container-actions-label">
          <div className="app-form-search">
            <label className="app-form-label" htmlFor="search">
              Buscar
            </label>
            <input
              type="search"
              className="form-control"
              name="search"
              id="search"
              placeholder="Buscar doctor"
              onChange={handleSearch}
            />
          </div>
        </div>
        <div className="app-container-actions-button">

          {isPermitted(["create_doctor"]) &&
            <button className="app-button" onClick={handleCreate}>
              Nuevo Medico
            </button>
          }
          {isPermitted(["select_user"]) &&
            <button
              className="app-button bg-japanese-laurel"
              onClick={() => handleGoTo("/configuracion/usuarios")}
            >
              Ir a Usuarios
            </button>
          }

        </div>
      </div>
      <div className="boxshadow white">
        <Table striped hover className="round">
          <thead className="bg-green-ryb">
            <tr>
              <th>ID</th>
              <th>Nombre Completo</th>
              <th>Cedula</th>
              <th>Correo</th>
              <th>Estatus</th>
              {isPermitted(["update_doctor", "delete_doctor"]) && <th></th>}
            </tr>
          </thead>
          <tbody>
            {pages
              .find((item) => item.id === current)
              ?.items.map((doctor, index) => (
                <tr key={index}>
                  <td>{doctor.id}</td>
                  <td>{doctor.full_name}</td>
                  <td>{doctor.professional_id}</td>
                  <td>{doctor.user.email}</td>
                  <td>
                    {doctor.is_active ? (
                      <i className="bi bi-check-circle-fill text-green-ryb"></i>
                    ) : (
                      <i className="bi bi-x-circle-fill text-danger"></i>
                    )}
                  </td>
                  {isPermitted(["update_doctor", "delete_doctor"]) &&
                    <td>
                      <button className="btn btn-outline-secondary detalle">
                        Detalles
                        <ul className="menu-button-detalle">
                          {isPermitted(["update_doctor"]) &&
                            <li>
                              <NavLink
                                to={`${doctor.id}/editar`}
                                className="nav-link"
                              >
                                Editar Medico
                              </NavLink>
                            </li>
                          }
                          {isPermitted(["delete_doctor"]) &&
                            <li onClick={() => handleStatus(doctor)}>
                              {`${doctor.status ? "Inactivar" : "Activar"} Medico`}
                            </li>
                          }
                        </ul>
                      </button>
                    </td>
                  }
                </tr>
              ))}
          </tbody>
        </Table>
      </div>
      <div className="app-footer-actions">
        <PaginationCounter data={data} rows={rows} setRows={setRows} />
        <PaginationComponent
          pages={pages}
          current={current}
          setCurrent={setCurrent}
        />
        <div className="app-table-button">
          <button
            className="app-button small bg-green-ryb"
            onClick={handleBack}
          >
            Regresar
          </button>
        </div>
      </div>
    </div>
  );
};

export default ListDoctorsComponent;
