import React from "react";

const SelectSectionClassComponent = (props) => {
  const { id, name, className, onChange, value, required, readOnly } = props;
  return (
    <select
      id={id}
      name={name}
      className={className}
      value={value}
      onChange={onChange}
      required={required}
      readOnly={readOnly}
    >
      <option value="">Selecciona una opción</option>
      <option value="app-forms-container">default</option>
      <option value="app-forms-container box">box</option>
      <option value="app-forms-container box-lg">boxlong</option>
      <option value="app-forms-container boxgray">boxgray</option>
      <option value="app-forms-container boxshadow">boxshadow</option>
    </select>
  );
};

export default SelectSectionClassComponent;
