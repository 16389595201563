import React from "react";
import "./NotFound.css";

const NotFound = () => {
  return <div className="not-found">
      <span>
        Error 404 <br/>
        Page Not Found
      </span>
  </div>;
};

export default NotFound;
