import React, { useContext } from "react";
import { useNavigate } from "react-router";
import PatientCareComponent from "../../components/patientcare/PatientCareComponent";
import DateFormat from "../../utils/DateFormat";
import SessionContext from "../../context/SessionContext";
import "./PatientCare.css";

const PatientCare = () => {
  const navigate = useNavigate();
  const {session} = useContext(SessionContext);

  return (
    <>
      <div className="app-container-header">
        <div className="left">
          <span onClick={() => navigate(-1)}>
            <span>
              <i className="fa-solid fa-arrow-left fs-auto"></i>
            </span>
            <span className="bold">Atención Paciente</span>
          </span>
          <span>{DateFormat()}</span>
        </div>
        <div className="right">
          <span className="bold">{session?.username}</span>
          <span>Médico General</span>
        </div>
      </div>
      <div className="app-patientcare-container">
        <PatientCareComponent />
      </div>
    </>
  );
};

export default PatientCare;
