import React, { useContext, useEffect, useState, useMemo } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import Swal from "sweetalert2";
import SwalLoading from "../../utils/SwalLoading";
import SwalError from "../../utils/SwalError";
import CameraComponent from "../camera/CameraComponent";
import UserPhoto from "../../assets/images/home/profile.svg";
import { useNavigate, useParams } from "react-router";
import { CalculateYears } from "../../utils/CalculateYears";
import SessionContext from "../../context/SessionContext";
import { getPatientById, putPatientById } from "../../../services/patients/PatientsService";

const ClinicHistoryEditPatientComponent = (props) => {
  const [showCapture, setShowCapture] = useState(false);
  const navigate = useNavigate();
  const { id: patientId } = useParams();
  const { closeSession } = useContext(SessionContext);

  useEffect(() => {
    getPatientById(patientId).then((response) => {
      if (response.status === 200) {
        const data = response.data;
        formik.setValues(data);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const formik = useFormik({
    initialValues: {
      photo: "",
      number_id: "",
      first_name: "",
      second_name: "",
      paternal_surname: "",
      maternal_surname: "",
      birth_date: "",
      gender: "male",
      hire_date: "",
      patient_extra_fields: [],
      is_active: true,
    },
    validationSchema: yup.object({
      photo: yup.string().matches(/^data:image\/png;base64,[\w\W=]+$/gm),
      number_id: yup
        .string("Escriba su número de documento de identidad")
        .required("Debe Escribir su número de documento de identidad."),
      first_name: yup.string("Escriba su nombre").required("Debe escribir su primer nombre."),
      second_name: yup.string("Escriba su segundo nombre"),
      paternal_surname: yup.string("Escriba su apellido paterno").required("Debe ingresar su apellido paterno."),
      maternal_surname: yup
        .string("Escriba su apellido materno")
        .required("Debe escribir su apellido materno."),
      birth_date: yup
        .date("Ingrese su fecha de nacimiento")
        .required("Debe ingresar su fecha de nacimiento."),
      hire_date: yup.date().nullable(),
      patient_extra_fields: yup.array(
        yup.object({
          extra_field_id: yup.number().required(),
          value: yup.string(),
          is_active: yup.bool().required()
        })),
      gender: yup
        .string("Seleccione una opción")
        .required("Debe seleccionar al menos una opción"),
      is_active: yup.bool().default(() => true),
    }),
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: (values) => {
      SwalLoading({ title: "Actualizando Paciente" });
      putPatientById(patientId, values)
        .then((response) => {
          if (response.status === 200) {
            navigate(-1);
            Swal.close();
          }
        })
        .catch((error) => {
          const response = error.response;
          if (response.status === 401) {
            closeSession()
          } else if ([400, 404].includes(response.status)) {
            SwalError({
              title: "Error",
              message: response.data.detail,
            });
          } else {
            SwalError({
              title: "Error No Controlado",
              message: JSON.stringify(response.data.detail),
            });
          }
        });
    },
  });

  const handlePhoto = (value) => {
    formik.setFieldValue("photo", value);
  };

  const antiquity = useMemo(() => CalculateYears(formik.values.hire_date), [formik.values.hire_date]);
  const age = useMemo(() => CalculateYears(formik.values.birth_date), [formik.values.birth_date]);


  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="boxshadow white row">
        <div className="d-flex">
          <div className="col-12 col-md-9">
            <div className="row">
              <div className="col-12 col-md-4">
                <label htmlFor="number_id" className="form-label fw-bold fs-5 text-secondary">
                  Número de ID:
                </label>
                <input
                  type="text"
                  className={`form-control ${formik.touched.paternal_surname && formik.errors.paternal_surname
                    ? "is-invalid"
                    : ""
                    }`}
                  id="number_id"
                  name="number_id"
                  value={formik.values.number_id}
                  onChange={formik.handleChange}
                  required
                />
                <div className="invalid-feedback">
                  {formik.touched.number_id && <span>{formik.errors.number_id}</span>}
                </div>
              </div>
              <div className="col-12 col-md-4">
                <label className="form-label fw-bold text-secondary fs-5" htmlFor="paternal_surname">
                  * Apellido Paterno:
                </label>
                <input
                  type="text"
                  id="paternal_surname"
                  name="paternal_surname"
                  className={`form-control ${formik.touched.paternal_surname && formik.errors.paternal_surname
                    ? "is-invalid"
                    : ""
                    }`}
                  placeholder="Escriba su apellido paterno"
                  value={formik.values.paternal_surname}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  required
                />
                <div className="invalid-feedback">
                  {formik.touched.paternal_surname && <span>{formik.errors.paternal_surname}</span>}
                </div>
              </div>
              <div className="col-12 col-md-4">
                <label className="form-label fw-bold text-secondary fs-5" htmlFor="maternal_surname">
                  * Apellido Materno:
                </label>
                <input
                  type="text"
                  id="maternal_surname"
                  name="maternal_surname"
                  className={`form-control ${formik.touched.maternal_surname && formik.errors.maternal_surname
                    ? "is-invalid"
                    : ""
                    }`}
                  placeholder="Escriba su apellido materno"
                  value={formik.values.maternal_surname}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  required
                />
                <div className="invalid-feedback">
                  {formik.touched.maternal_surname && (
                    <span>{formik.errors.maternal_surname}</span>
                  )}
                </div>
              </div>
              <div className="col-12 col-md-4">
                <label className="form-label fw-bold text-secondary fs-5" htmlFor="first_name">
                  * Primer Nombre:
                </label>
                <input
                  type="text"
                  id="first_name"
                  name="first_name"
                  className={`form-control ${formik.touched.first_name && formik.errors.first_name
                    ? "is-invalid"
                    : ""
                    }`}
                  placeholder="Escriba su primer nombre"
                  value={formik.values.first_name}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  required
                />
                <div className="invalid-feedback">
                  {formik.touched.first_name && <span>{formik.errors.first_name}</span>}
                </div>
              </div>
              <div className="col-12 col-md-4">
                <label className="form-label fw-bold text-secondary fs-5" htmlFor="second_name">
                  Segundo Nombre:
                </label>
                <input
                  type="text"
                  id="second_name"
                  name="second_name"
                  className="form-control"
                  placeholder="Escriba su segundo nombre"
                  value={formik.values.second_name}
                  onChange={formik.handleChange}
                />
              </div>
              <div className="col-12 col-md-4">
                <label className="form-label fw-bold text-secondary fs-5" htmlFor="birth_date">
                  * F. Nacimiento:
                </label>
                <input
                  type="date"
                  id="birth_date"
                  name="birth_date"
                  className={`form-control ${formik.touched.birth_date && formik.errors.birth_date
                    ? "is-invalid"
                    : ""
                    }`}
                  value={formik.values.birth_date}
                  onChange={formik.handleChange}
                  required
                />
                <div className="invalid-feedback">
                  {formik.touched.birth_date && (
                    <span>{formik.errors.birth_date}</span>
                  )}
                </div>
              </div>
              <div className="col-12 col-md-3">
                <label className="form-label fw-bold text-secondary fs-5" htmlFor="age">
                  Edad:
                </label>
                <input
                  type="text"
                  id="age"
                  name="age"
                  className="form-control"
                  placeholder="Edad"
                  value={age}
                  readOnly
                  disabled
                />
              </div>
              <div className="col-12 col-md-3">
                <label className="form-label fw-bold text-secondary fs-5" htmlFor="sexo">
                  * Sexo:
                </label>
                <select
                  id="gender"
                  name="gender"
                  className={`form-select ${formik.touched.gender && formik.errors.gender
                    ? "is-invalid"
                    : ""
                    }`}
                  value={formik.values.gender}
                  onChange={formik.handleChange}
                  required={true}
                >
                  <option value="">seleccione una opción</option>
                  <option value="male">Hombre</option>
                  <option value="female">Mujer</option>
                  <option value="other">Otro</option>
                </select>
              </div>
              <div className="col-12 col-md-3">
                <label className="form-label fw-bold text-secondary fs-5" htmlFor="hire_date">
                  Fecha Ingreso:
                </label>
                <input
                  type="date"
                  id="hire_date"
                  name="hire_date"
                  className="form-control"
                  value={formik.values.hire_date}
                  onChange={formik.handleChange}
                />
              </div>
              <div className="col-12 col-md-3">
                <label className="form-label fw-bold text-secondary fs-5" htmlFor="antiquity">
                  Antigüedad:
                </label>
                <input
                  type="text"
                  id="antiquity"
                  name="antiquity"
                  className="form-control"
                  placeholder="Escriba su Antigüedad"
                  value={antiquity}
                  readOnly
                  disabled
                />
              </div>
              {formik.values.patient_extra_fields.map((field, index) => (
                <div key={index} className="col-12 col-md-4">
                  <label className="form-label fw-bold text-secondary fs-5" htmlFor={`patient_extra_fields[${index}].value`}>
                    {field.extra_field.field_label}:
                  </label>
                  <input
                    type={field.extra_field.field_type}
                    id={`patient_extra_fields[${index}].value`}
                    name={`patient_extra_fields[${index}].value`}
                    className="form-control"
                    placeholder={field.extra_field.field_placeholder}
                    value={field.value}
                    onChange={formik.handleChange}
                  />
                </div>
              ))}
              <div className="col-12 col-md-12">
                <div className="form-check form-switch mt-2 float-right">
                  <input
                    className="form-check-input fs-5"
                    type="checkbox"
                    role="switch"
                    id="is_active"
                    name="is_active"
                    checked={formik.values.is_active}
                    value={formik.values.is_active}
                    onChange={formik.handleChange} />
                  <label className="form-check-label fw-bold text-secondary fs-5" htmlFor="is_active">Activo</label>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-3">
            <div className="app-clinichistory-photo">
              <div className="avatar">
                <img
                  src={!formik.values.photo ? UserPhoto : formik.values.photo}
                  alt="User Profile"
                  width="192px"
                  height="192px"
                />
                <CameraComponent
                  show={showCapture}
                  setShow={setShowCapture}
                  image={formik.values.photo}
                  setImage={handlePhoto}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="d-flex gap-2 float-right">
        <button type="reset" className="btn btn-outline-danger" onClick={() => navigate(-1)}>
          Cancelar
        </button>
        <button type="submit" className="btn btn-success">
          Actualizar Paciente
        </button>
      </div>
    </form >
  );
};

export default ClinicHistoryEditPatientComponent;
