import React, { useContext } from "react";
import DateFormat from "../../utils/DateFormat";
import { useNavigate } from "react-router";
import SessionContext from "../../context/SessionContext";
import ListClinicHistoryComponent from "../../components/clinichistory/ListClinicHistoryComponent";

const ListClinicHistory = () => {
  const navigate = useNavigate();
  const {session} = useContext(SessionContext);

  return (
    <div>
      <div className="app-container-header">
        <div className="left">
          <span onClick={() => navigate(-1)}>
            <span>
              <i className="fa-solid fa-arrow-left fs-auto"></i>
            </span>
            <span className="bold">Historia Clínica</span>
          </span>
          <span>{DateFormat()}</span>
        </div>
        <div className="right">
          <span className="bold">{session?.username}</span>
          <span>Médico General</span>
        </div>
      </div>
      <div className="app-clinichistory">
        <ListClinicHistoryComponent />
      </div>
    </div>
  );
};

export default ListClinicHistory;
