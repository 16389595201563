import React, { useContext } from "react";
import "./Reports.css";
import DateFormat from "../../utils/DateFormat";
import { useNavigate } from "react-router";
import ReportesComponent from "../../components/reports/ReportesComponent";
import SessionContext from "../../context/SessionContext";

const Reports = () => {
  const navigate = useNavigate();
  const {session} = useContext(SessionContext);

  return (
    <div>
      <div className="app-container-header">
        <div className="left">
          <span onClick={() => navigate(-1)}>
            <span>
              <i className="fa-solid fa-arrow-left fs-auto"></i>
            </span>
            <span className="bold">Reportes</span>
          </span>
          <span>{DateFormat()}</span>
        </div>
        <div className="right">
          <span className="bold">{session?.username}</span>
          <span>Médico General</span>
        </div>
      </div>
      <div className="app-reports">
        <ReportesComponent />
      </div>
    </div>
  );
};

export default Reports;
