import React, { useContext, useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { NavLink, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import Pagination from "../../../utils/Pagination";
import PaginationCounter from "../../../utils/PaginationCounter";
import SwalLoading from "../../../utils/SwalLoading";
import PaginationComponent from "../../pagination/PaginationComponent";
import SortArrayById from "../../../utils/SortArrayById";
import { toInteger } from "lodash";
import SessionContext from "../../../context/SessionContext";
import { getCatalogs } from "../../../../services/settings/catalogs/CatalogsService";

const ListCatalogsComponent = (props) => {
  const [data, setData] = useState([]);
  const [rows, setRows] = useState(10);
  const [current, setCurrent] = useState(1);
  const [pages, setPages] = useState([]);
  const { isPermitted, closeSession } = useContext(SessionContext);
  const navigate = useNavigate();

  useEffect(() => {
    SwalLoading({ title: "Consultando Catálogos" });
    getCatalogs()
      .then((response) => {
        if (response.status === 200) {
          const data = SortArrayById(response.data);
          setCurrent(1);
          setData(data);
          setPages(Pagination(data, rows));
          Swal.close();
        }
      })
      .catch((error) => {
        const response = error.response;
        if (response.status === 401) {
          closeSession();
        }
      });

    return () => { };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setCurrent(1);
    setPages(Pagination(data, rows));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rows]);

  const handleBack = () => {
    navigate(-1);
  };

  const handleCreate = () => {
    navigate("crear");
  };

  const handleSearch = (event) => {
    const value = event.target.value.toLowerCase();
    const array = [...data];

    if (value !== "") {
      setCurrent(1);
      setPages(Pagination(filter(array, value), rows));
    } else {
      setCurrent(1);
      setPages(Pagination(data, rows));
    }
  };

  const filter = (array, value) => {
    return array.filter(
      (item) =>
        (item?.id === toInteger(value)) |
        item?.name?.toLowerCase().includes(value)
    );
  };

  return (
    <div>
      <div className="app-container-actions">
        <div className="app-container-actions-label">
          <div className="app-form-search">
            <label className="app-form-label" htmlFor="search">
              Buscar
            </label>
            <input
              type="search"
              className="form-control"
              name="search"
              id="search"
              placeholder="Ingrese el nombre de formulario"
              onChange={handleSearch}
            />
          </div>
        </div>
        <div className="app-container-actions-button">
          {isPermitted(["create_catalog"]) &&
            <button className="app-button" onClick={handleCreate}>
              Crear Nuevo Catálogo
            </button>
          }

        </div>
      </div>
      <div className="app-container-table">
        <h1 className="app-table-title">Catálogos</h1>
        <Table striped hover>
          <thead>
            <tr>
              <th>#</th>
              <th>Nombre</th>
              <th>Acciones</th>
            </tr>
          </thead>
          <tbody>
            {pages
              .find((item) => item.id === current)
              ?.items.map((catalogo, index) => (
                <tr key={index}>
                  <td>{catalogo.id}</td>
                  <td>{catalogo.name}</td>
                  <td>
                    <NavLink to={`${catalogo.id}/ver`} className="nav-link">
                      <span>
                        <i className="fa-solid fa-eye"></i>
                      </span>
                      <span>Ver Catálogo</span>
                    </NavLink>
                    {isPermitted(["update_catalog"]) &&
                      <NavLink
                        to={`${catalogo.id}/editar`}
                        className="nav-link"
                      >
                        <span>
                          <i className="fa-solid fa-pencil"></i>
                        </span>
                        <span>Editar Catálogo</span>
                      </NavLink>
                    }
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
      </div>
      <div className="app-footer-actions">
        <PaginationCounter data={data} rows={rows} setRows={setRows} />
        <PaginationComponent
          pages={pages}
          current={current}
          setCurrent={setCurrent}
        />
        <div className="app-table-button">
          <button
            className="app-button small bg-green-ryb"
            onClick={handleBack}
          >
            Regresar
          </button>
        </div>
      </div>
    </div>
  );
};

export default ListCatalogsComponent;
