import React, { useEffect, useState } from "react";
import { getPatientById } from "../../../services/patients/PatientsService";
import { useNavigate, useParams } from "react-router";
import DateStepOneComponent from "./DateStepOneComponent";
import { useFormik } from "formik";
import * as yup from "yup";
import DateStepTwoComponent from "./DateStepTwoComponent";

const CreateDateComponent = (props) => {
    const { id: patientId } = useParams();
    const [patient, setPatient] = useState(null);
    // const [doctor, setDoctor] = useState(null);
    const [isStepOne, setIsStepOne] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        getPatientById(patientId)
            .then((response) => {
                if (response.status === 200) {
                    setPatient(response.data);
                }
            })
            .catch((error) => {
                console.log(error);
            });

    }, [patientId]);

    const formik = useFormik({
        initialValues: {
            patient_id: patientId,
            full_name: "",
            professional_id: "",
            speciality_id: 0,
            company_id: 0,
            branch_id: 0,
            protocol_id: 0,
            apointment_date: "",
            date_quantiy: 0,
            is_scheduled: false,
        },
        validationSchema: yup.object({
            patient_id: yup.number(),
            full_name: yup.string(),
            professional_id: yup.string(),
            speciality_id: yup.number(),
            company_id: yup.number(),
            branch_id: yup.number(),
            protocol_id: yup.number(),
            appointment_date: yup.string(),
            date_quantiy: yup.number(),
            is_scheduled: yup.boolean()
        }),
        onSubmit: (values) => {
            setIsStepOne(!isStepOne);
        },
        onReset: () => {
            navigate(-1);
        },
    });

    return isStepOne ? (
        <DateStepOneComponent
            patient={patient}
            values={formik.values}
            handleChange={formik.handleChange}
            handleSubmit={formik.handleSubmit}
            handleReset={formik.handleReset}
        />
    ) : (
        <DateStepTwoComponent
            patient={patient}
            values={formik.values}
            handleChange={formik.handleChange}
            handleSubmit={formik.handleSubmit}
            handleReset={formik.handleReset}
        />
    );
};

export default CreateDateComponent;
