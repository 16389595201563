import React from "react";
import ToBoolean from "../../../../utils/ToBoolean";

const FieldInputRadio = (props) => {
  return (
    <div
      className={`${props?.field_class} form-group-${props?.field_type}`}

    >
      <div>
        <label className="form-label" htmlFor={`${props?.field_id}`}>
          {props?.field_label}
        </label>
      </div>
      <div className="form-group">
        {props?.field_options?.map((option, index) => (
          <div key={index}>
            <label
              className="form-check-label"
              htmlFor={`${props?.field_id}${option.id}`}
            >
              {option.name}
            </label>
            <input
              type={`${props?.field_type}`}
              id={`${props?.field_id}${option.id}`}
              name={props?.field_name}
              className="form-check-input"
              value={option.value}
              readOnly={ToBoolean(props?.is_readonly)}
              autoComplete="off"
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default FieldInputRadio;
