import React from "react";
import ToBoolean from "../../../../utils/ToBoolean";

const FieldSelect = (props) => {
  return (
    <div
      className={`${props?.field_class} form-group`}

    >
      <label className="form-label" htmlFor={props?.field_id}>
        {props?.field_label ? props?.field_label : ""}
      </label>
      <select
        id={props?.field_id}
        name={props?.field_name}
        className="form-select"
        defaultValue={props?.default_value}
        readOnly={ToBoolean(props?.is_readonly)}
        autoComplete="off"
      >
        <option value="0">seleccione una opción</option>
        {props?.options &&
          props?.options?.map((option, index) => (
            <option key={index} value={option?.id}>
              {option?.name}
            </option>
          ))}
      </select>
    </div>
  );
};

export default FieldSelect;
