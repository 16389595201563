import React, { useContext, useEffect } from "react";
import { useFormik } from "formik";
import { useNavigate, useParams } from "react-router";
import SwalError from "../../../utils/SwalError";
import SwalSuccess from "../../../utils/SwalSuccess";
import SwalLoading from "../../../utils/SwalLoading";
import DoctorsScheduleComponent from "./DoctorsScheduleComponent";
import SelectCompaniesComponent from "../../selects/SelectCompaniesComponent";
import SelectBranchesComponent from "../../selects/SelectBranchesComponent";
import SelectUsersComponent from "../../selects/SelectUsersComponent";
import * as yup from "yup";
import SessionContext from "../../../context/SessionContext";
import { getDoctorById, putDoctorById } from "../../../../services/settings/doctors/DoctorsService";
import { time_regex } from "../../../utils/Regex";
import SelectSpecialitiesComponent from "../../selects/SelectSpecialitiesComponent";

const EditDoctorsComponent = (props) => {
  const navigate = useNavigate();
  const { id: doctorId } = useParams();
  const { closeSession } = useContext(SessionContext);

  useEffect(() => {
    getDoctorById(doctorId).then((response) => {
      if (response.status === 200) {
        formik.setValues(response.data);
      }
    }).catch((error) => {
      const response = error.response;
      if (response.status === 401) {
        closeSession();
      } else if ([400, 404].includes(response.status)) {
        SwalError({ title: "Error", message: response.data.detail });
      } else {
        SwalError({ title: "Error no Controlado", message: JSON.stringify(response.data.detail) });
      }
    })
    // eslint-disable-next-line
  }, [])

  const initialValues = {
    full_name: "",
    professional_id: "",
    user_id: 0,
    company_id: 0,
    branch_id: 0,
    speciality_id: 0,
    schedules: [
      { week_day: 0, start_time: "09:00", end_time: "19:00", is_active: true },
      { week_day: 1, start_time: "09:00", end_time: "19:00", is_active: true },
      { week_day: 2, start_time: "09:00", end_time: "19:00", is_active: true },
      { week_day: 3, start_time: "09:00", end_time: "19:00", is_active: true },
      { week_day: 4, start_time: "09:00", end_time: "19:00", is_active: true },
      { week_day: 5, start_time: "09:00", end_time: "19:00", is_active: true },
      { week_day: 6, start_time: "09:00", end_time: "19:00", is_active: true },
    ],
    is_active: true,
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: yup.object({
      full_name: yup
        .string("Escriba su nombre completo")
        .required("Debe escribir su nombre completo"),
      professional_id: yup
        .string()
        .matches(
          /^[1-9][0-9]{6,10}$/g,
          "El número de cedula ingresado no corresponde."
        )
        .required("El número de cedula es requerido."),
      company_id: yup.number().min(1).required("Debe selecciónar una empresa"),
      branch_id: yup.number().min(1).required("Debe selecciónar una sucursal"),
      speciality_id: yup.number().min(1).required("Debe selecciónar una sucursal"),
      user_id: yup
        .number("Seleccione un usuario")
        .required("Debe seleccionar un usuario."),
      schedules: yup.array(
        yup.object({
          week_day: yup.number().min(0).max(6).required(),
          start_time: yup.string().matches(time_regex, "El formato deberia ser (HH:MM:SS)").required(),
          end_time: yup.string().matches(time_regex, "El formato deberia ser (HH:MM:SS)").required(),
          is_active: yup.boolean().default(() => true)
        })),
      is_active: yup.boolean().default(() => true),
    }),
    onSubmit: (values) => {
      SwalLoading({ title: "Creando Médico" });
      putDoctorById(doctorId, values)
        .then((response) => {
          if (response.status === 200) {
            SwalSuccess({
              title: "Médico Creado",
              message: "El médico ha sido creado exitosamente.",
            });


            navigate(-1);

          }
        })
        .catch((error) => {
          const response = error.response;
          if (response.status === 401) {
            closeSession();
          } else if ([400, 404].includes(response.status)) {
            SwalError({
              title: "Error",
              message: response.data.detail
            })
          } else {
            SwalError({
              title: "Error no Controlado",
              message: JSON.stringify(response.data.detail)
            })
          }
        });
    },
  });




  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="mb-4">
        <div className="row d-flex">
          <div className="col-4 col-md-4 col-sm-4">
            <label htmlFor="full_name" className="app-form-label">
              Nombre de Completo:
            </label>
            <input
              type="text"
              id="full_name"
              name="full_name"
              className={`form-control ${formik.touched.full_name && formik.errors.full_name
                ? "is-invalid"
                : ""
                }`}
              placeholder="Ingrese su nombre completo"
              value={formik.values.full_name}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              required
            />
            {formik.touched.full_name && formik.errors.full_name && (
              <div className="d-flex invalid-feedback">
                {formik.errors.full_name}
              </div>
            )}
          </div>
          <div className="col-4 col-md-4 col-sm-4">
            <label htmlFor="professional_id" className="app-form-label">
              Cedula:
            </label>
            <input
              type="text"
              id="professional_id"
              name="professional_id"
              className={`form-control ${formik.touched.professional_id && formik.errors.professional_id
                ? "is-invalid"
                : ""
                }`}
              value={formik.values.professional_id}
              placeholder="Ingrese su cedula"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              required
            />
            {formik.touched.professional_id && formik.errors.professional_id && (
              <div className="d-flex invalid-feedback">
                {formik.errors.professional_id}
              </div>
            )}
          </div>
          <div className="col-4 col-md-4 col-sm-4">
            <label htmlFor="user_id" className="app-form-label">
              Usuario:
            </label>
            <SelectUsersComponent
              id="user_id"
              name="user_id"
              className={`form-select ${formik.touched.user_id && formik.errors.user_id
                ? "is-invalid"
                : ""
                }`}
              value={formik.values.user_id}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              required={true}
            />
            {formik.touched.email && formik.errors.user_id && (
              <div className="d-flex invalid-feedback">
                {formik.errors.user_id}
              </div>
            )}
          </div>
          <div className="col-3 col-md-3 col-sm-3">
            <label htmlFor="company_id" className="app-form-label">
              Empresa:
            </label>
            <SelectCompaniesComponent
              id="company_id"
              name="company_id"
              className={`form-select ${formik.touched.company_id && formik.errors.company_id
                ? "is-invalid"
                : ""
                }`}
              value={formik.values.company_id}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              required={true}
            />

            {formik.touched.company_id && formik.errors.company_id && (
              <div className="d-flex invalid-feedback">
                {formik.errors.company_id}
              </div>
            )}
          </div>
          <div className="col-3 col-md-3 col-sm-3">
            <label htmlFor="branch_id" className="app-form-label">
              Sucursales:
            </label>
            <SelectBranchesComponent
              id="branch_id"
              name="branch_id"
              className={`form-select ${formik.touched.branch_id && formik.errors.branch_id
                ? "is-invalid"
                : ""
                }`}
              value={formik.values.branch_id}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              companyId={formik.values.company_id}
              required={true}
            />
            {formik.touched.branch_id && formik.errors.branch_id && (
              <div className="d-flex invalid-feedback">
                {formik.errors.branch_id}
              </div>
            )}
          </div>
          <div className="col-3 col-md-3 col-sm-3">
            <label htmlFor="speciality_id" className="app-form-label">
              Especialidad:
            </label>
            <SelectSpecialitiesComponent
              id="speciality_id"
              name="speciality_id"
              className={`form-select ${formik.touched.speciality_id && formik.errors.speciality_id
                ? "is-invalid"
                : ""
                }`}
              value={formik.values.speciality_id}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              required={true}
            />
            {formik.touched.speciality_id && formik.errors.speciality_id && (
              <div className="d-flex invalid-feedback">
                {formik.errors.speciality_id}
              </div>
            )}
          </div>
          <div className="col-4 col-md-4 col-sm-4">
            <label htmlFor="is_active" className="app-form-label">
              Estatus:
            </label>
            <select
              id="is_active"
              name="is_active"
              className={`form-select ${formik.touched.is_active && formik.errors.is_active
                ? "is-invalid"
                : ""
                }`}
              value={formik.values.is_active}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              required
            >
              <option value="">seleccione una opción</option>
              <option value="true">Activo</option>
              <option value="false">Inactivo</option>
            </select>
            {formik.touched.is_active && formik.errors.is_active && (
              <div className="d-flex invalid-feedback">
                {formik.errors.is_active}
              </div>
            )}
          </div>
        </div>
      </div>
      <div>
        <DoctorsScheduleComponent formik={formik} />
      </div>
      <div className="app-protocols-footer">
        <div>
          <button type="sumbit" className="btn app-button bg-green-ryb">
            Actualizar Registro
          </button>
        </div>
        <div>
          <button
            type="reset"
            className="btn app-button default"
            onClick={() => navigate(-1)}
          >
            Cancelar
          </button>
        </div>
      </div>
    </form>
  );
};

export default EditDoctorsComponent;
