import React from "react";
import ToBoolean from "../../../../utils/ToBoolean";

const FieldInputRadioColRow = (props) => {
  const split = (value) => {
    const elements = value.split("\n");
    return elements.map((element, index) => (
      <span key={index} className="d-flex">
        {element}
      </span>
    ));
  };

  return (
    <div
      className={`form-${props?.field_type} ${props?.field_class}`}
      style={props.field_style}
    >
      <div className="col-6">
        <label className="form-label">{split(props.field_label)}</label>
      </div>
      <div className="col-6">
        <div className="options">
          {props?.field_options.map((option, index) => (
            <div key={index} className="option">
              <div>
                <label htmlFor={props?.field_id + index}>{option.name}</label>
              </div>
              <div>
                <input
                  type="radio"
                  id={props?.field_id + index}
                  name={props.field_name}
                  value={option.value}
                  readOnly={ToBoolean(props?.is_readonly)}
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default FieldInputRadioColRow;
