import React from "react";

const SelectFieldClassComponent = (props) => {
  const { id, name, className, onChange, value, required, readOnly } = props;

  return (
    <select
      id={id}
      name={name}
      className={className}
      value={value}
      onChange={onChange}
      required={required}
      readOnly={readOnly}
    >
      <option value="">Selecciona una opción</option>
      <option value="col-1 col-md-1 col-sm-1">columna 1 (vertical)</option>
      <option value="col-2 col-md-2 col-sm-2">columna 2 (vertical)</option>
      <option value="col-3 col-md-3 col-sm-3">columna 3 (vertical)</option>
      <option value="col-4 col-md-4 col-sm-4">columna 4 (vertical)</option>
      <option value="col-5 col-md-5 col-sm-5">columna 5 (vertical)</option>
      <option value="col-6 col-md-6 col-sm-6">columna 6 (vertical)</option>
      <option value="col-7 col-md-7 col-sm-7">columna 7 (vertical)</option>
      <option value="col-8 col-md-8 col-sm-8">columna 8 (vertical)</option>
      <option value="col-9 col-md-9 col-sm-9">columna 9 (vertical)</option>
      <option value="col-10 col-md-10 col-sm-10">columna 10 (vertical)</option>
      <option value="col-11 col-md-11 col-sm-11">columna 11 (vertical)</option>
      <option value="col-12 col-md-12 col-sm-12">columna 12 (vertical)</option>
      <option value="d-flex col-1 col-md-1 col-sm-1">columna 1 (horizontal)</option>
      <option value="d-flex col-2 col-md-2 col-sm-2">columna 2 (horizontal)</option>
      <option value="d-flex col-3 col-md-3 col-sm-3">columna 3 (horizontal)</option>
      <option value="d-flex col-4 col-md-4 col-sm-4">columna 4 (horizontal)</option>
      <option value="d-flex col-5 col-md-5 col-sm-5">columna 5 (horizontal)</option>
      <option value="d-flex col-6 col-md-6 col-sm-6">columna 6 (horizontal)</option>
      <option value="d-flex col-7 col-md-7 col-sm-7">columna 7 (horizontal)</option>
      <option value="d-flex col-8 col-md-8 col-sm-8">columna 8 (horizontal)</option>
      <option value="d-flex col-9 col-md-9 col-sm-9">columna 9 (horizontal)</option>
      <option value="d-flex col-10 col-md-10 col-sm-10">
        columna 10 (horizontal)
      </option>
      <option value="d-flex col-11 col-md-11 col-sm-11">
        columna 11 (horizontal)
      </option>
      <option value="d-flex col-12 col-md-12 col-sm-12">
        columna 12 (horizontal)
      </option>
    </select>
  );
};

export default SelectFieldClassComponent;
