import React from "react";
import MenuIcon from "../../assets/icons/menu.svg";
import SearchIcon from "../../assets/icons/search.svg";
import LogoIcon from "../../assets/icons/logo.svg";
import BellIcon from "../../assets/icons/bell.svg";
import MenuHeaderComponent from "../../components/header/MenuHeaderComponent";
import { NavLink } from "react-router-dom";
import "./Header.css";

function Header({ show, handleToggle }) {
  return (
    <div className="app-header-container">
      <div className={`app-icon ${show && "active"}`}>
        <button onClick={handleToggle}>
          <img src={MenuIcon} alt="menu" width="19px" height="10px" />
        </button>
      </div>
      <div className="divider"></div>
      <div className="app-header-search">
        <button type="button">
          <img src={SearchIcon} alt="search" width="28px" height="28px" />
        </button>
        <input
          type="search"
          id="search"
          name="search"
          placeholder="¿Qué estás buscando?"
        ></input>
      </div>
      <div className="divider"></div>
      <div className="app-header-logo">
        <NavLink to="/">
          <img src={LogoIcon} alt="menu" width="30px" height="36px" />
        </NavLink>
      </div>
      <div className="divider"></div>
      <div className="app-header-menu">
        <MenuHeaderComponent />
      </div>
      <div className="divider"></div>
      <div className="app-icon">
        <button>
          <img src={BellIcon} alt="menu" width="30px" height="36px" />
        </button>
      </div>
    </div>
  );
}

export default Header;
