import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { postVerifyEmail } from "../../../../services/auth/AuthService";
import "./EmailVerification.css";

const EmailVerification = () => {
  const {email, hashed_token} = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [message, setMessage] = useState("Cargando...");
  const [error, setError] = useState("");
  const navigate = useNavigate();

  useEffect(()=> {
    postVerifyEmail(email, hashed_token).then((response)=> {
      if(response.status === 200){
        setMessage(response.data.message);
        setIsLoading(!isLoading);
      }
    }).catch((error)=> {
      const response = error.response;
      if([400].includes(response.status)){
        setError(`Error: ${response.data.detail}`);
        setMessage("");
        setIsLoading(!isLoading);
      }
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  const goToLogin = () => navigate("/login");

  return (
    <div className="container vh-100">
      <div className="form" >
        <p className="message">
          {message}
        </p>
        <p className="message-error">
          {error}
        </p>
        <div className="buttons">
          <div>
            <button
              type="button"
              className="m-auto btn bg-danger text-white"
              onClick={goToLogin}
              disabled={isLoading}
            >
              Iniciar Sesión
            </button>
          </div>
        </div>
      </div>
      <div className="footer">
        <span>© 2022 Consulta Externa by HG Consulting</span>
      </div>
    </div>
  );
};

export default EmailVerification;
