import React, { useMemo, useState } from "react";

const ProtocolsFormComponent = (props) => {
  const { state, setState } = props;
  const [search, setSearch] = useState("");

  const handleSearch = ({ target }) => {
    setSearch(target.value.toLowerCase());
  };

  const handleAssigned = () => {
    setState(state.map((form) => {
      if (form.is_selected && !form.is_assigned) {
        form.is_selected = !form.is_selected;
        form.is_assigned = !form.is_assigned;
      }
      return form;
    }))
  };

  const handleUnassigned = () => {
    setState(state.map((form) => {
      if (form.is_selected && form.is_assigned) {
        form.is_selected = !form.is_selected;
        form.is_assigned = !form.is_assigned;
      }
      return form;
    }))
  };

  const handleDelete = () => {
    setState(state.map((item) => ({
      ...item,
      is_selected: false,
      is_assigned: false,
      is_required: false,
    })));
  };

  const handleSelected = (formId) => {
    setState(
      state.map((form) => {
        if (form.form_id === formId) {
          form.is_selected = !form.is_selected
        }
        return form
      }));
  };

  const handleRequired = (formId) => {
    setState(
      state.map((form) => {
        if (form.form_id === formId) {
          form.is_required = !form.is_required;
        }
        return form;
      })
    );
  };

  const unassigned = useMemo(() => state.filter((form) => {
    return search.length > 0 ? form.name.toLowerCase().includes(search) && form.is_assigned === false : form.is_assigned === false
  }), [state, search]);

  const assigned = useMemo(() => state.filter((form) => form.is_assigned === true), [state]);

  return (
    <div className="row d-flex app-protocols-form">
      <div className="col">
        <div className="col-12 app-table-title fs-4 m-1">
          <label>Formularios</label>
        </div>
        <div className="col-12 app-protocols-form-container white m-0">
          <div className="col">
            <input
              type="search"
              id="search"
              name="search"
              className="form-control"
              placeholder="Buscar formulario"
              onChange={handleSearch}
            />
          </div>
          {unassigned.length > 0 && (
            <div className="col app-protocols-list">
              {unassigned.map((form) => (
                <div
                  key={form.form_id}
                  className={`app-protocols-list-item${form?.is_selected ? " selected" : ""
                    }`}
                  onClick={() => handleSelected(form.form_id)}
                >
                  {form?.name}
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
      <div className="col-1 col-md-1 col-sm-1">
        <div className="app-protocols-actions">
          <span>
            <button type="button" className="circle" onClick={handleAssigned}>
              <i className="fa-solid fa-chevron-right"></i>
            </button>
          </span>
          <span>
            <button type="button" className="circle" onClick={handleUnassigned}>
              <i className="fa-solid fa-chevron-left"></i>
            </button>
          </span>
          <span>
            <button type="button" className="square" onClick={handleDelete}>
              <i className="bi bi-trash"></i>
            </button>
          </span>
        </div>
      </div>
      <div className="col">
        <div className="col-12 app-table-title m-1 fs-4">
          <label>Formularios Asignados</label>
        </div>
        <div className="col-12 app-protocols-form-container white m-0">
          <div className="row d-flex">
            <div className="col"></div>
            <div className="col-3 col-md-3 col-sm-3 app-table-title fs-5">
              Obligatorio
            </div>
          </div>
          {assigned.length > 0 && (
            <div className="col app-protocols-list">
              {assigned.map((form) => (
                <div
                  key={form.form_id}
                  className={`app-protocols-list-item${form?.is_selected ? " selected" : ""
                    }`}
                >
                  <div
                    className="col"
                    onClick={() => handleSelected(form.form_id)}
                  >
                    {form?.name}
                  </div>
                  <div className="col-2 col-md-2 col-sm-2 app-table-title fs-5">
                    <i
                      className={`bi bi-${form.is_required ? "check-circle-fill" : "circle"
                        }`}
                      onClick={() => handleRequired(form.form_id)}
                    ></i>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ProtocolsFormComponent;
