import React from "react";

const PatientCareStepsComponent = ({ steps, step, handleSelectStep }) => {

  const isStepActive = (is_current, is_completed) => `app-patientcare-step ${is_current | is_completed ? "active" : ""}`;

  return (
    <div className={`app-patientcare-steps`}>
      <div className="app-patientcare-steps-bar">
        <span style={{ width: `calc(100% / ${steps.length - 1} * ${step.id})` }}></span>
      </div>
      <div className="app-patientcare-steps-icons">
        {steps.map(({ id: index, form, is_current, is_completed }) => (
          <div
            key={index}
            className={isStepActive(is_current, is_completed)}
          >
            <div className="circle">
              <button onClick={() => handleSelectStep(index)}>
                {index + 1}
              </button>
            </div>
            <div className="icon">
              <i className="fa-solid fa-sort-up"></i>
            </div>
            <div className="dialog">
              <span>{form.form_name}</span>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default PatientCareStepsComponent;
