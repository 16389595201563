import React, { useEffect, useMemo, useState } from "react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const FieldAudiogram = (props) => {
  const initialValues = { left: {}, right: {} };
  const [state, setState] = useState(initialValues);
  const labels = ["125", "250", "500", "1000", "2000", "4000", "8000"];

  useEffect(() => {
    if (props?.value !== "") {
      setState({ ...state, ...JSON.parse(props?.value) });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.value]);

  const handleChange = (event, position) => {
    const stateTemp = {
      ...state,
      [position]: {
        ...state[position],
        [event.target.name]: event.target.value,
      },
    };
    props?.onChange({ name: props?.field_name, value: JSON.stringify(stateTemp) });
    setState(stateTemp);
  };

  const handleKeyDown = (event) => {
    const key = event.key;
    const value = event.target.value;

    if (key === "Backspace" || key === "Delete") {
      return;
    }

    if (!/^-?\d+$/.test(key) && key !== "-") {
      event.preventDefault();
      return;
    }

    const temp = parseInt(value + key, 10);

    if (temp < -10 || temp > 120) {
      event.preventDefault();
    }
  };

  const options = {
    responsive: true,
    scales: {
      x: {
        display: true,
        title: {
          display: true,
          text: "kHZ",
          font: { weight: "Bold", size: "16px" },
          color: "#39711F",
        },
      },
      y: {
        display: true,
        title: {
          display: true,
          text: "dB HL",
          font: { weight: "Bold", size: "16px" },
          color: "#39711F",
        },
        min: -10,
        max: 120,
        position: "top",
      },
    },
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: "Audiograma",
        color: "#39711F",
        font: { weight: "bold", size: "20px" },
        fullSize: true,
      },
      interaction: {
        intersect: true,
      },
    },
  };

  const data = useMemo(
    () => ({
      labels,
      datasets: [
        {
          label: "Oído Derecho",
          data: Object.values(state.right),
          fill: false,
          borderColor: "#39711F",
          backgroundColor: "rgba(0, 0, 0, 0.75)",
        },
        {
          label: "Oído Izquierdo",
          data: Object.values(state.left),
          fill: false,
          borderColor: "#25cec5",
          backgroundColor: "rgba(0, 0, 0, 0.75)",
        },
      ],
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [state]
  );

  return (
    props?.state?.fields && (
      <div className={`${props?.field_class}`}>
        <div className="row d-flex">
          <span style={{ color: "#39711f", fontFamily: "Noto Sans Bold" }}>
            Oído Derecho
          </span>
          {labels.map((label, index) => (
            <div key={index} className="col form-group">
              <label className="form-label text-center">{label}Hz</label>
              <input
                className="form-control text-center"
                type="number"
                id={`${label}`}
                name={`${label}`}
                placeholder={`${label}Hz`}
                min="-10"
                max="120"
                value={!state.right[label] ? "" : state.right[label]}
                onChange={(e) => handleChange(e, "right")}
                onKeyDown={handleKeyDown}
                readOnly={!props.isPreview ? false : props?.isPreview}
                disabled={!props.isPreview ? false : props?.isPreview}
                autoComplete="off"
                tabIndex={index}
                required
              />
            </div>
          ))}
        </div>
        <div className="row d-flex">
          <span style={{ color: "#25cec5", fontFamily: "Noto Sans Bold" }}>
            Oído Izquierdo
          </span>
          {labels.map((label, index) => (
            <div key={index} className="col form-group">
              <label className="form-label text-center">{label}Hz</label>
              <input
                className="form-control text-center"
                type="number"
                id={`${label}`}
                name={`${label}`}
                placeholder={`${label}Hz`}
                min="-10"
                max="120"
                value={!state.left[label] ? "" : state.left[label]}
                onChange={(e) => handleChange(e, "left")}
                onKeyDown={handleKeyDown}
                readOnly={!props.isPreview ? false : props.isPreview}
                disabled={!props.isPreview ? false : props.isPreview}
                autoComplete="off"
                tabIndex={index}
                required
              />
            </div>
          ))}
        </div>
        <div className="d-flex flex-row justify-content-center">
          <div className="col-12 col-md-12 col-sm-12 audiogram">
            <Line options={options} data={data} />
          </div>
        </div>
      </div>
    )
  );
};

export default FieldAudiogram;
