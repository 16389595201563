import React, { useContext, useEffect, useState } from "react";
import Pagination from "../../../utils/Pagination";
import PaginationCounter from "../../../utils/PaginationCounter";
import PaginationComponent from "../../pagination/PaginationComponent";
import SortArrayById from "../../../utils/SortArrayById";
import SwalConfirm from "../../../utils/SwalConfirm";
import SwalLoading from "../../../utils/SwalLoading";
import SwalSuccess from "../../../utils/SwalSuccess";
import SwalError from "../../../utils/SwalError";
import { Table } from "react-bootstrap";
import { NavLink, useNavigate } from "react-router-dom";
import { toInteger } from "lodash";
import { deleteProfileById, getProfiles } from "../../../../services/settings/profiles/ProfilesService";
import SessionContext from "../../../context/SessionContext";
import IsActiveIcon from "../../../utils/IsActiveIcon";

const ListProfilesComponent = (props) => {
  const [data, setData] = useState([]);
  const [current, setCurrent] = useState(1);
  const [pages, setPages] = useState([]);
  const [rows, setRows] = useState(10);
  const [isReload, setIsReload] = useState(true);
  const { isPermitted, closeSession } = useContext(SessionContext);

  const navigate = useNavigate();

  useEffect(() => {
    if (isReload) {
      getProfiles()
        .then((response) => {
          if (response.status === 200) {
            setCurrent(1);
            setData(SortArrayById(response.data));
            setPages(Pagination(SortArrayById(response.data), rows));
            setIsReload(!isReload);
          }
        })
        .catch((error) => {
          const response = error.response;
          if (response.status === 401) {
            closeSession();
          } else if ([400, 404].includes(response.status)) {
            SwalError({ title: "Error", message: response.data.detail })
          } else {
            SwalError({ title: "Error no Controlado", message: JSON.stringify(response.data.detail) })
          }
        });
    }

    return () => { };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isReload]);

  useEffect(() => {
    setCurrent(1);
    setPages(Pagination(data, rows));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rows]);

  const handleCreate = () => {
    navigate("crear");
  };

  const handleGoTo = (value) => {
    navigate(`${value}`);
  };

  const handleBack = () => {
    navigate(-1);
  };

  const handleSearch = (event) => {
    const value = event.target.value.toLowerCase();
    const array = [...data];

    if (value !== "") {
      setCurrent(1);
      setPages(Pagination(filter(array, value), rows));
    } else {
      setCurrent(1);
      setPages(Pagination(data, rows));
    }
  };

  const filter = (array, value) => {
    return array.filter(
      (item) =>
        (item?.id === toInteger(value)) |
        item?.name?.toLowerCase().includes(value)
    );
  };

  const handleDelete = (profile) => {
    SwalConfirm({
      title: "Eliminar Perfil",
      message: `¿Esta seguro de eliminar el perfil: ${profile.name} ?`,
    }).then((result) => {
      if (result.isConfirmed) {
        SwalLoading({ title: "Eliminando perfil" });
        deleteProfileById(profile.id)
          .then((response) => {
            if (response.status === 204) {
              SwalSuccess({
                title: "Eliminar Perfil",
                message: "El perfil ha sido eliminado correctamente",
              });
              setIsReload(!isReload);
            }
          })
          .catch((error) => {
            const response = error.response;
            if (response.status === 401) {
              closeSession();
            } else if ([400, 404].includes(response.status)) {
              SwalError({ title: "Error", message: response.data.detail })
            } else {
              SwalError({ title: "Error no Controlado", message: JSON.stringify(response.data.detail) })
            }
          });
      }
    });
  };

  return (
    <React.Fragment>
      <div className="app-container-actions">
        <div className="app-container-actions-label">
          <div className="app-form-search">
            <label className="app-form-label" htmlFor="search">
              Buscar
            </label>
            <input
              type="search"
              className="form-control"
              name="search"
              id="search"
              placeholder="Buscar perfil"
              onChange={handleSearch}
            />
          </div>
        </div>
        <div className="app-container-actions-button">
          {isPermitted(["create_profile"]) &&
            <button className="app-button" onClick={handleCreate}>
              Nuevo Perfil
            </button>

          }
          {isPermitted(["select_user"]) &&
            <button
              className="app-button bg-japanese-laurel"
              onClick={() => handleGoTo("/configuracion/usuarios")}
            >
              Ir a Usuarios
            </button>
          }
        </div>
      </div>
      <div className="boxshadow white">
        <Table striped hover className="round">
          <thead className="bg-green-ryb">
            <tr>
              <th>ID</th>
              <th>Perfil</th>
              <th>Activo</th>
              {isPermitted(["update_profile", "delete_profile"]) && <th></th>}
            </tr>
          </thead>
          <tbody>
            {pages
              .find((item) => item.id === current)
              ?.items.map((profile, index) => (
                <tr key={index}>
                  <td>{profile.id}</td>
                  <td>{profile.name}</td>
                  <td>
                    {IsActiveIcon(profile.is_active)}
                  </td>
                  {isPermitted(["update_profile", "delete_profile"]) &&
                    <td>
                      <button className="btn btn-outline-secondary detalle">
                        Detalles
                        <ul className="menu-button-detalle">
                          {isPermitted(["update_profile"]) &&
                            <li>
                              <NavLink
                                to={`${profile.id}/editar`}
                                className="nav-link"
                              >
                                Editar Perfil
                              </NavLink>
                            </li>
                          }
                          {isPermitted(["delete_profile"]) &&
                            <li onClick={() => handleDelete(profile)}>
                              Eliminar Perfil
                            </li>
                          }
                        </ul>
                      </button>
                    </td>
                  }
                </tr>
              ))}
          </tbody>
        </Table>
      </div>
      <div className="app-footer-actions">
        <PaginationCounter data={data} rows={rows} setRows={setRows} />
        <PaginationComponent
          pages={pages}
          current={current}
          setCurrent={setCurrent}
        />
        <div className="app-table-button">
          <button
            className="app-button small bg-green-ryb"
            onClick={handleBack}
          >
            Regresar
          </button>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ListProfilesComponent;
