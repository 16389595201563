import React, { useContext } from "react";
import { useNavigate } from "react-router";
import ListUsersComponent from "../../../components/settings/users/ListUsersComponent";
import DateFormat from "../../../utils/DateFormat";
import SessionContext from "../../../context/SessionContext";


const ListUsers = () => {
  const navigate = useNavigate();
  const {session} = useContext(SessionContext);

  return (
    <React.Fragment>
      <div className="app-container-header">
        <div className="left">
          <span onClick={() => navigate(-1)}>
            <span>
              <i className="fa-solid fa-arrow-left fs-auto"></i>
            </span>
            <span className="bold">Usuarios</span>
          </span>
          <span>{DateFormat()}</span>
        </div>
        <div className="right">
          <span className="bold">{session?.username}</span>
          <span>{session.email}</span>
        </div>
      </div>
      <div className="app-settings">
        <ListUsersComponent />
      </div>
    </React.Fragment>
  );
};

export default ListUsers;
