import PropTypes from "prop-types";

const DayOfWeek = (value) => {
  switch (value) {
    case 0:
      return "Domingo";
    case 1:
      return "Lunes";
    case 2:
      return "Martes";
    case 3:
      return "Miércoles";
    case 4:
      return "Jueves";
    case 5:
      return "Viernes";
    case 6:
      return "Sábado ";
    default:
      return;
  }
};

DayOfWeek.propTypes = { value: PropTypes.number.isRequired };

export default DayOfWeek;
