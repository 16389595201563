import axiosService from "../../connection/AxiosService";


export async function getCatalogs() {
    if (axiosService.checkToken()) {
        return await axiosService.client.get("/catalogs");
    }
}

export async function getCatalogById(catalogId) {
    if (axiosService.checkToken()) {
        return await axiosService.client.get(`/catalogs/${catalogId}`);
    }
}

export async function getCatalogFieldsById(catalogId) {
    if (axiosService.checkToken()) {
        return await axiosService.client.get(`/catalogs/${catalogId}/fields`);
    }
}

export async function postCatalog(data) {
    if (axiosService.checkToken()) {
        if (data) {
        return await axiosService.client.post("/catalogs", data);
        }
    }
}

export async function putCatalogById(catalogId, data) {
    if (axiosService.checkToken()) {
        if (data) {
            return await axiosService.client.put(`/catalogs/${catalogId}`, data);
        }
    }
}
