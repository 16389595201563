import PropTypes from "prop-types";

const SortArrayById = (array) => {
  return array.sort((a, b) => {
    if (a.id > b.id) {
      return 1;
    } else if (a.id < b.id) {
      return -1;
    }
    return 0;
  });
};

export default SortArrayById;

SortArrayById.propTypes = {
  array: PropTypes.array.isRequired,
};
