import React, { useContext, useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { NavLink, useNavigate } from "react-router-dom";
import { toInteger } from "lodash";
import Pagination from "../../../utils/Pagination";
import PaginationCounter from "../../../utils/PaginationCounter";
import SortArrayById from "../../../utils/SortArrayById";
import SwalLoading from "../../../utils/SwalLoading";
import PaginationComponent from "../../pagination/PaginationComponent";
import Swal from "sweetalert2";
import SwalError from "../../../utils/SwalError";
import { getForms } from "../../../../services/settings/forms/FormService";
import SessionContext from "../../../context/SessionContext";


const ListFormsComponent = (props) => {
  const [data, setData] = useState([]);
  const [current, setCurrent] = useState(1);
  const [pages, setPages] = useState([]);
  const [rows, setRows] = useState(10);
  const navigate = useNavigate();
  const { isPermitted } = useContext(SessionContext);

  useEffect(() => {
    SwalLoading({ title: "Consultando Formularios" });
    getForms()
      .then((response) => {
        if (response.status === 200) {
          setCurrent(1);
          setData(SortArrayById(response.data));
          setRows(10);
          setPages(Pagination(SortArrayById(response.data), rows));
          Swal.close();
        }
      })
      .catch((error) => {
        const response = error.response;
        if (response.status === 401) {
          SwalError({
            title: "Error",
            message: response.data.detail,
          });
        }
      });
    return () => { };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setCurrent(1);
    setPages(Pagination(data, rows));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rows]);

  const handleBack = () => {
    navigate(-1);
  };

  const handleCreate = () => {
    navigate("crear");
  };

  const handleSearch = (event) => {
    const value = event.target.value.toLowerCase();
    const array = [...data];

    if (value !== "") {
      setCurrent(1);
      setPages(Pagination(filter(array, value), rows));
    } else {
      setCurrent(1);
      setPages(Pagination(data, rows));
    }
  };

  const filter = (array, value) => {
    return array.filter(
      (item) =>
        (item?.id === toInteger(value)) |
        item?.form_name?.toLowerCase().includes(value)
    );
  };

  return (
    <div>
      <div className="app-container-actions">
        <div className="app-container-actions-label fs-4">
          <div className="app-form-search">
            <label className="app-form-label" htmlFor="search">
              Buscar
            </label>
            <input
              type="search"
              className="form-control"
              name="search"
              id="search"
              placeholder="Ingrese el nombre de formulario"
              onChange={handleSearch}
            />
          </div>
        </div>
        <div className="app-container-actions-button">
          {isPermitted(["create_form"]) &&
            <button className="app-button" onClick={handleCreate}>
              Crear Nuevo Formulario
            </button>
          }

        </div>
      </div>
      <div className="app-container-table">
        <h1 className="app-table-title">Formularios</h1>
        <Table striped hover>
          <thead>
            <tr>
              <th>#</th>
              <th>Nombre</th>
              <th>Acciones</th>
            </tr>
          </thead>
          <tbody>
            {pages
              .find((item) => item.id === current)
              ?.items.map((form, index) => (
                <tr key={index}>
                  <td>{form.id}</td>
                  <td>{form.form_name}</td>
                  <td>

                    <NavLink to={`${form.id}/ver`} className="nav-link">
                      <span>
                        <i className="fa-solid fa-eye"></i>
                      </span>
                      <span>Ver Formulario</span>
                    </NavLink>

                    {isPermitted(["update_form"]) &&
                      <NavLink to={`${form.id}/editar`} className="nav-link">
                        <span>
                          <i className="fa-solid fa-pencil"></i>
                        </span>
                        <span>Editar Formulario</span>
                      </NavLink>
                    }
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
      </div>
      <div className="app-footer-actions">
        <PaginationCounter data={data} rows={rows} setRows={setRows} />
        <PaginationComponent
          pages={pages}
          current={current}
          setCurrent={setCurrent}
        />
        <div className="app-table-button">
          <button
            className="app-button small bg-green-ryb"
            onClick={handleBack}
          >
            Regresar
          </button>
        </div>
      </div>
    </div>
  );
};

export default ListFormsComponent;
