import { useEffect, useState } from 'react';
import { getProfile } from '../../services/auth/AuthService';
import SwalError from '../utils/SwalError';
import {useNavigate} from "react-router-dom";

const usePermission = (permission) => {
    const navigate = useNavigate();
    const [permitted, setIsPermitted] = useState(null);

    useEffect(() => {
        getProfile().then((response) => {
            if (response.status === 200) {
                const data = response.data;
                if (data?.user !== undefined) {
                    setIsPermitted(isPermitted(data?.user?.profiles, permission))
                  } else {
                    setIsPermitted(isPermitted(data?.profiles, permission))
                  }
            }
        }).catch((error) => {
            const response = error.response;
            if (response.status === 401) {
              SwalError({ title: "Error", message: response.data.detail });
              sessionStorage.clear();
              navigate("/login");
            }
          });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return permitted;
};

const isPermitted = (profiles, action) => {
    return profiles?.some(({ profile }) => profile?.permissions?.some((permission) => {
        if (action.includes(permission.permission.permission)) {
            return permission.is_active
        }
        return false;
    }));
};

export default usePermission;
