import React, { useContext, useMemo } from 'react'
import { CalculateYears } from '../../utils/CalculateYears';
import TranslateGender from '../../utils/TranslateGender';
import UserPhoto from "../../assets/images/home/profile.svg";
import permissions from "../../json/route_permission.json";
import SessionContext from '../../context/SessionContext';
import { NavLink } from 'react-router-dom';

const PatientCardInformation = ({ patient, state, handleSelected }) => {
    const { isPermitted } = useContext(SessionContext);

    const collapseId = `show_${patient?.id}_details`;

    const fullName = `${patient?.first_name} ${patient?.second_name} ${patient?.paternal_surname} ${patient?.maternal_surname}`;

    const age = useMemo(() => CalculateYears(patient?.birth_date), [patient?.birth_date]);

    const antiquity = useMemo(() => CalculateYears(patient?.hire_date), [patient?.hire_date]);

    const handleShowDetail = (e) => {

        const collapse = document.getElementById(e.target.id);

        if (collapse.classList.contains("show")) {
            collapse.classList.toggle("show");
            e.target.innerHTML = "Ver más detalles";
        } else {
            collapse.classList.toggle("show");
            e.target.innerHTML = "Ocultar Detalles";
        }
    }

    return (
        <div
            className={`d-flex flex-row gap-2${state?.patient_id === patient.id ? " selected" : ""}`}
            onClick={() => handleSelected(patient?.id)}
        >
            <div className="col-10 col-md-10">
                <div className="d-flex flex-row flex-wrap justify-start">
                    <div className="col-md-3">
                        <label htmlFor="full_name" className="form-label fw-semibold">
                            Nombre Paciente:
                        </label>
                        <input
                            type="text"
                            className="form-control-plaintext"
                            id="full_name"
                            name="full_name"
                            value={fullName}
                            readOnly
                        />
                    </div>
                    <div className="col-md-3">
                        <label htmlFor="full_name" className="form-label fw-semibold">
                            No. Identificación:
                        </label>
                        <input
                            type="text"
                            className="form-control-plaintext"
                            id="number_id"
                            name="number_id"
                            value={patient?.number_id}
                            readOnly
                        />
                    </div>
                    <div className="col-md-2">
                        <label htmlFor="gender" className="form-label fw-semibold">
                            Sexo:
                        </label>
                        <input
                            type="text"
                            className="form-control-plaintext"
                            id="gender"
                            name="gender"
                            value={TranslateGender(patient?.gender)}
                            readOnly
                        />
                    </div>
                    <div className="col-md-2">
                        <label htmlFor="age" className="form-label fw-semibold">
                            Edad:
                        </label>
                        <input
                            type="number"
                            id="age"
                            name="age"
                            className="form-control-plaintext"
                            value={age}
                            readOnly
                        />
                    </div>
                    <div className="col-md-2">
                        <label htmlFor="birth_date" className="form-label fw-semibold">
                            Fecha de Nacimiento:
                        </label>
                        <input
                            type="date"
                            className="form-control-plaintext"
                            id="birth_date"
                            name="birth_date"
                            value={patient?.birth_date}
                            readOnly
                        />
                    </div>
                    <div className="collapse" id={collapseId}>
                        <div className="d-flex flex-wrap justify-evenly">
                            <div className="col-md-4">
                                <label htmlFor="hire_date" className="form-label fw-semibold">
                                    Fecha Ingreso:
                                </label>
                                <input
                                    type="date"
                                    className="form-control-plaintext"
                                    id="hire_date"
                                    name="hire_date"
                                    value={patient?.hire_date}
                                    readOnly
                                />
                            </div>
                            <div className="col-md-4">
                                <label htmlFor="antiquity" className="form-label fw-semibold">
                                    Antigüedad:
                                </label>
                                <input
                                    type="text"
                                    className="form-control-plaintext"
                                    id="antiquity"
                                    name="antiquity"
                                    value={antiquity}
                                    readOnly
                                />
                            </div>
                            {patient?.patient_extra_fields.map((field, index) =>
                                <div className="col-md-4 p-1" key={index}>
                                    <label htmlFor={field.extra_field.field_id} className="form-label fw-semibold">
                                        {field.extra_field.field_label}
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control-plaintext text-truncate"
                                        id={field.extra_field.field_id}
                                        name={field.extra_field.field_name}
                                        value={field.value === "" ? 'N/A' : field.value}
                                        readOnly
                                    />
                                </div>)}
                        </div>
                    </div>
                </div>
                <div className="d-flex flex-row gap-2">
                    {isPermitted(permissions.clinic_history.index) &&
                        <div className="col-md-3 col-sm-3">
                            <NavLink
                                className="btn"
                                to={`/historia-clinica/${patient?.id}/ver`}
                            >
                                Ver historia Clínica
                            </NavLink>
                        </div>}
                    <div className="col-md-9 col-sm-9 float-right">
                        <button className="nav-link text-secondary text-decoration-underline" id={collapseId} onClick={handleShowDetail}>
                            Ver más detalles
                        </button>
                    </div>
                </div>
            </div>
            <div className="col">
                <img
                    src={patient?.photo ? patient?.photo : UserPhoto}
                    alt="profile"
                    className="modal-avatar"
                    width="124px"
                    height="124px" />
            </div>
        </div>
    )
}

export default PatientCardInformation
