import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import DoubleRowLeftIcon from '../../assets/icons/double_arrow_left.svg';
import DoubleRowRightIcon from '../../assets/icons/double_arrow_right.svg';
import MedicalCareModalComponent from './MedicalCareModalComponent';
import SwalLoading from '../../utils/SwalLoading';
import SwalError from '../../utils/SwalError';
import CalendarComponent from './CalendarComponent';
import CameraComponent from '../camera/CameraComponent';
import UserPhoto from '../../assets/images/home/profile.svg';
import ToUpperCase from '../../utils/ToUpperCase';
import SessionContext from '../../context/SessionContext';
import Swal from 'sweetalert2';
import { CalculateYears } from '../../utils/CalculateYears';
import {
  getExtraFields,
  postPatient,
} from '../../../services/patients/PatientsService';
import permissions from '../../json/route_permission.json';

const MedicalCareComponent = () => {
  const [image, setImage] = useState(null);
  const [showCapture, setShowCapture] = useState(false);
  const [show, setShow] = useState(false);
  const [search, setSearch] = useState();
  const [extraFields, setExtraFields] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const { isPermitted, closeSession } = useContext(SessionContext);

  useEffect(() => {
    getExtraFields()
      .then(response => {
        if (response.status === 200) {
          const data = response.data;
          setExtraFields(data);
          formik.setFieldValue(
            'patient_extra_fields',
            data.map(field => ({
              extra_field_id: field.id,
              value: '',
              is_active: true,
            }))
          );
        }
      })
      .catch(error => {
        const response = error.response;
        if (response.status === 401) {
          closeSession();
        }
      });
    // eslint-disable-next-line
  }, []);

  const formik = useFormik({
    initialValues: {
      photo: '',
      number_id: '',
      first_name: '',
      second_name: '',
      paternal_surname: '',
      maternal_surname: '',
      birth_date: '',
      gender: '',
      hire_date: '',
      patient_extra_fields: [],
      is_active: false,
    },
    validationSchema: yup.object({
      photo: yup.string().matches(/^data:image\/png;base64,[\w\W=]+$/gm),
      number_id: yup
        .string('Escriba su número de documento de identidad')
        .required('Debe Escribir su número de documento de identidad.'),
      first_name: yup
        .string('Escriba su nombre')
        .required('Debe escribir su primer nombre.'),
      second_name: yup.string('Escriba su segundo nombre'),
      paternal_surname: yup
        .string('Escriba su apellido paterno')
        .required('Debe ingresar su apellido paterno.'),
      maternal_surname: yup
        .string('Escriba su apellido materno')
        .required('Debe escribir su apellido materno.'),
      birth_date: yup
        .date('Ingrese su fecha de nacimiento')
        .required('Debe ingresar su fecha de nacimiento.'),
      hire_date: yup.date().nullable(),
      patient_extra_fields: yup.array(
        yup.object({
          extra_field_id: yup.number().required(),
          value: yup.string(),
          is_active: yup.bool().required(),
        })
      ),
      gender: yup
        .string('Seleccione una opción')
        .required('Debe seleccionar al menos una opción'),
      is_active: yup.bool().default(() => true),
    }),
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: values => {
      SwalLoading({ title: 'Creando Paciente' });
      postPatient(values)
        .then(response => {
          if (response.status === 200) {
            handleSearch();
            Swal.close();
          }
        })
        .catch(error => {
          const response = error.response;
          if (response.status === 401) {
            closeSession();
          } else if ([400, 404].includes(response.status)) {
            SwalError({
              title: 'Error',
              message: response.data.detail,
            });
          } else {
            SwalError({
              title: 'Error No Controlado',
              message: JSON.stringify(response.data.detail),
            });
          }
        });
    },
  });

  const handleToggle = () => {
    setShow(!show);
  };

  const handleToggleModal = () => {
    setShowModal(!showModal);
  };

  const handleSearch = () => {
    const values = { ...formik.values };
    setSearch(values);
    handleToggleModal();
  };

  const handlePhoto = value => {
    setImage(value);
    formik.setFieldValue('photo', value);
  };

  const handleReset = () => {
    formik.handleReset();
    formik.setFieldValue(
      'patient_extra_fields',
      extraFields.map(field => ({
        extra_field_id: field.id,
        value: '',
        is_active: true,
      }))
    );
  }

  const antiquity = useMemo(() => CalculateYears(formik.values.hire_date), [
    formik.values.hire_date,
  ]);

  const age = useMemo(() => CalculateYears(formik.values.birth_date), [
    formik.values.birth_date,
  ]);

  return (
    <div className="app-medicalcare-container">
      <MedicalCareModalComponent
        handleToggleModal={handleToggleModal}
        showModal={showModal}
        search={search}
      />
      <form onSubmit={formik.handleSubmit}>
        <div className="d-flex flex-row flex-nowrap gap-2">
          <div className="col-6 col-md-6">
            <div className="app-medicalcare-title">
              <span className="bold">Pacientes - </span> Busqueda Rapida
            </div>
            <div className="col-md-12 col-sm-12">
              <div className="row d-wrap">
                <div className="mt-3 ">
                  <div className="col-12 col-md-12 col-sm-12 app-form-group">
                    <label
                      className="form-label fw-bold text-secondary text-left fs-5"
                      htmlFor="number_id"
                    >
                      * Número de ID:
                    </label>
                    <input
                      type="text"
                      id="number_id"
                      name="number_id"
                      className={`form-control ${formik.touched.number_id && formik.errors.number_id ? 'is-invalid' : ''}`}
                      placeholder="Escriba su número de documento de identidad"
                      value={formik.values.number_id}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      onKeyDown={ToUpperCase}
                      required
                    />
                  </div>
                  <div className="invalid-feedback app-form-error">
                    {formik.touched.number_id &&
                      <span>
                        {formik.errors.number_id}
                      </span>}
                  </div>
                  <div className="col-12 col-md-12 col-sm-12 app-form-group">
                    <label
                      className="form-label fw-bold text-secondary text-left fs-5"
                      htmlFor="paternal_surname"
                    >
                      * Apellido Paterno:
                    </label>
                    <input
                      type="text"
                      id="paternal_surname"
                      name="paternal_surname"
                      className={`form-control ${formik.touched.paternal_surname && formik.errors.paternal_surname ? 'is-invalid' : ''}`}
                      placeholder="Escriba su apellido paterno"
                      value={formik.values.paternal_surname}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      required
                    />
                  </div>
                  <div className="invalid-feedback app-form-error">
                    {formik.touched.paternal_surname &&
                      <span>
                        {formik.errors.paternal_surname}
                      </span>}
                  </div>
                  <div className="col-12 col-md-12 col-sm-12 app-form-group">
                    <label
                      className="form-label fw-bold text-secondary text-left fs-5"
                      htmlFor="maternal_surname"
                    >
                      * Apellido Materno:
                    </label>
                    <input
                      type="text"
                      id="maternal_surname"
                      name="maternal_surname"
                      className={`form-control ${formik.touched.maternal_surname && formik.errors.maternal_surname ? 'is-invalid' : ''}`}
                      placeholder="Escriba su apellido materno"
                      value={formik.values.maternal_surname}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      required
                    />
                  </div>
                  <div className="invalid-feedback app-form-error">
                    {formik.touched.maternal_surname &&
                      <span>
                        {formik.errors.maternal_surname}
                      </span>}
                  </div>
                  <div className="col-12 col-md-12 col-sm-12 app-form-group">
                    <label
                      className="form-label fw-bold text-secondary text-left fs-5"
                      htmlFor="first_name"
                    >
                      * Primer Nombre:
                    </label>
                    <input
                      type="text"
                      id="first_name"
                      name="first_name"
                      className={`form-control ${formik.touched.first_name && formik.errors.first_name ? 'is-invalid' : ''}`}
                      placeholder="Escriba su primer nombre"
                      value={formik.values.first_name}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      required
                    />
                  </div>
                  <div className="invalid-feedback app-form-error">
                    {formik.touched.first_name &&
                      <span>
                        {formik.errors.first_name}
                      </span>}
                  </div>
                  <div className="col-12 col-md-12 col-sm-12 app-form-group">
                    <label
                      className="form-label fw-bold text-secondary text-left fs-5"
                      htmlFor="second_name"
                    >
                      Segundo Nombre:
                    </label>
                    <input
                      className='form-control'
                      type="text"
                      id="second_name"
                      name="second_name"
                      placeholder="Escriba su segundo nombre"
                      value={formik.values.second_name}
                      onChange={formik.handleChange}
                    />
                  </div>
                  <div className="col-12 col-sm-auto col-md-auto app-form-group">
                    <label
                      className="form-label fw-bold text-secondary text-left fs-5"
                      htmlFor="birth_date"
                    >
                      * F. Nacimiento:
                    </label>
                    <input
                      type="date"
                      id="birth_date"
                      name="birth_date"
                      className={`form-control ${formik.touched.birth_date && formik.errors.birth_date ? 'is-invalid' : ''}`}
                      value={formik.values.birth_date}
                      onChange={formik.handleChange}
                      required
                    />
                  </div>
                  <div className="invalid-feedback app-form-error">
                    {formik.touched.birth_date &&
                      <span>
                        {formik.errors.birth_date}
                      </span>}
                  </div>
                  <div className="col-12 col-md-auto col-md-auto app-form-group">
                    <label
                      className="form-label fw-bold text-secondary text-left fs-5"
                      htmlFor="age"
                    >
                      Edad:
                    </label>
                    <input
                      className='form-control'
                      type="text"
                      id="age"
                      name="age"
                      placeholder="Edad"
                      value={age}
                      readOnly
                      disabled
                    />
                  </div>
                  <div className="d-flex flex-row justify-evelyn gap-5">
                    <div className="col-12 col-md-8 col-sm-8 app-form-group mr-3">
                      <label
                        className="form-label fw-bold text-secondary text-left fs-5"
                        htmlFor="sexo"
                      >
                        * Sexo:
                      </label>
                      <select
                        id="gender"
                        name="gender"
                        className={`form-select ${formik.touched.gender && formik.errors.gender ? 'is-invalid' : ''}`}
                        value={formik.values.gender}
                        onChange={formik.handleChange}
                        required={true}
                      >
                        <option value="">seleccione una opción</option>
                        <option value="male">Masculino</option>
                        <option value="female">Femenino</option>
                        <option value="other">Otro</option>
                      </select>
                    </div>
                    <div className="col-12 col-md-4 col-sm-4">
                      <div className="form-check form-switch mt-2">
                        <input
                          className="form-check-input fs-5"
                          type="checkbox"
                          role="switch"
                          id="is_active"
                          name="is_active"
                          checked={formik.values.is_active}
                          value={formik.values.is_active}
                          onChange={formik.handleChange}
                        />
                        <label
                          className="form-check-label text-left fw-bold text-secondary fs-5"
                          htmlFor="is_active"
                        >
                          Activo
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="app-medicalcare-button-group mt-2">
                    <div>
                      <button
                        type="button"
                        className="btn app-medicalcare-button"
                        onClick={handleSearch}
                      >
                        Buscar Paciente
                      </button>
                    </div>
                    <div>
                      {isPermitted(permissions.medical_care.patients.create) &&
                        <button
                          type="submit"
                          className="btn app-medicalcare-button"
                        >
                          Crear Paciente
                        </button>}
                    </div>
                  </div>
                  <div className="app-form-button">
                    <button
                      type="reset"
                      onClick={handleReset}
                      className="small-x"
                    >
                      Limpiar Busqueda
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-6 col-md-6">
            <div className="d-flex flex-row flex-nowrap">
              <div className="col-auto" onClick={handleToggle}>
                <div className="app-divider">
                  <div className="app-divider-container">
                    <div className="">
                      <img
                        src={show ? DoubleRowLeftIcon : DoubleRowRightIcon}
                        alt="double row left"
                        width="16px"
                        height="16px"
                      />
                    </div>
                    <div className="">
                      <span className="label">
                        {`${show ? 'Cerrar' : 'Mostrar'} Busqueda Avanzada - Opcional`}
                      </span>
                    </div>
                    <div className="">
                      <img
                        src={show ? DoubleRowLeftIcon : DoubleRowRightIcon}
                        alt="double row left"
                        width="16px"
                        height="16px"
                      />
                    </div>
                  </div>
                </div>
              </div>
              {show &&
                <div className="col-11 app-medicalcare-form-advanced">
                  <div className="d-flex">
                    <div className="col-md-8 col-sm-8">
                      <div className="col-12 col-md-12 col-sm-12 app-form-group">
                        <label
                          className="form-label fw-bold text-secondary text-left fs-5"
                          htmlFor="hire_date"
                        >
                          Fecha Ingreso:
                        </label>
                        <input
                          type="date"
                          id="hire_date"
                          name="hire_date"
                          value={formik.values.hire_date}
                          onChange={formik.handleChange}
                        />
                      </div>
                      <div className="col-12 col-md-12 col-sm-12 app-form-group">
                        <label
                          className="form-label fw-bold text-secondary text-left fs-5"
                          htmlFor="antiquity"
                        >
                          Antigüedad:
                        </label>
                        <input
                          type="text"
                          id="antiquity"
                          name="antiquity"
                          placeholder="Escriba su Antigüedad"
                          value={antiquity}
                          readOnly
                          disabled
                        />
                      </div>
                    </div>
                    <div className="col-md-4 col-sm-4">
                      <div className="app-medicalcare-photo">
                        <img
                          src={image ? image : UserPhoto}
                          alt="User Profile"
                          width="128px"
                          height="128px"
                        />
                        <CameraComponent
                          show={showCapture}
                          setShow={setShowCapture}
                          image={image}
                          setImage={handlePhoto}
                        />
                      </div>
                    </div>
                  </div>
                  {extraFields.map((field, index) => (
                    <div
                      key={index}
                      className="col-12 col-md-12 col-sm-12 app-form-group"
                    >
                      <label
                        className="form-label fw-bold text-secondary text-left fs-5"
                        htmlFor="job_position"
                      >
                        {field.field_label}:
                      </label>
                      <input
                        type={field.field_type}
                        id={`patient_extra_fields[${index}].value`}
                        name={`patient_extra_fields[${index}].value`}
                        placeholder={field.field_placeholder}
                        value={formik.values.patient_extra_fields[index]?.value}
                        onChange={formik.handleChange}
                      />
                    </div>
                  ))}
                </div>}
            </div>
          </div>
        </div>
      </form>
      {process.env.NODE_ENV === 'development' && <CalendarComponent />}
    </div>
  );
};

export default MedicalCareComponent;
