import React from "react";

const FormatTitle = (value) => {
  const regex = /((<[\w]{1,}>)|(<\/[\w]{1,}>))/g;
  return value
    .replaceAll("<b>", "<s><b>")
    .replaceAll("</b>", "</b><s>")
    .split("<s>")
    .map((item, index) => {
      return regex.exec(item) ? (
        <span key={index} className="bold">
          {item.replaceAll(regex, "")}
        </span>
      ) : (
        <span key={index}>{item}</span>
      );
    });
};

export default FormatTitle;
