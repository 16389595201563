import React from "react";
import { Form } from "react-bootstrap";

const ReportesComponent = () => {
  return (
    <Form>
      <fieldset>
        <legend>Busca los datos para el reporte que necesites</legend>
        <div className="row d-flex">
          <div className="col-6 col-md-6 col-sm-6">
            <label className="app-form-label">Paciente:</label>
            <input
              className="form-control"
              type="text"
              id="name"
              name="name"
              placeholder="Escribe el nombre del peciente"
            />
          </div>
          <div className="col-6 col-md-6 col-sm-6">
            <label className="app-form-label">Formularios</label>
            <select className="form-select">
              <option value="0">Todos los formularios</option>
              {/* {db.formularios.map((form, index) => (
                <option key={index} value={form.id}>
                  {form.form_name}
                </option>
              ))} */}
            </select>
          </div>
          <div className="col-6 col-md-6 col-sm-6">
            <label className="app-form-label">Fecha:</label>
            <input
              className="form-control"
              type="date"
              id="name"
              name="name"
              placeholder="Escribe el nombre del peciente"
            />
          </div>
        </div>
      </fieldset>
    </Form>
  );
};

export default ReportesComponent;
