import React, { useContext, useState } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import esLocale from "@fullcalendar/core/locales/es";
import Calendar from "react-calendar";
import { Modal } from "react-bootstrap";
import { useFormik } from "formik";
import * as yup from "yup";
import DateFormatPersonalized from "../../utils/DateFormatPersonalized";
import SessionContext from "../../context/SessionContext";
import GenerateTimeIntervals from "../../utils/GenerateTimeIntervals";
import "react-calendar/dist/Calendar.css";
import "./Calendar.css";

const CalendarComponent = () => {
  const [state] = useState({ weekendsVisible: true, currentEvents: [] });
  const [initialDate, setDate] = useState(new Date());
  const [events, setEvents] = useState([]);
  const [intervals, setIntervals] = useState(GenerateTimeIntervals());
  const [show, setShow] = useState(false);
  const {
    session: { schedules },
  } = useContext(SessionContext);

  const formik = useFormik({
    initialValues: {
      patient_id: 0,
      protocol_id: 0,
      doctor_id: 0,
      start_time: "09:00:00",
      end_time: "09:00:00",
      location: "",
      is_assigned: false,
    },
    validationSchema: yup.object({}),
    onSubmit: (values) => {
      toggleModal();
    },
  });

  const toTime = (value) => DateFormatPersonalized(value, "HH:mm:ss");

  const handleDate = (value) => {
    formik.setValues({
      ...formik.values,
      start_time: toTime(value?.start),
      end_time: toTime(value?.end),
    });

    toggleModal();
  };

  const toggleModal = () => setShow(!show);

  return (
    <div className="app-calendar">
      <Modal show={show} className="app-calendar" centered>
        <Modal.Header bsPrefix="app-calendar-modal-header">
          <Modal.Title>Cita</Modal.Title>
          <button className="btn" onClick={toggleModal}>
            <i className="bi bi-x"></i>
          </button>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={formik.handleSubmit}>
            <h1 className="text-center fs-4 fw-bold mb-3">
              Consulta No Programada
            </h1>
            <div className="d-flex gap-2 mb-2">
              <div className="col">
                <div className="d-flex flex-row justify-around  align-items-center gap-2">
                  <label htmlFor="start_time" className="form-label">
                    <i className="bi bi-clock"></i>
                  </label>
                  <select
                    name="start_time"
                    id="start_time"
                    className="form-select"
                    value={formik.values.start_time}
                    onChange={formik.handleChange}
                    required
                  >
                    {intervals.map((interval, index) => (
                      <option key={index} value={interval}>
                        {interval}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="col">
                <div className="d-flex flex-row justify-around align-items-center gap-2">
                  <label htmlFor="end_time" className="form-label">
                    a
                  </label>
                  <select
                    id="end_time"
                    name="end_time"
                    className="form-select"
                    value={formik.values.end_time}
                    onChange={formik.handleChange}
                    required
                  >
                    {intervals.map((interval, index) => (
                      <option key={index} value={interval}>
                        {interval}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
            <div className="col mb-3">
              <div className="d-flex flex-row justify-around  align-items-center gap-2">
                <label htmlFor="location" className="form-label">
                  <i className="bi bi-geo-alt"></i>
                </label>
                <input
                  className="form-control"
                  type="text"
                  name="location"
                  id="location"
                  placeholder="Escriba la dirección"
                  value={formik.values.location}
                  onChange={formik.handleChange}
                  required
                />
              </div>
            </div>
            <div className="d-flex flex-col flex-wrap justify-content-center align-items-start">
              <div className="col-md-12 col-sm-12">
                <div className="float-start">
                  <div className="form-check form-switch ms-3 mb-3">
                    <label
                      className="form-check-label fs-5"
                      htmlFor="is_assigned"
                    >
                      Cita No Programada
                    </label>
                    <input
                      className="form-check-input fs-5 text-danger"
                      type="checkbox"
                      role="switch"
                      id="is_assigned"
                      value={formik.values.is_assigned}
                      onChange={formik.handleChange}
                      required
                    />
                  </div>
                </div>
              </div>
              <div className="d-flex flex-column justify-content-center gap-2">
                <div className="row">
                  <button className="btn" type="submit">
                    Asignar
                  </button>
                </div>
                <div className="row">
                  <button
                    className="btn btn-outline"
                    type="reset"
                    onClick={toggleModal}
                  >
                    Cancelar
                  </button>
                </div>
              </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>
      <div className="app-calendar-options">
        <div className="app-calendar-options-items">
          <div className="list">
            <div className="title">
              <span>Agenda - Estatus Citas</span>
            </div>
            <div className="subtitle">
              <span>
                Citas Programadas:&nbsp;
                <span className="counter">00 Pacientes</span>
              </span>
            </div>
            <div className="subtitle">
              <span>
                Citas Prioritarias:&nbsp;
                <span className="counter">00 Pacientes</span>
              </span>
            </div>
          </div>
        </div>
        <div className="app-calendar-options-items">
          <div>
            <Calendar onChange={setDate} value={initialDate} />
          </div>
          <div className="list-status">
            <div className="status">
              <div className="circle one"></div>
              <div className="label">Disponible</div>
            </div>
            <div className="status">
              <div className="circle two"></div>
              <div className="label">Cita Asignada</div>
            </div>
            <div className="status">
              <div className="circle three"></div>
              <div className="label">Cita Prioritaria</div>
            </div>
            <div className="status">
              <div className="circle four"></div>
              <div className="label">Fuera de Servicio</div>
            </div>
          </div>
        </div>
      </div>
      <div className="app-calendar-container">
        <FullCalendar
          plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
          headerToolbar={{
            left: "prev, next, today",
            center: "title",
            right: "dayGridMonth,timeGridDay",
          }}
          initialView="dayGridMonth"
          editable={true}
          selectable={true}
          selectMirror={true}
          dayMaxEvents={true}
          locale={esLocale}
          weekends={state.weekendsVisible}
          initialDate={initialDate}
          select={handleDate}
          timeZone="UTC"
          events={events}
        />
      </div>
    </div>
  );
};

export default CalendarComponent;
