const TranslateGender = (gender) => {
    let translate = '';

    switch (gender) {
        case 'male':
            translate = 'Masculino';
            break;

        case 'female':
            translate = 'Femenino';
            break;

        default:
            translate = 'Otro';
    }

    return translate
}

export default TranslateGender;
