import React, { useEffect } from "react";
import ToBoolean from "../../../utils/ToBoolean";

const FieldTextarea = (props) => {
  useEffect(() => {
    return () => { };
  }, []);

  return (
    <div
      className={`${props.field_class} form-group`}
      style={props.field_style?.container}
    >
      <label
        className="form-label"
        htmlFor={props?.field_id}
        style={props.field_style?.label}
      >
        {props?.field_label ? props?.field_label : ""}
      </label>
      <textarea
        id={props.field_id || ""}
        name={props.field_name || ""}
        className="form-control"
        placeholder={props.field_placeholder || ""}
        cols={props.field_cols || ""}
        rows={props.field_rows || ""}
        value={props.value || ""}
        onChange={!props.isPreview ? props.onChange : () => { }}
        required={!props.isPreview ? ToBoolean(props.is_required) : false}
        readOnly={!props.isPreview ? false : true}
        disabled={!props.isPreview ? false : true}
      ></textarea>
    </div>
  );
};

export default FieldTextarea;
