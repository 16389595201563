import dayjs from "dayjs";
import React, { useContext, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useParams } from "react-router";
import FormatTitle from "../../utils/FormatTitle";
import FieldsComponent from "../forms/FieldsComponent";
import ClinicHistoryPatientInformationComponent from "./ClinicHistoryPatientInformationComponent";
import LogoDefault from "../../assets/images/forms/logo_default.svg";
import SwalLoading from "../../utils/SwalLoading";
import SwalSuccess from "../../utils/SwalSuccess";
import SwalError from "../../utils/SwalError";
import * as yup from "yup";
import { useFormik } from "formik";
import { getPatientAppointments } from "../../../services/patients/PatientsService";
import { postAppointment } from "../../../services/patients/AppointmentsService";
import SessionContext from "../../context/SessionContext";
import SwalInformation from "../../utils/SwalInformation";
import permissions from "../../json/route_permission.json";

const ClinicHistoryShowComponent = () => {
    const { id: patientId } = useParams();
    const [appointmentHistory, setAppointmentHistory] = useState([]);
    const [show, setShow] = useState(false);
    const [forms, setForms] = useState([]);
    const [isPreview, setIsPreview] = useState(false);
    const [isReload, setIsReload] = useState(true);
    const { session, isPermitted, closeSession } = useContext(SessionContext);

    useEffect(() => {
        if (isReload) {
            getPatientAppointments(patientId).then((response) => {
                if (response.status === 200) {
                    const data = response.data;
                    setAppointmentHistory(
                        data?.map((appointment) => ({
                            ...appointment,
                            isShow: false,
                            isSelectedAll: false,
                            forms_history: appointment.forms_history.map((form_history) => ({
                                ...form_history,
                                isSelected: false,
                            })),
                        }))
                    );
                    setIsReload(!isReload);
                }
            }).catch((error) => {
                const response = error.response;
                if (response?.status === 401) {
                    closeSession();
                } else if ([400, 404].includes(response.status)) {
                    SwalError({
                        title: "Error",
                        message: response.data.detail,
                    });
                } else {
                    SwalError({
                        title: "Error No Controlador",
                        message: response.data.detail,
                    });
                }
            });
        }

        return () => { };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isReload]);

    const formik = useFormik({
        initialValues: {
            patient_id: patientId,
            protocol_id: null,
            is_active: true,
            forms_history: [],
        },
        validationSchema: yup.object({
            patient_id: yup.number().required(),
            protocol_id: yup.number().required(),
            forms_history: yup.array(
                yup.object({
                    form_id: yup.number().required(),
                    is_active: yup.bool().default(() => true),
                    fields_history: yup.array(
                        yup.object({
                            field_id: yup.number().required(),
                            value: yup.string().required(),
                            is_active: yup.bool().default(() => true),
                        })
                    ),
                })
            ),
            is_active: yup.bool().required(),
        }),
        onSubmit: (values) => {
            SwalLoading({ title: "Guardando Historial Clinico" });
            postAppointment(values)
                .then((response) => {
                    if (response.status === 200) {
                        SwalSuccess({
                            title: "Aclaración",
                            message: "La aclaración ha sido guardada correctamente.",
                        });
                        setIsReload(true);
                        toggleModal();
                    }
                })
                .catch((error) => {
                    const response = error.response;
                    if (response.status === 401) {
                        closeSession();
                    } else if ([400, 404].includes(response.status)) {
                        SwalError({
                            title: "Error",
                            message: response.data.detail,
                        });
                    } else {
                        SwalError({
                            title: "Error No Controlador",
                            message: response.data.detail,
                        });
                    }
                });
        },
    });

    const handleToggle = (value) => {
        setAppointmentHistory((appointmentHistory) =>
            appointmentHistory.map((item) => ({
                ...item,
                isShow: item.id === value.id ? !item?.isShow : item.isShow,
            }))
        );
    };

    const handleSelected = (appointmentId, formId) => {
        setAppointmentHistory(
            appointmentHistory.map((appointment) => {
                if (appointment.id === appointmentId) {
                    appointment.forms_history = appointment?.forms_history.map(
                        (form_history) => {
                            if (formId === form_history.form_id) {
                                form_history.isSelected = !form_history?.isSelected;
                            }
                            return form_history;
                        }
                    );
                }
                return appointment;
            })
        );
    };

    const handleSelectedAll = (appointmentId) => {
        setAppointmentHistory(
            appointmentHistory.map((appointment) => {
                if (appointment.id === appointmentId) {
                    appointment.isSelectedAll = !appointment?.isSelectedAll;
                    appointment.forms_history = appointment?.forms_history.map(
                        (form_history) => {
                            form_history.isSelected = !appointment?.isSelectedAll
                                ? false
                                : true;
                            return form_history;
                        }
                    );
                }
                return appointment;
            })
        );
    };

    const setupFormsHistory = ({
        patient_id,
        protocol_id,
        forms_history,
        is_active,
    }) => ({
        patient_id,
        protocol_id,
        is_active,
        forms_history: [...forms_history].map(
            ({ form_id, fields_history, is_active }) => {
                fields_history = fields_history.map(
                    ({ field_id, value, is_active }) => ({ field_id, value, is_active })
                );
                return { form_id, fields_history, is_active };
            }
        ),
    });

    const handlePreview = (appointment, form_id) => {
        setForms(
            appointment.forms_history.filter(
                (form_history) => form_history.form_id === form_id
            )
        );
        formik.setValues(setupFormsHistory(appointment));
        toggleModal();
        setIsPreview(true);
    };

    const handleClarification = (appointment) => {
        const forms = appointment.forms_history.filter(
            (form_history) => form_history.isSelected === true
        );
        if (forms.length > 0) {
            formik.setValues(setupFormsHistory(appointment));
            setIsPreview(false);
            setForms(forms);
            toggleModal();
        } else {
            SwalInformation({
                title: "Información",
                message: "Debes seleccionar al menos un formulario",
            });
        }
    };

    const toggleModal = () => setShow(!show);

    const handleCustom = (value, formIndex, fieldIndex) => handleChange(JSON.stringify(value), formIndex, fieldIndex);


    const handleChange = (value, formIndex, fieldIndex) => {
        const { forms_history } = formik.values;
        forms_history[formIndex].fields_history[fieldIndex].value =
            JSON.stringify(value);
        formik.setFieldValue("forms_history", forms_history);
    };

    const createdDate = ({ created_at }) => dayjs(created_at).locale(process.env.REACT_APP_LOCATE).format("DD/MM/YYYY HH:mm:ss");


    const fullTitle = (appointment) => `${createdDate(appointment)} Medicina General - ${appointment?.protocol.name}`;


    const getFormIndex = (formId) => {
        const { forms_history } = formik.values;
        return forms_history.findIndex((form_history) => form_history.form_id === formId);
    };

    const getFieldIndex = (formIndex, fieldId) => {
        const { fields_history } = formik.values.forms_history[formIndex];
        return fields_history.findIndex(
            (field_history) => field_history.field_id === fieldId
        );
    };

    const isCheckAll = (appointment) => !appointment.forms_history.some((form_history) => form_history.isSelected === false);


    return (
        <div className="app-patientcare-form">
            <Modal show={show} onHide={toggleModal} size="xl">
                <Modal.Body>
                    <div className="col-12 col-md-12 col-sm-12 m-0">
                        <div className="app-forms-preview-title rounded">
                            <span> VISTA PREVIA </span>
                            <span className="app-forms-preview-close-button">
                                <button
                                    type="button"
                                    className="d-block m-auto text-center"
                                    onClick={toggleModal}
                                >
                                    <span className="bi bi-x"></span>
                                </button>
                            </span>
                        </div>
                    </div>
                    <form onSubmit={formik.handleSubmit}>
                        {forms.map((form) => {
                            const formIndex = getFormIndex(form.form_id);
                            const isShowSection = (section) => ({
                                display: section?.section_show ? "" : "none",
                            });

                            return (
                                <div
                                    key={form.id}
                                    className="border rounded pb-4 shadow-sm mt-4"
                                >
                                    <div className="app-forms-header">
                                        <div className="left">
                                            <span className="bold"> {form?.form_name} </span>
                                        </div>
                                        <div className="right">
                                            <img src={LogoDefault} alt="Logo Empresa" />
                                            <span className="bold"> {session?.username} </span>
                                            <span> Médico General </span>
                                        </div>
                                    </div>
                                    {form.form?.form_sections?.map((section) => (
                                        <div key={section.id} className={section?.section_class}>
                                            <div className="app-forms-section-actions">
                                                <div className="app-forms-section-title">

                                                    {FormatTitle(section?.section_name)}
                                                </div>
                                                {section?.section_actions && (
                                                    <div className="app-forms-section-tools">
                                                        <div className="tool"> </div>
                                                    </div>
                                                )}
                                            </div>
                                            <div
                                                className="row app-forms-section-fields"
                                                style={isShowSection(section)}
                                            >

                                                {section?.section_fields.map((field) => {
                                                    const fieldIndex = getFieldIndex(formIndex, field.id);

                                                    return (
                                                        <FieldsComponent
                                                            key={field.id}
                                                            {...field}
                                                            field_id={`forms_history[${formIndex}].fields_history[${fieldIndex}].value`}
                                                            field_name={`forms_history[${formIndex}].fields_history[${fieldIndex}].value`}
                                                            isPreview={isPreview}
                                                            onChange={formik.handleChange}
                                                            onCustom={(e) => handleCustom(e, fieldIndex)}
                                                            value={
                                                                formik.values.forms_history[formIndex]
                                                                    ?.fields_history[fieldIndex]?.value
                                                            }
                                                        />
                                                    );
                                                })}
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            );
                        })}
                        <div className="d-flex flex-direction-row justify-content-center align-items-center mt-4">
                            <button
                                type="button"
                                className="app-button bg-green-ryb"
                                onClick={toggleModal}
                            >

                                {`Cerrar ${!isPreview ? "Aclaración" : "Vista Previa"}`}
                            </button>
                            {!isPreview && (
                                <button type="submit" className="app-button bg-green-ryb">
                                    Guardar Aclaración
                                </button>
                            )}
                        </div>
                    </form>
                </Modal.Body>
            </Modal>
            <div className="app-clinichistory-form-header">
                <div className="app-clinichistory-form-header-title">
                    <span className="bold"> Datos del Paciente </span>
                </div>
            </div>
            <div className="row d-wrap">
                <ClinicHistoryPatientInformationComponent />
            </div>
            {process.env.NODE_ENV === "development" && (
                <div>
                    <button className="app-button small"> Agendar Cita </button>
                </div>
            )}
            <div className="app-clinichistory-table">
                <div className="app-clinichistory-table-header">
                    <div className="app-clinichistory-table-header-title">
                        Resultados de busqueda Historia Clinica
                    </div>
                    <div className="app-clinichistory-table-header-options">

                        <span> Opciones </span>
                    </div>
                </div>
                <div className="app-clinichistory-table-body">
                    {appointmentHistory.map((appointment, index) => {
                        return (
                            <div key={index}>
                                <div className="app-clinichistory-table-body-row">
                                    <div className="title">
                                        <button
                                            className="button"
                                            onClick={() => handleToggle(appointment)}
                                        >
                                            <span> {fullTitle(appointment)} </span>
                                            <span>
                                                <i
                                                    className={`bi bi-chevron-double-${appointment.isShow ? "up" : "down"
                                                        }`}
                                                ></i>
                                            </span>
                                        </button>
                                    </div>
                                    {appointment.isShow &&
                                        isPermitted(permissions.clinic_history.edit)
                                        && (<div className="options">
                                            <button className="button">
                                                <span className="icon">
                                                    <i className="bi bi-lightbulb"> </i>
                                                </span>
                                                <span
                                                    className="label"
                                                    onClick={() => handleClarification(appointment)}
                                                >
                                                    Agregar Aclaración
                                                </span>
                                            </button>
                                            {process.env.NODE_ENV === "development" && (
                                                <button className="button">
                                                    <span className="icon">
                                                        <i className="fa-solid fa-print"> </i>
                                                    </span>
                                                    <span className="label"> Imprimir </span>
                                                </button>
                                            )}
                                        </div>
                                        )}
                                </div>
                                <div
                                    className="app-clinichistory-table-body-box"
                                    style={{
                                        display: appointment?.isShow ? "block" : "none",
                                        height: appointment?.isShow ? "auto" : "0px",
                                    }}
                                >
                                    <div className="row">
                                        <div className="col-1 col-md-1 col-sm-1">
                                            <span> Todos </span>
                                            <span>
                                                <button
                                                    className={`button${isCheckAll(appointment) ? " checked" : ""
                                                        }`}
                                                    onClick={() => handleSelectedAll(appointment.id)}
                                                ></button>
                                            </span>
                                        </div>
                                        <div className="col-3 col-md-3 col-sm-3 text-left">

                                            Tipo de Nota
                                        </div>
                                        <div className="col-2 col-md-2 col-sm-2 text-left">
                                            Fecha de Creación
                                        </div>
                                        <div className="col-2 col-md-2 col-sm-2">

                                            Vista Previa
                                        </div>
                                    </div>
                                    {appointment?.forms_history?.map((form, index) => (
                                        <div key={index} className="row">
                                            <div className="col-1 col-md-1 col-sm-1">
                                                <button
                                                    className={`button${form?.isSelected ? " checked" : ""
                                                        }`}
                                                    onClick={() =>
                                                        handleSelected(appointment.id, form?.form_id)
                                                    }
                                                ></button>
                                            </div>
                                            <div className="col-3 col-md-3 col-sm-3">
                                                <span className="label"> {form?.form?.form_name} </span>
                                            </div>
                                            <div className="col-2 col-md-2 col-sm-2">
                                                <span className="label"> {createdDate(form)} </span>
                                            </div>
                                            <div className="col-2 col-md-2 col-sm-2">
                                                <span>
                                                    <button
                                                        type="button"
                                                        className="icon-button"
                                                        onClick={() =>
                                                            handlePreview(appointment, form.form_id)
                                                        }
                                                    >
                                                        <span className="bi bi-eye"></span>
                                                    </button>
                                                </span>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
};

export default ClinicHistoryShowComponent;
