import React from "react";
import DayOfWeek from "../../../utils/DayOfWeek";

const DoctorsScheduleComponent = ({ formik }) => {

  return (
    <div className="row d-flex app-doctors-schedule">
      <div className="col">
        <div className="col-12 app-table-title fs-4 m-1">
          <label>Horario de Trabajo:</label>
        </div>
        <div className="col-12 app-doctors-schedule-items white m-0">
          <div className="row d-flex">
            {formik.values?.schedules?.map((schedule, index) => (
              <div key={index} className="col">
                <h4 className="schedule-title">
                  {DayOfWeek(index)}
                </h4>
                <div className="form-group">
                  <label
                    htmlFor={`schedules[${index}].start_time`}
                    className="form-label time"
                  >
                    Hora Inicial:
                  </label>
                  <input
                    type="time"
                    className="form-control"
                    id={`schedules[${index}].start_time`}
                    name={`schedules[${index}].start_time`}
                    value={schedule.start_time}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    required
                  />
                </div>
                <div className="form-group">
                  <label htmlFor={`week_day${index}`} className="form-label time">
                    Hora Final:
                  </label>
                  <input
                    type="time"
                    className="form-control"
                    id={`schedules[${index}].end_time`}
                    name={`schedules[${index}].end_time`}
                    value={schedule.end_time}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    required
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div >
  );
};

export default DoctorsScheduleComponent;
