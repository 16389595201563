import dayjs from "dayjs";
import "dayjs/locale/es";

const DateFormatPersonalized = (value = null, format = null) => {
  return dayjs(value ? value : new Date())
    .locale(process.env.REACT_APP_LOCATE)
    .format(format ? format : "YYYY-MM-DD");
};

export default DateFormatPersonalized;
