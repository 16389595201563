import React from "react";

const FieldInput = (props) => {

  return (
    <div className={`${props?.field_class} form-group`}>
      <label
        className="form-label"
        htmlFor={props?.field_id}

      >
        {props?.field_label ? props?.field_label : ""}
      </label>
      <input
        type={props?.field_type}
        id={props?.field_id}
        name={props?.field_name}
        className="form-control"
        placeholder={props?.field_placeholder}
        min={props?.field_min}
        max={props?.field_max}
        defaultValue=""
        readOnly={props?.is_readonly}
        required={props?.is_required}
        autoComplete="off"
      />
    </div>
  );
};

export default FieldInput;
