import dayjs from "dayjs";
import weekday from "dayjs/plugin/weekday";
import "dayjs/locale/es";
import PropTypes from "prop-types";
import DayOfWeek from "./DayOfWeek";
import ToCapitalize from "./ToCapitalize";

const DateFormat = (value = null) => {
  dayjs.extend(weekday);
  const date = dayjs(value ? value : new Date()).locale("es");
  const dayOfMonth = date.format("DD");
  const dayOfWeek = DayOfWeek(date.weekday() + 1);
  const month = ToCapitalize(date.format("MMMM"));
  const year = date.format("YYYY");
  return `${dayOfWeek} ${dayOfMonth} de ${month} de ${year}`;
};

DateFormat.propTypes = {
  value: PropTypes.string,
};

export default DateFormat;
