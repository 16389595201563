import React from "react";
import Logo from "../../assets/images/login/logo.svg";
import LoginComponent from "../../components/login/LoginComponent";
import "./Login.css";

const Login = () => {

  const year = new Date().getUTCFullYear();

  return (
    <div className="app-login">
      <div className="app-login-container">
        <div className="app-login-logo">
          <img src={Logo} alt="logo" width="167px" height="166px" />
        </div>
        <div className="app-login-form">
          <LoginComponent />
        </div>
        <div className="app-login-footer">
          <div>By HG Consulting&copy;{year}</div>
          <div>Terminos & Condiciones</div>
        </div>
      </div>
      <div className="app-login-background"></div>
    </div>
  );
};

export default Login;
