import React from 'react'

const IsActiveIcon = (is_active) => {
    return is_active ? (
        <i className="bi bi-check-circle-fill text-green-ryb"></i>
    ) : (
        <i className="bi bi-x-circle-fill text-danger"></i>
    );
}

export default IsActiveIcon
