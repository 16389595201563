import Swal from "sweetalert2";
import PropTypes from "prop-types";

const SwalConfirm = (props) => {
  return Swal.fire({
    title: props?.title,
    html: props?.message,
    showCancelButton: true,
    showCloseButton: true,
    confirmButtonText: "Si, Confirmar",
    cancelButtonText: "No, Cancelar",
    closeButtonHtml: `<div class="swal2-header">
                        <div class="swal2-header-title"></div>
                        <div class="swal2-header-close">
                          <button type="button" aria-label="Close this dialog">x</button>
                        </div>
                    </div>`,
  });
};

export default SwalConfirm;

SwalConfirm.propTypes = {
  title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
};
