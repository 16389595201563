import Swal from "sweetalert2";
import PropTypes from "prop-types";

const SwalError = (props) => {
  return Swal.fire({
    title: props?.title,
    html: `<div class="error">
                <span class="label">${props?.message}</span>
            </div>`,
    allowEscapeKey: false,
    allowOutsideClick: false,
    confirmButtonText: "Confirmar",
  });
};

export default SwalError;

SwalError.propTypes = {
  title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
};
