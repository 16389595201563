import React, { useContext, useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { NavLink, useNavigate } from "react-router-dom";
import PaginationComponent from "../../pagination/PaginationComponent";
import Pagination from "../../../utils/Pagination";
import PaginationCounter from "../../../utils/PaginationCounter";
import SortArrayById from "../../../utils/SortArrayById";
import SwalLoading from "../../../utils/SwalLoading";
import SwalSuccess from "../../../utils/SwalSuccess";
import SwalError from "../../../utils/SwalError";
import { deleteUserById, getUsers } from "../../../../services/settings/users/UsersService";
import { postRequestPassword } from "../../../../services/auth/AuthService";
import SessionContext from "../../../context/SessionContext";
import dayjs from "dayjs";
import "dayjs/locale/es";
import IsActiveIcon from "../../../utils/IsActiveIcon";

const ListUsersComponent = (props) => {
  const [data, setData] = useState([]);
  const [current, setCurrent] = useState(1);
  const [pages, setPages] = useState([]);
  const [rows, setRows] = useState(10);
  const [isReload, setIsReload] = useState(true);
  const { isPermitted, closeSession } = useContext(SessionContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (isReload) {
      getUsers()
        .then((response) => {
          if (response.status === 200) {
            setCurrent(1);
            setData(SortArrayById(response.data));
            setPages(Pagination(SortArrayById(response.data), rows));
            setIsReload(!isReload);
          }
        })
        .catch((error) => {
          const response = error.response;
          if (response.status === 401) {
            closeSession();
          } else if ([400, 404].includes(response.status)) {
            SwalError({ title: "Error", message: response.data.detail })
          } else {
            SwalError({ title: "Error no Controlado", message: JSON.stringify(response.data.detail) })
          }
        });
    }
    return () => { };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isReload]);

  useEffect(() => {
    setCurrent(1);
    setPages(Pagination(data, rows));
    return () => { };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rows]);

  const handleCreate = () => {
    navigate(`crear`);
  };

  const handleGoTo = (value) => {
    navigate(`${value}`);
  };

  const handleBack = () => {
    navigate(-1);
  };

  const handleStatus = (user) => {
    const title = `${user.is_active ? "Inactivando" : "Activando"} Usuario`;
    SwalLoading({ title: title });

    deleteUserById(user.id)
      .then((response) => {
        if (response.status === 204) {
          SwalSuccess({
            title: title,
            message: `El usuario ha sido ${user.is_active ? "Inactivado" : "Activado"} correctamente`,
          });
          setIsReload(!isReload);
        }
      })
      .catch((error) => {
        const response = error.response;
        if (response.status === 401) {
          closeSession();
        } else if ([404].includes(response.status)) {
          SwalError({ title: "Error", message: response.data.detail })
        } else {
          SwalError({ title: "Error no Controlado", message: JSON.stringify(response.data.detail) })
        }
      });
  };

  const handleSearch = (event) => {
    const value = event.target.value.toLowerCase();
    const array = [...data];

    if (value !== "") {
      setCurrent(1);
      setPages(Pagination(filter(array, value), rows));
    } else {
      setCurrent(1);
      setPages(Pagination(data, rows));
    }
  };

  const filter = (array, value) => {
    return array.filter((item) => {

      return (
        (item?.id === parseInt(value)) |
        item?.username?.toLowerCase().includes(value.trim()) |
        item?.email?.toLowerCase().includes(value.trim()) |
        item?.roles?.some((role) =>
          role?.name.toLowerCase().includes(value.trim())
        )
      );
    });
  };

  const handleChangePassword = (email) => {
    SwalLoading({ title: "Restableciendo contraseña" });
    postRequestPassword(email)
      .then((response) => {
        if (response.status === 200) {
          SwalSuccess({
            title: "Restableciendo contraseña",
            message: response.data.message
          });
        }
      })
      .catch((error) => {
        const response = error.response;
        if (response.status === 400) {
          SwalError({
            title: "Error",
            message: response.data.detail,
          });
        } else {
          SwalError({
            title: "Error No Controlado",
            message: JSON.stringify(response.data.detail),
          });
        }
      });
  };

  const profileNames = (user) => user?.profiles?.map((user_profile) => user_profile.profile.name);

  const lastSession = (user) => user.last_session ? dayjs(user.last_session)
    .locale("es")
    .format("DD/MMMM/YYYY hh:mm:ss a") : '';

  return (
    <div>
      <div className="app-container-actions">
        <div className="app-container-actions-label">
          <div className="app-form-search">
            <label className="app-form-label" htmlFor="search">
              Buscar
            </label>
            <input
              type="search"
              className="form-control"
              name="search"
              id="search"
              placeholder="Buscar usuario"
              onChange={handleSearch}
            />
          </div>
        </div>
        <div className="app-container-actions-button">
          {isPermitted(["create_user"]) &&
            <button className="app-button" onClick={handleCreate}>
              Nuevo Usuario
            </button>
          }
          {isPermitted(["select_profile"]) &&
            <button
              className="app-button bg-japanese-laurel"
              onClick={() => handleGoTo("/configuracion/perfiles")}
            >
              Ir a Perfiles
            </button>
          }

        </div>
      </div>
      <div className="boxshadow white">
        <Table striped hover className="round">
          <thead className="bg-green-ryb">
            <tr>
              <th>ID</th>
              <th>Usuario</th>
              <th>Última Sesión</th>
              <th>Estatus</th>
              <th>Correo</th>
              <th>Perfiles Asignados</th>
              {isPermitted(["update_user"]) && <th>Contraseña</th>}
              {isPermitted(["update_user", "delete_user"]) && <th></th>}
            </tr>
          </thead>
          <tbody>
            {pages
              .find((item) => item.id === current)
              ?.items.map((user, index) => (
                <tr key={index}>
                  <td>{user.id}</td>
                  <td>{user.username}</td>
                  <td>
                    {lastSession(user)}
                  </td>
                  <td>
                    {IsActiveIcon(user.is_active)}
                  </td>
                  <td>{user.email}</td>
                  <td>
                    {profileNames(user).join(", ")}
                  </td>
                  {isPermitted(["update_user"]) &&
                    <td>
                      <button
                        className="app-button small fs-6 bg-japanese-laurel m-auto"
                        onClick={() => handleChangePassword(user.email)}
                      >
                        <i className="bi bi-eye"> </i>&nbsp;Cambiar Contraseña
                      </button>
                    </td>
                  }
                  {isPermitted(["update_user", "delete_user"]) &&
                    <td>
                      <button className="btn btn-outline-secondary detalle">
                        Detalles
                        <ul className="menu-button-detalle">
                          {isPermitted(["update_user"]) &&
                            <li>
                              <NavLink
                                to={`${user.id}/editar`}
                                className="nav-link"
                              >
                                Editar Usuario
                              </NavLink>
                            </li>
                          }
                          {isPermitted(["delete_user"]) &&
                            <li onClick={() => handleStatus(user)}>
                              {`${user.is_active ? "Inactivar" : "Activar"} Usuario`}
                            </li>
                          }
                        </ul>
                      </button>
                    </td>
                  }
                </tr>
              ))}
          </tbody>
        </Table>
      </div>
      <div className="app-footer-actions">
        <PaginationCounter data={data} rows={rows} setRows={setRows} />
        <PaginationComponent
          pages={pages}
          current={current}
          setCurrent={setCurrent}
        />
        <div className="app-table-button">
          <button
            className="app-button small bg-green-ryb"
            onClick={handleBack}
          >
            Regresar
          </button>
        </div>
      </div>
    </div>
  );
};

export default ListUsersComponent;
