import axiosService from "../connection/AxiosService";

/** Auth */
export async function postLogin(data = null) {
    if (data) {
      return await axiosService.client.post(`/auth/login`, data);
    }
}

export async function postResedOtpCode(data = null) {
    if (data) {
      return await axiosService.client.post(`/auth/resend_otpcode`, data);
    }
}

export async function postVerifyOtpCode(data = null) {
    if (data) {
      return await axiosService.client.post(`/auth/verify_otpcode`, data);
    }
}

export async function postVerifyEmail(email = null, hashed_token = null) {
    if (email && hashed_token) {
      return await axiosService.client.post(`/auth/verify_email/${email}/${hashed_token}`);
    }
}

export async function postRequestPassword(email = null) {
    if (email) {
      return await axiosService.client.post(`/auth/request_password/${email}`);
    }
}

export async function postChangePassword(email = null, hashed_token = null, data = null) {
    if (email && hashed_token && data) {
      return await axiosService.client.post(`/auth/change_password/${email}/${hashed_token}`, data);
    }
}

export async function postChangeCredentials(data = null) {
    if (axiosService.checkToken()) {
      return await axiosService.client.post(`/auth/change_password`, data);
    }
}

export async function getProfile() {
  if(axiosService.checkToken()){
    return await axiosService.client.get(`/profile`);
  }
}
