import React, { useState, useRef, useEffect } from "react";
import { Modal } from "react-bootstrap";
import PropTypes from "prop-types";
import "./Camera.css";

function CameraComponent(props) {
  const { image, setImage, show, setShow } = props;
  const videoRef = useRef(null);
  const [stream, setStream] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (show) {
      setIsLoading(true);
      navigator.mediaDevices
        .getUserMedia({ video: true })
        .then(async (stream) => {
          pauseMedia();
          changeMediaSource(stream);
          setStream(stream);
          setIsLoading(false);
        })
        .catch((error) => {
          console.error("Unable to access the camera", error);
        });
    }
  }, [show]);

  const handleCapture = () => {
    const canvas = document.createElement("canvas");
    canvas.width = videoRef.current.videoWidth;
    canvas.height = videoRef.current.videoHeight;
    canvas
      .getContext("2d")
      .drawImage(videoRef.current, 0, 0, canvas.width, canvas.height);
    setImage(canvas.toDataURL());
  };

  const handleClose = () => {
    if (stream) {
      stream.getTracks().forEach((track) => {
        track.stop();
      });
      setShow(!show);
    }
  };

  const pauseMedia = () => {
    const video = videoRef.current;
    if (!video.paused) {
      video.pause();
    }
  };

  const changeMediaSource = (srcObject) => {
    try {
      const video = videoRef.current;

      if (!video.paused) {
        video.pause();
      }

      video.srcObject = srcObject;

      video.addEventListener("canplay", () => {
        video.play();
      });

      video.load();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <React.Fragment>
      <button type="button" onClick={() => setShow(!show)}>
        <i className="fa-solid fa-camera text-secondary"></i>
      </button>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        size="lg"
      >
        <Modal.Body>
          {isLoading && (
            <div className="app-camera-loading">
              <span className="spin"></span> Cargando...
            </div>
          )}
          <div className="app-camera">
            <video playsInline={true} muted={true} ref={videoRef} width="100%">
              <source src="" type="video/mp4" />
              Your browser does not support the video tag.
            </video>
            {image && <img src={image} alt="capture" />}
            <div className="app-camera-actions p-1">
              <div>
                <button className="btn" type="button" onClick={handleCapture}>
                  <i className="fa-solid fa-camera"></i> Capturar
                </button>
              </div>
              <div>
                <button className="btn" type="button" onClick={handleClose}>
                  <i className="fa-solid fa-x"></i> Cerrar
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
}

export default CameraComponent;

CameraComponent.propTypes = {
  image: PropTypes.string,
  setImage: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  setShow: PropTypes.func.isRequired,
};
