import axiosService from "../../connection/AxiosService";

export async function getProtocols() {
    if (axiosService.checkToken()) {
        return await axiosService.client.get("/protocols");
    }
}

export async function postProtocol(data = null) {
    if (axiosService.checkToken()) {
        if (data) {
            return await axiosService.client.post("/protocols", data);
        }
    }
}

export async function getProtocolById(protocolId) {
    if (axiosService.checkToken()) {
        return await axiosService.client.get(`/protocols/${protocolId}`);
    }
}

export async function getProtocolFormsById(protocolId) {
    if (axiosService.checkToken()) {
        return await axiosService.client.get(`/protocols/${protocolId}/forms`);
    }
}

export async function putProtocolById(protocolId = null, data = null) {
    if (axiosService.checkToken()) {
        if (protocolId && data) {
            return await axiosService.client.put(`/protocols/${protocolId}`, data);
        }
    }
}

export async function deleteProtocolById(protocolId = null) {
    if (axiosService.checkToken()) {
        if (protocolId) {
            return await axiosService.client.delete(`/protocols/${protocolId}`);
        }
    }
}
