import React, { useContext, useEffect } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import ProtocolsFormComponent from "./ProtocolsFormComponent";
import SwalError from "../../../utils/SwalError";
import { useNavigate, useParams } from "react-router";
import SwalSuccess from "../../../utils/SwalSuccess";
import SwalLoading from "../../../utils/SwalLoading";
import Swal from "sweetalert2";
import { getForms } from "../../../../services/settings/forms/FormService";
import { getProtocolById, putProtocolById } from "../../../../services/settings/protocols/ProtocolsService";
import SessionContext from "../../../context/SessionContext";

const EditProtocolsComponent = (props) => {
  const navigate = useNavigate();
  const initialValues = { name: "", is_active: true, protocol_forms: [] };
  const { id: protocolId } = useParams();
  const { closeSession } = useContext(SessionContext);

  useEffect(() => {
    SwalLoading({ title: "Consultando información" });
    getForms()
      .then((response) => {
        if (response?.status === 200) {
          const data = response.data;
          const forms = setupInitialValues(data);

          getProtocolById(protocolId).then((response) => {
            if (response.status === 200) {

              const protocol = response.data;

              protocol.protocol_forms = forms.map((form) => {

                const protocol_form = protocol.protocol_forms.find((protocol_form) => protocol_form.form_id === form.form_id)

                if (protocol_form) {
                  form.id = protocol_form.id
                  form.is_assigned = true;
                  form.is_required = protocol_form.is_required
                  form.is_active = protocol_form.is_active
                }

                return form;
              });

              formik.setValues(protocol);

              Swal.close();
            }
          }).catch((error) => {
            const response = error.response;
            if (response.status === 401) {
              closeSession();
            } else {
              SwalError({
                title: "Error no Controlado",
                message: JSON.stringify(response.data.detail),
              });
            }
          });
        }
      })
      .catch((error) => {
        const response = error.response;
        if (response.status === 401) {
          closeSession();
        } else {
          SwalError({
            title: "Error no Controlado",
            message: JSON.stringify(response.data.detail),
          });
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: yup.object({
      name: yup
        .string("Describa el tipo de actividad")
        .required("Debe escribir el tipo de actividad"),
      protocol_forms: yup.array(yup.object({
        form_id: yup.number().required(),
        is_required: yup.boolean().required(),
        is_active: yup.boolean().required()
      })).min(1)
    }),
    onSubmit: (values) => {

      const protocol_forms = [...values.protocol_forms]
        .filter((form) => form.is_assigned === true)
        .map(({ name, is_assigned, is_selected, ...rest }) => rest);

      if (protocol_forms.length > 0) {

        SwalLoading({ title: "Actualizando protocolo" });

        const data = { ...values, protocol_forms };

        putProtocolById(protocolId, data)
          .then((response) => {
            if (response.status === 200) {
              SwalSuccess({
                title: "Protocolo de Atención",
                message: "El protocolo ha sido actualizado exitosamente",
              });
              navigate(-1);
            }
          })
          .catch((error) => {
            const response = error.response;
            if (response.status === 401) {
              closeSession();
            } else {
              SwalError({
                title: "Error no Controlado",
                message: JSON.stringify(response.data.detail),
              });
            }
          });
      } else {
        SwalError({
          title: "Error",
          message: "Debe asignar al menos un formulario",
        });
      }
    },
  });

  const setupInitialValues = (array) => {
    return array.map((value) => {
      return {
        id: null,
        form_id: value.id,
        name: value?.form_name,
        protocol_id: parseInt(protocolId),
        is_active: true,
        is_required: false,
        is_assigned: false,
        is_selected: false,
      };
    });
  };

  const handleChange = (value) => formik.setFieldValue("protocol_forms", value)

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="mb-4">
        <div className="row d-flex">
          <div className="col-4 col-md-4 col-sm-4">
            <label htmlFor="name" className="app-form-label">
              Tipo de Actividad
            </label>
            <input
              id="name"
              name="name"
              placeholder="Escriba el tipo de actividad"
              className={`form-control ${formik.touched.name && formik.errors.name ? "is-invalid" : ""
                }`}
              value={formik.values.name}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              required={true}
            />
            {formik.touched.name && formik.errors.name && (
              <div className="d-flex invalid-feedback">
                {formik.errors.name}
              </div>
            )}
          </div>
          <div className="col-4 col-md-4 col-sm-4">
            <label htmlFor="is_active" className="app-form-label">
              Estatus
            </label>
            <select
              id="is_active"
              name="is_active"
              placeholder="Escriba el tipo de actividad"
              className={`form-select ${formik.touched.is_active && formik.errors.is_active ? "is-invalid" : ""
                }`}
              value={formik.values.is_active}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              required={true}
            >
              <option disabled>Seleccione una opción</option>
              <option value={true}>Activo</option>
              <option value={false}>Inactivo</option>
            </select>
            {formik.touched.is_active && formik.errors.is_active && (
              <div className="d-flex invalid-feedback">
                {formik.errors.is_active}
              </div>
            )}
          </div>
        </div>
      </div>
      <div>
        <ProtocolsFormComponent
          state={formik.values.protocol_forms}
          setState={handleChange}
        />
      </div>
      <div className="app-protocols-footer">
        <div>
          <button type="sumbit" className="app-button bg-green-ryb">
            Actualizar Registro
          </button>
        </div>
        <div>
          <button
            type="reset"
            className="app-button default"
            onClick={() => navigate(-1)}
          >
            Cancelar
          </button>
        </div>
      </div>
    </form>
  );
};

export default EditProtocolsComponent;
