import React, { useEffect } from "react";

const FieldSelect = (props) => {
  useEffect(() => {
    return () => { };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      className={`${props?.field_class} form-group`}

    >
      <label className="form-label" htmlFor={props?.field_id}>
        {props?.field_label ? props?.field_label : ""}
      </label>
      <select
        className="form-select"
        id={props?.field_id || ""}
        name={props?.field_name || ""}
        placeholder={props.field_placeholder || ""}
        value={props?.value || ""}
        onChange={!props.isPreview ? !props.onChange : () => { }}
        required={!props.isPreview ? props.is_required : false}
        disabled={!props.isPreview ? false : true}
      >
        <option value="0"> seleccione una opción</option>
        {props?.options.map((option) => (
          <option key={option.id} value={option.id}>
            {option.name}
          </option>
        ))}
      </select>
    </div>
  );
};

export default FieldSelect;
