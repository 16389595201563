const ToBoolean = (value) => {
  return value === "false"
    ? false
    : value === "true"
    ? true
    : value === "0"
    ? false
    : true;
};

export default ToBoolean;
