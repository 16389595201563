const FormJsonUpdate = (form) => {
  const formulario = {
    ...form,
    form_sections: form.form_sections.map((section) => {
      if (section?.is_new) {
        section.id = null;
        delete section.is_new;
        section.section_fields = section.section_fields.map((field) => {
          field.id = null;
          delete field.is_new;
          field.field_options = field.field_options.map((option) => {
            option.id = null
            delete option.is_new
            return option;
          })

          return field
        })
      } else {
        section.section_fields = section.section_fields.map((field) => {
          if (field?.is_new) {
            field.id = null;
            delete field.is_new;
            field.section_id = section.id

            field.field_options = field.field_options.map((option) => {
              option.id = null
              delete option.is_new
              return option;
            })

          } else {
            field.field_options = field.field_options.map((option) => {
              if (option?.is_new) {
                option.id = null
                delete option.is_new
              }
              return option;
            })
          }
          return field
        })
      }
      return section;
    }),
  };

  return formulario;
};

export default FormJsonUpdate;
