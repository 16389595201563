import React from "react";
import PropTypes from "prop-types";
import FieldInput from "./fields/FieldInput";
import FieldInputSwitch from "./fields/FieldInputSwitch";
import FieldInputRadio from "./fields/FieldInputRadio";
import FieldInputCheckbox from "./fields/FieldInputCheckbox";
import FieldTextarea from "./fields/FieldTextarea";
import FieldSelect from "./fields/FieldSelect";
import FieldAudiogram from "./fields/FieldAudiogram";
import FieldInputRadioColRow from "./fields/FieldInputRadioColRow";
import FieldSelectCatalog from "./fields/FieldSelectCatalog";

const FieldsComponent = (props) => {

  switch (true) {
    case /((^text$)|(^number$)|(^tel$)|(^email$)|(^password$)|(^date$)|(^time$))/.test(
      props?.field_type
    ):
      return <FieldInput {...props} />;

    case /^switch$/.test(props?.field_type):
      return <FieldInputSwitch {...props} />;

    case /^radio$/.test(props?.field_type):
      return <FieldInputRadio {...props} />;

    case /^checkbox$/.test(props?.field_type):
      return <FieldInputCheckbox {...props} />;

    case /^textarea$/.test(props?.field_type):
      return <FieldTextarea {...props} />;

    case /^select$/.test(props?.field_type):
      return <FieldSelect {...props} />;

    case /^audiogram$/.test(props?.field_type):
      return <FieldAudiogram {...props} />;

    case /((^radiorow$)|(^radiocol$))/.test(props?.field_type):
      return <FieldInputRadioColRow {...props} />;

    case /^catalog$/.test(props?.field_type):
      return <FieldSelectCatalog {...props} />;

    default:
      break;
  }
};

FieldsComponent.propTypes = { field_type: PropTypes.string.isRequired };

export default FieldsComponent;
