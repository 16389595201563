import React from "react";
import PropTypes from "prop-types";
import "./Pagination.css";

const PaginationComponent = (props) => {
  const { pages, current, setCurrent } = props;

  const handleLeftPage = () => {
    setCurrent(current - 1);
  };

  const handleRightPage = () => {
    setCurrent(current + 1);
  };

  const handleFirstPage = () => {
    setCurrent(1);
  };

  const handleLastPage = () => {
    setCurrent(pages.length);
  };

  const CurrentPage = () => {
    const size = pages.length;
    return pages
      .filter(
        (page) =>
          (current === 1 && page.id <= current + 2) |
          (current === size && current - 2 <= page.id) |
          ((current + 1 === size) | (current + 1 !== size) &&
            page.id >= current - 1 &&
            page.id <= current + 1)
      )
      .map((page, index) => (
        <button
          key={index}
          type="button"
          disabled={page.id === current ? false : true}
        >
          {page.id}
        </button>
      ));
  };

  return (
    <div className="app-table-pagination">
      <div>
        <button
          type="button"
          onClick={handleFirstPage}
          disabled={current === 1 ? true : false}
        >
          <span>Primer</span>
        </button>
      </div>
      <div className="bg-japanese-laurel">
        <div>
          <button
            type="button"
            onClick={handleLeftPage}
            disabled={current === 1 ? true : false}
          >
            Anterior
          </button>
        </div>
        <div>{CurrentPage()}</div>
        <div>
          <button
            type="button"
            onClick={handleRightPage}
            disabled={pages.length === current ? true : false}
          >
            Siguiente
          </button>
        </div>
      </div>
      <div>
        <button
          type="button"
          onClick={handleLastPage}
          disabled={pages.length === current ? true : false}
        >
          <span>Última</span>
        </button>
      </div>
    </div>
  );
};

export default PaginationComponent;

PaginationComponent.propTypes = {
  pages: PropTypes.array.isRequired,
  current: PropTypes.number.isRequired,
  setCurrent: PropTypes.func.isRequired,
};
