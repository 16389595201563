import React, { useContext, useEffect, useState } from "react";
import Swal from "sweetalert2";
import SwalError from "../../utils/SwalError";
import SwalLoading from "../../utils/SwalLoading";
import SessionContext from "../../context/SessionContext";
import { postPatientsSearch } from "../../../services/patients/PatientsService";
import { useNavigate } from "react-router-dom";
import { Modal } from "react-bootstrap";
import PatientCardInformation from "./PatientCardInformation";
import permissions from "../../json/route_permission.json";
import SelectProtocolsComponent from "../selects/SelectProtocolsComponent";

const MedicalCareModalComponent = (props) => {
  const navigate = useNavigate();
  const { showModal, search, handleToggleModal } = props;
  const [patients, setPatients] = useState([]);
  const [state, setState] = useState({ patient_id: 0, protocol_id: 0 });
  const { isPermitted, closeSession } = useContext(SessionContext);


  useEffect(() => {

    if (showModal) {
      SwalLoading({ title: "Buscando Pacientes" });
      postPatientsSearch(search)
        .then(async (response) => {
          if (response.status === 200) {
            setPatients(response.data);
            Swal.close();
          }
        })
        .catch((error) => {
          const response = error.response;
          if (response.status === 401) {
            closeSession();
          } else if ([404].includes(response.status)) {
            SwalError({
              title: "Error",
              message: response.data.detail,
            }).then((result) => {
              if (result.isConfirmed) {
                handleToggleModal();
              }
            });
          } else {
            SwalError({
              title: "Buscando pacientes",
              message: "Error la no fue completada como usted lo indico.",
            }).then((result) => {
              if (result.isConfirmed) {
                handleToggleModal();
              }
            });
          }
        });
    }

    return () => { };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showModal]);

  const handleChange = ({ target }) => setState({ ...state, [target.name]: target.value });

  const handleNextPage = () => navigate(`${state.protocol_id}/atencion-paciente/${state.patient_id}`);

  const handleSelected = (patientId) => setState({ ...state, patient_id: patientId });

  return (
    <Modal
      size="xl"
      show={showModal}
      onHide={handleToggleModal}
      backdrop="static"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>Resultado de Busqueda Paciente</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p className="modal-paragraph">
          {patients.length > 0 ? (
            <span>
              <span>
                Se encontraron las siguientes coincidencias en el sistema
              </span>
              <span className="bold">
                &nbsp;por favor, seleccione uno de los pacientes al <br /> cual desea asignarle una cita&nbsp;
              </span>
            </span>
          ) : (
            <span>
              <span className="bold">
                No se encontro ninguna coincidencia en el sistema.
              </span>
              Si desea hacer una
              <span className="bold">nueva busqueda haga clic en el botón</span>
            </span>
          )}
          o de lo contrario haga&nbsp;
          <span className="bold">
            click en el botón "CANCELAR" para crear uno nuevo paciente.
          </span>
        </p>
        <div className="modal-body-header">
          {patients.length > 0 ? (
            <>
              <div>
                <span className="bold">Coincidencias: </span>
                ({`${patients.length}) pacientes encontrados`}
              </div>
              <div>
                <button
                  type="button"
                  className="btn modal-button"
                  onClick={handleToggleModal}
                >
                  Nueva Busqueda
                </button>
              </div>
            </>
          ) : (
            <div className="center">
              <div>
                <span className="bold">Coincidencias: </span>
                {`${patients.length} pacientes encontrados`}
              </div>
              <div>
                <span className="bold">
                  Este paciente que busca no se encuentra ¿Desea registrar este
                  nuevo paciente?
                </span>
              </div>
            </div>
          )}
        </div>
        {patients.length > 0 && (
          <React.Fragment>
            <div className="modal-body-table-content">
              {patients.map((patient, index) =>
                <PatientCardInformation
                  key={index}
                  patient={patient}
                  state={state}
                  handleSelected={handleSelected}
                />)}
            </div>
            {isPermitted(permissions.medical_care.appointments.create) &&
              <div className="col-6 modal-group">
                <label className="modal-label" htmlFor="protocol_id">
                  Tipo de Actividad:
                </label>
                <SelectProtocolsComponent
                  id="protocol_id"
                  name="protocol_id"
                  className="modal-select"
                  value={state.protocol_id}
                  onChange={handleChange}
                  required
                />
              </div>
            }
          </React.Fragment>
        )
        }
        {isPermitted(permissions.medical_care.appointments.create) &&
          <div className="modal-actions">
            <div className="col-auto">
              {patients.length > 0 ? (
                <button
                  type="button"
                  className="btn modal-button"
                  onClick={handleNextPage}
                  disabled={!state?.patient_id !== 0}
                >
                  Atención Médica
                </button>
              ) : (
                <React.Fragment>
                  <button
                    type="button"
                    className="btn modal-button"
                    onClick={handleToggleModal}
                  >
                    Nueva Busqueda
                  </button>
                </React.Fragment>
              )}
            </div>
            <div className="col-auto">
              <button
                className="btn modal-button-outline"
                onClick={handleToggleModal}
              >
                Cancelar
              </button>
            </div>
          </div>}
      </Modal.Body>
    </Modal>
  );
};

export default MedicalCareModalComponent;
