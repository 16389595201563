import React from 'react';
import ReactDOM from 'react-dom/client';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

// unique routes
import App from './app/App';
import Home from './app/pages/home/Home';
import MedicalCare from './app/pages/medicalcare/MedicalCare';
import PatientCare from './app/pages/patientcare/PatientCare';
import Settings from './app/pages/settings/Settings';
import Reports from './app/pages/reports/Reports';

// Auth
import Login from './app/pages/login/Login';
import EmailVerification
  from './app/pages/auth/email_verification/EmailVerification';
import RequestPassword from './app/pages/auth/request_password/RequestPassword';
import ChangePassword from './app/pages/auth/changepassword/ChangePassword';

// clinic history
import ClinicHistory from './app/pages/clinichistory/ClinicHistory';
import ListClinicHistory from './app/pages/clinichistory/LIstClinicHIstory';
import ClinicHistoryShow from './app/pages/clinichistory/ClinicHistoryShow';
import ClinicHistoryEditPatient from './app/pages/clinichistory/ClinicHistoryEditPatient';

// Catalogs
import Catalogs from './app/pages/settings/catalogs/Catalogs';
import ListCatalogs from './app/pages/settings/catalogs/ListCatalogs';
import CreateCatalogs from './app/pages/settings/catalogs/CreateCatalogs';
import ShowCatalogs from './app/pages/settings/catalogs/ShowCatalogs';
import EditCatalogs from './app/pages/settings/catalogs/EditCatalogs';

// Protocols
import Protocols from './app/pages/settings/protocols/Protocols';
import ListProtocols from './app/pages/settings/protocols/ListProtocols';
import CreateProtocols from './app/pages/settings/protocols/CreateProtocols';
import ShowForms from './app/pages/settings/forms/ShowForms';
import EditProtocols from './app/pages/settings/protocols/EditProtocols';

// Forms
import Forms from './app/pages/settings/forms/Forms';
import ListForms from './app/pages/settings/forms/ListForms';
import CreateForms from './app/pages/settings/forms/CreateForms';
import EditForms from './app/pages/settings/forms/EditForms';

// Users
import Users from './app/pages/settings/users/Users';
import ListUsers from './app/pages/settings/users/ListUsers';
import CreateUsers from './app/pages/settings/users/CreateUsers';
import EditUsers from './app/pages/settings/users/EditUsers';

// Profiles
import Profiles from './app/pages/settings/profiles/Profiles';
import ListProfiles from './app/pages/settings/profiles/ListProfiles';
import CreateProfile from './app/pages/settings/profiles/CreateProfile';
import EditProfile from './app/pages/settings/profiles/EditProfile';

// Doctors
import Doctors from './app/pages/settings/doctors/Doctors';
import ListDoctors from './app/pages/settings/doctors/ListDoctors';
import CreateDoctors from './app/pages/settings/doctors/CreateDoctors';
import EditDoctors from './app/pages/settings/doctors/EditDoctors';

// PrivateRoute
import PrivateRoute from './app/routes/PrivateRoute';
import NotFound from './app/pages/error/NotFound';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import '@fortawesome/fontawesome-free/css/fontawesome.min.css';
import '@fortawesome/fontawesome-free/css/solid.min.css';
import 'jquery/dist/jquery.min.js';
import 'popper.js/dist/umd/popper.min.js';
import 'bootstrap/dist/js/bootstrap.min.js';
import './index.css';
import Dates from './app/pages/dates/Dates';
import CreateDateComponent from './app/components/dates/CreateDateComponent';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/" element={<PrivateRoute component={App} />}>
          <Route index element={<Home />} />
          <Route path="atencion-medica" element={<MedicalCare />} />
          <Route
            path="atencion-medica/:protocolId/atencion-paciente/:patientId"
            element={<PatientCare />}
          />
          <Route path="citas" element={<Dates />}>
            <Route path=":id/crear" element={<CreateDateComponent />} />
          </Route>
          <Route path="historia-clinica" element={<ClinicHistory />}>
            <Route index element={<ListClinicHistory />} />
            <Route path=":id/ver" element={<ClinicHistoryShow />} />
            <Route path=":id/editar" element={<ClinicHistoryEditPatient />} />
          </Route>
          <Route path="reportes" element={<Reports />} />
          <Route path="configuracion" element={<Settings />} />
          <Route path="configuracion/catalogos" element={<Catalogs />}>
            <Route index element={<ListCatalogs />} />
            <Route path="crear" element={<CreateCatalogs />} />
            <Route path=":id/ver" element={<ShowCatalogs />} />
            <Route path=":id/editar" element={<EditCatalogs />} />
          </Route>
          <Route path="configuracion/protocolos" element={<Protocols />}>
            <Route index element={<ListProtocols />} />
            <Route path="crear" element={<CreateProtocols />} />
            <Route path=":id/editar" element={<EditProtocols />} />
          </Route>
          <Route path="configuracion/formularios" element={<Forms />}>
            <Route index element={<ListForms />} />
            <Route path="crear" element={<CreateForms />} />
            <Route path=":id/ver" element={<ShowForms />} />
            <Route path=":id/editar" element={<EditForms />} />
          </Route>
          <Route path="configuracion/usuarios" element={<Users />}>
            <Route index element={<ListUsers />} />
            <Route path="crear" element={<CreateUsers />} />
            <Route path=":id/editar" element={<EditUsers />} />
          </Route>
          <Route path="configuracion/perfiles" element={<Profiles />}>
            <Route index element={<ListProfiles />} />
            <Route path="crear" element={<CreateProfile />} />
            <Route path=":id/editar" element={<EditProfile />} />
          </Route>
          <Route path="configuracion/medicos" element={<Doctors />}>
            <Route index element={<ListDoctors />} />
            <Route path="crear" element={<CreateDoctors />} />
            <Route path=":id/editar" element={<EditDoctors />} />
          </Route>
        </Route>
        <Route
          path="cambiar-contrasenia/:email/:hashed_token"
          element={<ChangePassword />}
        />
        <Route path="recuperar-contrasenia" element={<RequestPassword />} />
        <Route
          path="email-verification/:email/:hashed_token"
          element={<EmailVerification />}
        />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
