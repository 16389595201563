import React, { useMemo } from 'react'
import TranslateGender from '../../utils/TranslateGender'
import DateFormatPersonalized from '../../utils/DateFormatPersonalized'
import { CalculateYears } from '../../utils/CalculateYears'
import CalendarComponent from '../medicalcare/CalendarComponent'

const DateStepTwoComponent = ({ patient, values, handleChange, handleSubmit, handleReset }) => {

    const fullName = useMemo(() => `${patient?.paternal_surname} ${patient?.maternal_surname} ${patient?.first_name} ${patient?.second_name}`, [patient])

    return (
        <form className='app-dates-container' onSubmit={handleSubmit}>
            <h1 className='app-dates-title mb-5'>Paso 2 - Asignación de Citas</h1>
            <div className='mb-5'>
                <fieldset>
                    <legend>Paciente</legend>
                    <div className="d-flex flex-row">
                        <div className='col-md-4'>
                            <div className='row ps-3'>
                                <label htmlFor='full_name' className='col-sm-2 col-form-label fw-bold fs-6 me-2'>Nombre:</label>
                                <div className="col-sm-9">
                                    <input className='form-control-plaintext text-secondary fw-semibold fs-6' id="full_name" name="full_name" value={fullName} />
                                </div>
                            </div>
                        </div>
                        <div className='col-md-4'>
                            <div className='row ps-3'>
                                <label htmlFor='number_id' className='col-sm-4 col-form-label fw-bold fs-6 text-nowrap me-3'>No. Identificación:</label>
                                <div className="col-sm-7">
                                    <input className='form-control-plaintext text-secondary fw-semibold fs-6' id="number_id" name="number_id" value={patient?.number_id} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="d-flex flex-row">
                        <div className='col-md-4'>
                            <div className='row ps-3'>
                                <label htmlFor='gender' className='col-sm-2 col-form-label fw-bold fs-6'>Sexo:</label>
                                <div className="col-sm-10">
                                    <input className='form-control-plaintext text-secondary fw-semibold fs-6' id="gender" name="gender" value={TranslateGender(patient?.gender)} />
                                </div>
                            </div>
                        </div>
                        <div className='col-md-4'>
                            <div className='row ps-3'>
                                <label htmlFor='birth_date' className='col-sm-5 col-form-label fw-bold fs-6 text-nowrap me-2'>Fecha de Nacimiento:</label>
                                <div className="col-sm-6">
                                    <input className='form-control-plaintext text-secondary fw-semibold fs-6' id="birth_date" name="birth_date" value={DateFormatPersonalized(patient?.birth_date, "DD/MM/YYYY")} />
                                </div>
                            </div>
                        </div>
                        <div className='col-md-4'>
                            <div className='row ps-3'>
                                <label htmlFor='age' className='col-sm-2 col-form-label fw-bold fs-6 text-nowrap'>Edad:</label>
                                <div className="col-sm-10">
                                    <input className='form-control-plaintext text-secondary fw-semibold fs-6' id="age" name="age" value={`${CalculateYears(patient?.birth_date)} años`} />
                                </div>
                            </div>
                        </div>
                    </div>
                </fieldset>
            </div>
            <div className='mb-5'>
                <fieldset>
                    <legend>Datos del Profesional</legend>
                    <div className="d-flex flex-row">
                        <div className='col-md-4'>
                            <div className='row ps-3'>
                                <label htmlFor='full_name' className='col-sm-5 col-form-label fw-bold fs-6 text-nowrap'>Nombre Profesional:</label>
                                <div className="col-sm-7">
                                    <input className='form-control-plaintext text-secondary fw-semibold fs-6' id="full_name" name="full_name" value={fullName} />
                                </div>
                            </div>
                        </div>
                        <div className='col-md-4'>
                            <div className='row ps-3'>
                                <label htmlFor='speciality_id' className='col-sm-4 col-form-label fw-bold fs-6 text-nowrap me-3'>Especialidad:</label>
                                <div className="col-sm-7">
                                    <input className='form-control-plaintext text-secondary fw-semibold fs-6' id="speciality_id" name="number_id" value={patient?.number_id} />
                                </div>
                            </div>
                        </div>
                        <div className='col-md-4'>
                            <div className='row ps-3'>
                                <label htmlFor='speciality_id' className='col-sm-5 col-form-label fw-bold fs-6 text-nowrap'>Centro de Atención:</label>
                                <div className="col-sm-7">
                                    <input className='form-control-plaintext text-secondary fw-semibold fs-6 text-truncate' id="speciality_id" name="number_id" value={patient?.number_id} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="d-flex flex-row">
                        <div className='col-md-4'>
                            <div className='row ps-3'>
                                <label htmlFor='protocol_id' className='col-sm-4 col-form-label fw-bold fs-6 text-nowrap'>Tipo Actividad:</label>
                                <div className="col-sm-8">
                                    <input className='form-control-plaintext text-secondary fw-semibold fs-6' id="protocol_id" name="protocol_id" value="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </fieldset>
            </div>
            <div className='d-flex flex-row justify-content-center'>
                <h1 className='app-dates-title fst-italic'>
                    Por favor, seleccione un horario para agendar cita*
                </h1>
            </div>
            <div>
              <CalendarComponent/>
            </div>
            <div className="d-flex flex-row justify-content-center gap-2 mb-3">
                <div className='col-auto'>
                    <button type="submit" className="btn btn-success fs-5">Cerrar</button>
                </div>
            </div>
        </form>
    )
}

export default DateStepTwoComponent
