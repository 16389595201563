import React, { useContext } from 'react';
import AtencionMedica from '../../assets/images/home/atencion-medica.svg';
import HistoriaClinica from '../../assets/images/home/historia-clinica.svg';
import Reportes from '../../assets/images/home/reportes.svg';
import Configuracion from '../../assets/images/home/configuracion.svg';
import DateFormat from '../../utils/DateFormat';
import useSession from '../../hooks/useSession';
import SessionContext from '../../context/SessionContext';
import permissions from '../../json/route_permission.json';
import { NavLink } from 'react-router-dom';
import './Home.css';

const Home = () => {
  const { isPermitted } = useContext(SessionContext);

  return (
    <div className="app-home">
      <div className="app-title">
        Bienvenido {useSession().username} a tu tablero de
        <span className="bold"> Consulta Externa Medica</span>
      </div>
      <div className="app-date">
        <span>{DateFormat()}</span>
      </div>
      <h1 className="app-home-title">Seleccione una opción.</h1>
      <div className="app-options-container">
        {isPermitted(permissions.medical_care.index) &&
          <NavLink
            className="app-option nav-link bg-green-ryb"
            to='/atencion-medica'
            button="true"
          >
            <img
              src={AtencionMedica}
              alt="atención medica"
              width="143px"
              height="138px"
            />
            <span className="bold">
              Atención
              <br />
              Médica
            </span>
          </NavLink>}

        {isPermitted(permissions.clinic_history.index) &&
          <NavLink
            className="app-option nav-link bg-maximun-blue-green"
            to='/historia-clinica'
            button="true"
          >
            <img
              src={HistoriaClinica}
              alt="historia clinica"
              width="143px"
              height="138px"
            />
            <span className="bold">
              Historia
              <br />
              Clínica
            </span>
          </NavLink>}

        {process.env.NODE_ENV === 'development' &&
          <NavLink
            className="app-option nav-link bg-cornflower-blue"
            to='/reportes'
            button="true"
          >
            <img src={Reportes} alt="reportes" width="143px" height="138px" />
            <span className="bold">Reportes</span>
          </NavLink>}

        {isPermitted(permissions.settings.index) &&
          <NavLink
            className="app-option nav-link bg-spanish-gray"
            to='/configuracion'
            button="true"
          >
            <img
              src={Configuracion}
              alt="configuración"
              width="143px"
              height="138px"
            />
            <span className="bold">Configuración</span>
          </NavLink>}

      </div>
    </div>
  );
};

export default Home;
