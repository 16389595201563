import axiosService from "../../connection/AxiosService";

export async function getProfiles() {
    if (axiosService.checkToken()) {
        return await axiosService.client.get(`/profiles`);
    }
}


export async function getModules(){
    if (axiosService.checkToken()) {
        return await axiosService.client.get(`/modules`);
    }
}


export async function postProfile(data = null) {
    if (axiosService.checkToken()) {
        if (data) {
            return await axiosService.client.post("/profiles", data);
        }
    }
}


export async function getProfileById(id = null) {
    if (axiosService.checkToken()) {
        if (id) {
            return await axiosService.client.get(`/profiles/${id}`);
        }
    }
}


export async function putProfileById(id = null, data = null) {
    if (axiosService.checkToken()) {
        if (id && data) {
            return await axiosService.client.put(`/profiles/${id}`, data);
        }
    }
}


export async function deleteProfileById(id = null) {
    if (axiosService.checkToken()) {
        if (id) {
            return await axiosService.client.delete(`/profiles/${id}`);
        }
    }
}
