import React, { useState, useEffect } from "react";
import SwalError from "../../utils/SwalError";
import { getCompanies } from "../../../services/settings/doctors/DoctorsService";

const SelectCompaniesComponent = (props) => {
  const { id, name, value, onChange, onBlur, className, required } = props;
  const [companies, setCompanies] = useState([]);

  useEffect(() => {
    getCompanies()
      .then((response) => {
        if (response.status === 200) {
          setCompanies(response.data);
        }
      })
      .catch((error) => {
        const response = error.response;
        if (response.status === 401) {
          SwalError({
            title: "Error",
            message: response.data.detail,
          });
        }
      });
  }, []);

  return (
    <select
      id={id}
      name={name}
      className={className}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      required={required}
    >
      <option value="0">seleccione una opción</option>
      {companies?.map((item) => (
        <option key={item.id} value={item.id}>
          {item.name}
        </option>
      ))}
    </select>
  );
};

export default SelectCompaniesComponent;
