import React, { useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router";
import Header from "./pages/header/Header";
import Navbar from "./pages/navbar/Navbar";
import SwalSuccess from "./utils/SwalSuccess";
import SessionContext from "./context/SessionContext";
import useSession from "./hooks/useSession";
import "./App.css";

function App() {
  const [show, setShow] = useState(false);
  const navigate = useNavigate();
  const session = useSession();
  const inactivityTime = 60 * 60 * 1000; // 60 minutos
  let inactivityTimer;


  const closeSession = async () => {

    sessionStorage.clear();
    SwalSuccess({
      title: "Cerrando Sesión",
      message: "La sesión ha sido cerrada exitosamente.",
    });
    navigate("/login");
  };

  // Agrega event listeners para los eventos de usuario.

  const resetInactivityTimer = () => {
    clearTimeout(inactivityTimer);
    inactivityTimer = setTimeout(closeSession, inactivityTime);
  };

  const isPermitted = (action) => {
    return session?.user?.profiles?.some(({ profile }) => profile?.permissions?.some((permission) => {
      if (action.includes(permission.permission.permission)) {
        return permission.is_active
      }
      return false;
    }));
  };

  // Función para cerrar la sesión del usuario
  useEffect(() => {
    resetInactivityTimer();

    window.addEventListener("mousemove", resetInactivityTimer);
    window.addEventListener("mousedown", resetInactivityTimer);
    window.addEventListener("keydown", resetInactivityTimer);



    return () => {
      window.addEventListener("mousemove", resetInactivityTimer);
      window.addEventListener("mousedown", resetInactivityTimer);
      window.addEventListener("keydown", resetInactivityTimer);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleToggle = () => {
    setShow(!show);
  };

  const year = new Date().getUTCFullYear();

  return (
    <SessionContext.Provider value={{ closeSession: closeSession, session: session, isPermitted: isPermitted }}>
      <div className="app">
        <div className="app-header">
          <Header show={show} handleToggle={handleToggle} />
        </div>
        <div className="app-container">
          <Outlet />
        </div>
        {show && (
          <div className="app-navbar" onClick={handleToggle}>
            <Navbar show={show} handleToggle={handleToggle} />
          </div>
        )}
        <div className="app-footer">
          <span> &copy; {year} Consulta Externa by HG Consulting </span>
        </div>
      </div>
    </SessionContext.Provider>
  );
}

export default App;
