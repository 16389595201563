import React, { useEffect } from 'react';
import {Outlet, useNavigate} from 'react-router-dom';
import usePermission from '../../hooks/usePermission';
import permissions from "../../json/route_permission.json";
import './ClinicHistory.css';

const ClinicHistory = () => {
  const navigate = useNavigate();
  const permitted = usePermission(permissions.clinic_history.index);

  useEffect(() => {

    if (permitted && !permitted) {
      navigate("not-found");
    }

    return () => { };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [permitted]);

  return <Outlet />;
};

export default ClinicHistory;
