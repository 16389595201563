import React, { useEffect } from "react";
import { Outlet, useNavigate } from "react-router";
import usePermission from "../../../hooks/usePermission";
import permissions from "../../../json/route_permission.json";
import "./Protocols.css";

const Protocols = () => {

  const navigate = useNavigate();
  const permitted = usePermission(permissions.settings.protocols.index);

  useEffect(() => {

    if (permitted && !permitted) {
      navigate("not-found");
    }

    return () => { };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [permitted]);

  return permitted && (
    <Outlet />
  );
};

export default Protocols;
