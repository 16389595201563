import React, { useContext } from "react";
import { useNavigate } from "react-router";
import EditCatalogComponent from "../../../components/settings/catalogs/EditCatalogComponent";
import DateFormat from "../../../utils/DateFormat";
import SessionContext from "../../../context/SessionContext";

const EditCatalogs = () => {
  const navigate = useNavigate();
  const {session} = useContext(SessionContext);

  return (
    <div>
      <div className="app-container-header">
        <div className="left">
          <span onClick={() => navigate(-1)}>
            <span>
              <i className="fa-solid fa-arrow-left fs-auto"></i>
            </span>
            <span className="bold">Catálogos / Editar Catálogo</span>
          </span>
          <span>{DateFormat()}</span>
        </div>
        <div className="right">
          <span className="bold">{session?.username}</span>
          <span>{session.email}</span>
        </div>
      </div>
      <div className="app-settings">
        <EditCatalogComponent />
      </div>
    </div>
  );
};

export default EditCatalogs;
