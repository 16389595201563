import PropTypes from "prop-types";

const ToCapitalize = (value = null) => {
  if (value) {
    return [value.charAt(0).toUpperCase(), value.slice(1, value.lenght)].join(
      ""
    );
  }
  return "";
};

ToCapitalize.propTypes = { value: PropTypes.string.isRequired };

export default ToCapitalize;
