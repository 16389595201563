import Swal from "sweetalert2";
import PropTypes from "prop-types";

const SwalSuccess = (props) => {
  return Swal.fire({
    title: props?.title,
    html: props?.message,
    showCloseButton: true,
    showConfirmButton: false,
    timer: 2500,
    closeButtonHtml: `<div class="swal2-header">
                        <div class="swal2-header-title"></div>
                        <div class="swal2-header-close">
                          <button type="button" aria-label="Close this dialog">x</button>
                        </div>
                      </div>`,
  });
};

export default SwalSuccess;

SwalSuccess.propTypes = {
  title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
};
