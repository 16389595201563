import { useEffect, useState } from "react";
import { getProfile } from "../../services/auth/AuthService";
import { useNavigate } from "react-router-dom";
import SwalError from "../utils/SwalError";

const useSession = () => {
  const [state, setState] = useState({ username: "", email: "", user: {} });
  const navigate = useNavigate();

  useEffect(() => {
    getProfile().then((response) => {
      const data = response.data;
      if (data?.user !== undefined) {
        setState({
          username: data.full_name,
          email: data.user.email,
          id: data.id,
          schedules: data.schedules,
          user: data.user,
          is_doctor: true
        });
      } else {
        setState({
          username: data.username,
          email: data.email,
          user: data,
          is_doctor: false
        });
      }
    }).catch((error) => {
      const response = error.response;
      if (response.status === 401) {
        SwalError({ title: "Error", message: response.data.detail });
        sessionStorage.clear();
        navigate("/login");
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return state;
};

export default useSession;
