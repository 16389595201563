import React, { useEffect, useState } from "react";

const ResendCodeTimer = (props) => {
  const { duration, isSended, setIsSended } = props;
  const [seconds, setSeconds] = useState(duration);

  useEffect(() => {
    if (isSended) {
      const timer = setInterval(() => {
        setSeconds((prevSeconds) => prevSeconds - 1);
      }, 1000);
      return () => clearInterval(timer);
    }
  }, [isSended, duration]);

  useEffect(() => {
    if (seconds === 0) {
      setIsSended(!isSended);
      setSeconds(duration);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [seconds]);

  const formattedSeconds = seconds < 10 ? `0${seconds}` : seconds;

  return (
    <span>
      Reenviar Código en <span className="bold">{formattedSeconds} </span>{" "}
      segundos
    </span>
  );
};

export default ResendCodeTimer;
