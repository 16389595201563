import Swal from "sweetalert2";
import PropTypes from "prop-types";

const SwalLoading = (props) => {
  Swal.fire({
    title: props?.title,
    html: `<div class="loading">
            <span class="spin"></span>
            <span class="label">Cargando...</span>
           </div>`,
    showConfirmButton: false,
    allowEscapeKey: false,
    allowOutsideClick: false,
  });
};

export default SwalLoading;

SwalLoading.propTypes = {
  title: PropTypes.string.isRequired,
};
