import React, { useEffect } from "react";

const FieldInput = (props) => {

  useEffect(() => {
    return () => { };
  }, []);


  return (
    <div className={`${props?.field_class} form-group`}>
      <label
        className="form-label"
        htmlFor={props?.field_id}
      >
        {props?.field_label ? props?.field_label : ""}
      </label>
      <input
        type={props.field_type || "text"}
        id={props.field_id || ""}
        name={props.field_name || ""}
        className="form-control"
        placeholder={props.field_placeholder || ""}
        min={props.field_min || ""}
        max={props?.field_max || ""}
        value={props?.value}
        onChange={!props.isPreview ? props.onChange : () => { }}
        required={!props.isPreview ? props.is_required : false}
        readOnly={!props.isPreview ? false : true}
        disabled={!props.isPreview ? false : true}
      />
    </div>
  );
};

export default FieldInput;
